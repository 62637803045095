import { call, put, takeLatest } from 'redux-saga/effects';
import { add_TicketFieldGroupMappingFailed, add_TicketFieldGroupMappingSuccess, ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED, check_TicketFieldGroupMappingFailed, check_TicketFieldGroupMappingSuccess, CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED, get_TicketFieldGroupMappingFailed, get_TicketFieldGroupMappingSuccess, GET_TICKET_FIELD_GROUP_MAPPING_REQUESTED } from '../action/ticketFieldGroupMapping-action';
import { get_ticketFieldGroupMapping_API, add_ticketFieldGroupMapping_API, check_ticketFieldGroupMapping_API } from '../api/ticketFieldGroupMapping-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getTicketFieldGroupMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_ticketFieldGroupMapping_API, action.payload);
    yield put(get_TicketFieldGroupMappingSuccess(getResponse));
  } catch (error) {
    yield put(get_TicketFieldGroupMappingFailed(error));
  }
}

function* addTicketFieldGroupMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_ticketFieldGroupMapping_API, action.payload);
    yield put(add_TicketFieldGroupMappingSuccess(getResponse));
  } catch (error) {
    yield put(add_TicketFieldGroupMappingFailed(error));
  }
}


function* checkTicketFieldGroupMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(check_ticketFieldGroupMapping_API, action.payload);
    yield put(check_TicketFieldGroupMappingSuccess(getResponse));
  } catch (error) {
    yield put(check_TicketFieldGroupMappingFailed(error));
  }
}

export function* ticketFieldGroupMappingSaga() {
  yield takeLatest(GET_TICKET_FIELD_GROUP_MAPPING_REQUESTED, getTicketFieldGroupMapping)
  yield takeLatest(ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED, addTicketFieldGroupMapping)
  yield takeLatest(CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED, checkTicketFieldGroupMapping)
}