export const GET_TICKET_FIELD = 'GET_TICKET_FIELD';
export const GET_TICKET_FIELD_REQUESTED = 'GET_TICKET_FIELD_REQUESTED';
export const GET_TICKET_FIELD_SUCCESS = 'GET_TICKET_FIELD_SUCCESS';
export const GET_TICKET_FIELD_FAILED = 'GET_TICKET_FIELD_FAILED';
export const GET_TICKET_FIELD_CLEAR = 'GET_TICKET_FIELD_CLEAR';
export const ADD_TICKET_FIELD = 'ADD_TICKET_FIELD';
export const ADD_TICKET_FIELD_REQUESTED = 'ADD_TICKET_FIELD_REQUESTED';
export const ADD_TICKET_FIELD_SUCCESS = 'ADD_TICKET_FIELD_SUCCESS';
export const ADD_TICKET_FIELD_FAILED = 'ADD_TICKET_FIELD_FAILED';
export const ADD_TICKET_FIELD_DONE = 'ADD_TICKET_FIELD_DONE';

export function get_TicketFieldData(payload: any) {
  return {
    type: 'GET_TICKET_FIELD_REQUESTED',
    payload: payload,
  };
}

export function get_TicketFieldSuccess(payload: any) {
  return {
    type: 'GET_TICKET_FIELD_SUCCESS',
    payload: payload,
  };
}
export function get_TicketFieldFailed(payload: any) {
  return {
    type: 'GET_TICKET_FIELD_FAILED',
    payload: '',
  };
}
export function get_TicketFieldClear() {
  return {
    type: 'GET_TICKET_FIELD_CLEAR',
  };
}

export function add_TicketFieldData(payload: any) {
  return {
    type: 'ADD_TICKET_FIELD_REQUESTED',
    payload: payload,
  };
}
export function add_TicketFieldSuccess(payload: any) {
  return {
    type: 'ADD_TICKET_FIELD_SUCCESS',
    payload: payload,
  };
}
export function add_TicketFieldFailed(payload: any) {
  return {
    type: 'ADD_TICKET_FIELD_FAILED',
    payload: '',
  };
}
export function add_TicketFieldDone() {
  return {
    type: 'ADD_TICKET_FIELD_DONE',
  };
}
