
import { ADD_COMPANY, ADD_COMPANY_DONE, ADD_COMPANY_FAILED, ADD_COMPANY_SUCCESS, GET_COMPANY, GET_COMPANY_FAILED, GET_COMPANY_SUCCESS } from "../action/company-action"
import cookie from 'react-cookies';

const initialState = {
    loading: false,
    data: [],
    addCompanyStatus: false,
    addData: {}
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_COMPANY:
            return {
                ...state,
                loading: true,
            }
        case GET_COMPANY_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_COMPANY_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: [],
                    loading: false
                }
            }
        case ADD_COMPANY:
            return {
                ...state,
            }
        case ADD_COMPANY_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addCompanyStatus: true
                }
            }
        case ADD_COMPANY_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addCompanyStatus: true
                }
            }
        case ADD_COMPANY_DONE:
                return {
                    ...state,
                    addData: {},
                    addCompanyStatus: false
                }
        default:
            return state;
    }
}