import cookie from 'react-cookies';
import { ADD_TICKET_SOURCE_DETAILS, ADD_TICKET_SOURCE_DETAILS_FAILED, ADD_TICKET_SOURCE_DETAILS_SUCCESS, GET_PRIMARY_TICKET_SOURCE_DETAILS, GET_PRIMARY_TICKET_SOURCE_DETAILS_FAILED, GET_PRIMARY_TICKET_SOURCE_DETAILS_SUCCESS, GET_SECONDARY_TICKET_SOURCE_DETAILS, GET_SECONDARY_TICKET_SOURCE_DETAILS_FAILED, GET_SECONDARY_TICKET_SOURCE_DETAILS_SUCCESS } from '../action/ticketSourceDetails-action';

const initialState = {
    loading: false,
    primaryData: [],
    secondaryData: [],
    data: [],
    addTicketSourceDetails: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PRIMARY_TICKET_SOURCE_DETAILS:
            return {
                ...state,
                loading: true
            }
        case GET_PRIMARY_TICKET_SOURCE_DETAILS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    primaryData: action.payload,
                    loading: false
                }
            }
        case GET_PRIMARY_TICKET_SOURCE_DETAILS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    primaryData: [],
                    loading: false
                }
            }

        case GET_SECONDARY_TICKET_SOURCE_DETAILS:
            return {
                ...state,
                loading: true
            }
        case GET_SECONDARY_TICKET_SOURCE_DETAILS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    secondaryData: action.payload,
                    loading: false
                }
            }
        case GET_SECONDARY_TICKET_SOURCE_DETAILS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    secondaryData: [],
                    loading: false
                }
            }
        case ADD_TICKET_SOURCE_DETAILS:
            return {
                ...state,
                loading: true
            }
        case ADD_TICKET_SOURCE_DETAILS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addTicketSourceDetails: true,
                    data: action.payload,
                    loading: false
                }
            }
        case ADD_TICKET_SOURCE_DETAILS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    date: [],
                    loading: false
                }
            }
        default:
            return state
    }
}