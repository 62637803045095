
export const GET_DEPTWISE_AGENT = "GET_DEPTWISE_AGENT"
export const GET_DEPTWISE_AGENT_REQUESTED = "GET_DEPTWISE_AGENT_REQUESTED"
export const GET_DEPTWISE_AGENT_SUCCESS = "GET_DEPTWISE_AGENT_SUCCESS"
export const GET_DEPTWISE_AGENT_FAILED = "GET_DEPTWISE_AGENT_FAILED"

export const ADD_DEPTWISE_AGENT = "ADD_DEPTWISE_AGENT"
export const ADD_DEPTWISE_AGENT_REQUESTED = "ADD_DEPTWISE_AGENT_REQUESTED"
export const ADD_DEPTWISE_AGENT_SUCCESS = "ADD_DEPTWISE_AGENT_SUCCESS"
export const ADD_DEPTWISE_AGENT_FAILED = "ADD_DEPTWISE_AGENT_FAILED"
export const ADD_DEPTWISE_AGENT_DONE = "ADD_DEPTWISE_AGENT_DONE"

export function get_deptwiseAgentData(payload:any) {
    return {
        type: 'GET_DEPTWISE_AGENT_REQUESTED',
        payload: payload
    }
}

export function get_deptwiseAgentSuccess(payload:any) {
    return {
        type: 'GET_DEPTWISE_AGENT_SUCCESS',
        payload: payload
    }
}
export function get_deptwiseAgentFailed(payload:any) {
    return {
        type: 'GET_DEPTWISE_AGENT_FAILED',
        payload: ''
    }
}

export function add_deptwiseAgentData(payload:any) {
    return {
        type: 'ADD_DEPTWISE_AGENT_REQUESTED',
        payload: payload
    }
}
export function add_deptwiseAgentSuccess(payload:any) {
    return {
        type: 'ADD_DEPTWISE_AGENT_SUCCESS',
        payload: payload
    }
}
export function add_deptwiseAgentFailed(payload:any) {
    return {
        type: 'ADD_DEPTWISE_AGENT_FAILED',
        payload: ''
    }
}
export function add_deptwiseAgentDone() {
    return {
        type: 'ADD_DEPTWISE_AGENT_DONE',
    }
}