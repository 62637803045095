import { SERVICE_URL } from "../../Service";


export const loginUser = async(payload:any) => {
    try {
        let headers = {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(payload)
        }
        let getDetails = await fetch(SERVICE_URL.LOGIN, headers);
        let getResponse = await getDetails.json();
        
        return getResponse;
    }
    catch(e) {
        console.log(e);
    }
}