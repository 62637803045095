
export const GET_TICKET_FIELD_GROUP_MAPPING = "GET_TICKET_FIELD_GROUP_MAPPING"
export const GET_TICKET_FIELD_GROUP_MAPPING_REQUESTED = "GET_TICKET_FIELD_GROUP_MAPPING_REQUESTED"
export const GET_TICKET_FIELD_GROUP_MAPPING_SUCCESS = "GET_TICKET_FIELD_GROUP_MAPPING_SUCCESS"
export const GET_TICKET_FIELD_GROUP_MAPPING_FAILED = "GET_TICKET_FIELD_GROUP_MAPPING_FAILED"

export const ADD_TICKET_FIELD_GROUP_MAPPING = "ADD_TICKET_FIELD_GROUP_MAPPING"
export const ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED = "ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED"
export const ADD_TICKET_FIELD_GROUP_MAPPING_SUCCESS = "ADD_TICKET_FIELD_GROUP_MAPPING_SUCCESS"
export const ADD_TICKET_FIELD_GROUP_MAPPING_FAILED = "ADD_TICKET_FIELD_GROUP_MAPPING_FAILED"
export const ADD_TICKET_FIELD_GROUP_MAPPING_DONE = "ADD_TICKET_FIELD_GROUP_MAPPING_DONE"

export const CHECK_TICKET_FIELD_GROUP_MAPPING = "CHECK_TICKET_FIELD_GROUP_MAPPING"
export const CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED = "CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED"
export const CHECK_TICKET_FIELD_GROUP_MAPPING_SUCCESS = "CHECK_TICKET_FIELD_GROUP_MAPPING_SUCCESS"
export const CHECK_TICKET_FIELD_GROUP_MAPPING_FAILED = "CHECK_TICKET_FIELD_GROUP_MAPPING_FAILED"
export const CHECK_TICKET_FIELD_GROUP_MAPPING_DONE = "CHECK_TICKET_FIELD_GROUP_MAPPING_DONE"

export function get_TicketFieldGroupMappingData(payload:any) {
    return {
        type: 'GET_TICKET_FIELD_GROUP_MAPPING_REQUESTED',
        payload: payload
    }
}

export function get_TicketFieldGroupMappingSuccess(payload:any) {
    return {
        type: 'GET_TICKET_FIELD_GROUP_MAPPING_SUCCESS',
        payload: payload
    }
}
export function get_TicketFieldGroupMappingFailed(payload:any) {
    return {
        type: 'GET_TICKET_FIELD_GROUP_MAPPING_FAILED',
        payload: ''
    }
}

export function add_TicketFieldGroupMappingData(payload:any) {
    return {
        type: 'ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED',
        payload: payload
    }
}
export function add_TicketFieldGroupMappingSuccess(payload:any) {
    return {
        type: 'ADD_TICKET_FIELD_GROUP_MAPPING_SUCCESS',
        payload: payload
    }
}
export function add_TicketFieldGroupMappingFailed(payload:any) {
    return {
        type: 'ADD_TICKET_FIELD_GROUP_MAPPING_FAILED',
        payload: ''
    }
}
export function add_TicketFieldGroupMappingDone() {
    return {
        type: 'ADD_TICKET_FIELD_GROUP_MAPPING_DONE'
    }
}


export function check_TicketFieldGroupMappingData(payload:any) {
    return {
        type: 'CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED',
        payload: payload
    }
}
export function check_TicketFieldGroupMappingSuccess(payload:any) {
    return {
        type: 'CHECK_TICKET_FIELD_GROUP_MAPPING_SUCCESS',
        payload: payload
    }
}
export function check_TicketFieldGroupMappingFailed(payload:any) {
    return {
        type: 'CHECK_TICKET_FIELD_GROUP_MAPPING_FAILED',
        payload: ''
    }
}
export function check_TicketFieldGroupMappingDone() {
    return {
        type: 'CHECK_TICKET_FIELD_GROUP_MAPPING_DONE'
    }
}