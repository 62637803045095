
export const GET_COMPANYGROUP = "GET_COMPANYGROUP"
export const GET_COMPANYGROUP_REQUESTED = "GET_COMPANYGROUP_REQUESTED"
export const GET_COMPANYGROUP_SUCCESS = "GET_COMPANYGROUP_SUCCESS"
export const GET_COMPANYGROUP_FAILED = "GET_COMPANYGROUP_FAILED"
export const ADD_COMPANYGROUP = "ADD_COMPANYGROUP"
export const ADD_COMPANYGROUP_REQUESTED = "ADD_COMPANYGROUP_REQUESTED"
export const ADD_COMPANYGROUP_SUCCESS = "ADD_COMPANYGROUP_SUCCESS"
export const ADD_COMPANYGROUP_FAILED = "ADD_COMPANYGROUP_FAILED"
export const ADD_COMPANYGROUP_DONE = "ADD_COMPANYGROUP_DONE"

export function get_CompanyGroupData() {
    return {
        type: 'GET_COMPANYGROUP_REQUESTED'
    }
}
export function get_companyGroupSuccess(payload:any) {
    return {
        type: 'GET_COMPANYGROUP_SUCCESS',
        payload: payload
    }
}
export function get_companyGroupFailed(payload:any) {
    return {
        type: 'GET_COMPANYGROUP_FAILED',
        payload: ''
    }
}

export function add_CompanyGroupData(payload:any) {
    return {
        type: 'ADD_COMPANYGROUP_REQUESTED',
        payload: payload
    }
}
export function add_CompanyGroupSuccess(payload:any) {
    return {
        type: 'ADD_COMPANYGROUP_SUCCESS',
        payload: payload
    }
}
export function add_CompanyGroupFailed(payload:any) {
    return {
        type: 'ADD_COMPANYGROUP_FAILED',
        payload: ''
    }
}
export function add_CompanyGroupDone() {
    return {
        type: 'ADD_COMPANYGROUP_DONE',
    }
}