import cookie from 'react-cookies';
import { GET_TICKETSTATUS, GET_TICKETSTATUS_CLEAR, GET_TICKETSTATUS_FAILED, GET_TICKETSTATUS_SUCCESS } from '../action/ticketStatus-action';

const initialState = {
    loading: false,
    getData: {},
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TICKETSTATUS:
            return {
                ...state,
                loading: true,
            }
        case GET_TICKETSTATUS_SUCCESS:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                }
            }
        case GET_TICKETSTATUS_FAILED:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: {}
                }
            }

        case GET_TICKETSTATUS_CLEAR:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: {}
                }
            }

        default:
            return state
    }
}