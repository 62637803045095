import cookie from 'react-cookies';
import { GET_DISPOSITION1, GET_DISPOSITION1_SUCCESS, GET_DISPOSITION1_FAILED, GET_DISPOSITION2, GET_DISPOSITION2_FAILED, GET_DISPOSITION2_SUCCESS, GET_DISPOSITION3, GET_DISPOSITION3_FAILED, GET_DISPOSITION3_SUCCESS } from '../action/disposition-action';
import {  SUBDOMAIN } from "../../Service"
const initialState = {
  loading: false,
  getData1: [],
  getData2: [],
  getData3: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DISPOSITION1:
      return {
        ...state,
        loading: true,
      };
    case GET_DISPOSITION1_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData1: action.payload,
          loading: false,
        };
      }
    case GET_DISPOSITION1_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData1: [],
          loading: false,
        };
      }

    case GET_DISPOSITION2:
      return {
        ...state,
        loading: true,
      };
    case GET_DISPOSITION2_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData2: action.payload,
          loading: false,
        };
      }
    case GET_DISPOSITION2_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData2: [],
          loading: false,
        };
      }

    case GET_DISPOSITION3:
      return {
        ...state,
        loading: true,
      };
    case GET_DISPOSITION3_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData3: action.payload,
          loading: false,
        };
      }
    case GET_DISPOSITION3_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData3: [],
          loading: false,
        };
      }

    default:
      return state;
  }
};
