
export const LOGIN = "LOGIN"
export const LOGIN_REQUESTED = "LOGIN_REQUESTED"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGIN_REQUESTED_DONE = "LOGIN_REQUESTED_DONE"


export function loginUser(payload:any) {
    return {
        type: 'LOGIN_REQUESTED',
        payload: payload
    }
}
export function loginSuccess(payload: any) {
    return {
      type: "LOGIN_SUCCESS",
      payload: payload,
    };
  }

export function loginFailed(payload: any) {
return {
    type: "LOGIN_SUCCESS",
    payload: payload,
};
}
export function loginDone() {
  return {
      type: 'LOGIN_REQUESTED_DONE'
  }
}