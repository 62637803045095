import cookie from 'react-cookies';
import { GET_DEPTWISE_SOURCEDETAILS, GET_DEPTWISE_SOURCEDETAILS_FAILED, GET_DEPTWISE_SOURCEDETAILS_REQUESTED, GET_DEPTWISE_SOURCEDETAILS_SUCCESS } from '../action/deptwiseSourceDetails-action';

const initialState = {
    loading: false,
    getData: []
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_DEPTWISE_SOURCEDETAILS:
            return {
                ...state,
                loading: true
            }
        case GET_DEPTWISE_SOURCEDETAILS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_DEPTWISE_SOURCEDETAILS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }
        default:
            return state
    }
}