import cookie from 'react-cookies';
import { ADD_SUB_CATEGORYMAPPING, ADD_SUB_CATEGORYMAPPING_DONE, ADD_SUB_CATEGORYMAPPING_FAILED, ADD_SUB_CATEGORYMAPPING_REQUESTED, ADD_SUB_CATEGORYMAPPING_SUCCESS, CHECK_SUB_CATEGORYMAPPING, CHECK_SUB_CATEGORYMAPPING_FAILED, CHECK_SUB_CATEGORYMAPPING_SUCCESS, FIELDWISE_SUBCATEGORY, FIELDWISE_SUBCATEGORY_FAILED, FIELDWISE_SUBCATEGORY_SUCCESS, GET_SUB_CATEGORYMAPPING, GET_SUB_CATEGORYMAPPING_FAILED, GET_SUB_CATEGORYMAPPING_REQUESTED, GET_SUB_CATEGORYMAPPING_SUCCESS } from '../action/subCategoryMapping-action';
import {  SUBDOMAIN } from "../../Service"
const initialState = {
  loading: false,
  data: [],
  fieldWiseSubCategoryData: [],
  addSubCategoryMappingStatus: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SUB_CATEGORYMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_SUB_CATEGORYMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      }

    case GET_SUB_CATEGORYMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          loading: false,
        };
      }
    case ADD_SUB_CATEGORYMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ADD_SUB_CATEGORYMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addData: action.payload,
          addSubCategoryMappingStatus: true,
          loading: false,
        };
      }
    case ADD_SUB_CATEGORYMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          addSubCategoryMappingStatus: false,
          loading: false,
        };
      }
    case ADD_SUB_CATEGORYMAPPING_DONE:
      return {
        ...state,
        addData: {},
        addSubCategoryMappingStatus: false,
        loading: false,
      };

    case CHECK_SUB_CATEGORYMAPPING:
      return {
        ...state,
        loading: true,
      };
    case CHECK_SUB_CATEGORYMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          checkData: action.payload,
          loading: false,
        };
      }
    case CHECK_SUB_CATEGORYMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          checkData: [],
          loading: false,
        };
      }

    case FIELDWISE_SUBCATEGORY:
      return {
        ...state,
        loading: true,
      };
    case FIELDWISE_SUBCATEGORY_SUCCESS:
      console.log(action,'currentUser')
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          fieldWiseSubCategoryData: action.payload,
          loading: false,
        };
      }
    case FIELDWISE_SUBCATEGORY_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          fieldWiseSubCategoryData: [],
          loading: false,
        };
      }
    default:
      return state;
  }
};
