
import cookie from 'react-cookies';
import { ADD_CALL_CONFIGURATION_DONE, ADD_CALL_CONFIGURATION_FAILED, ADD_CALL_CONFIGURATION_SUCCESS, GET_CALL_CONFIGURATION, GET_CALL_CONFIGURATION_FAILED, GET_CALL_CONFIGURATION_SUCCESS } from '../action/callConfiguration-action';


const initialState = {
    loading: false,
    data: [],
    addCallConfigurationStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CALL_CONFIGURATION:
            return {
                ...state,
                loading: true
            }
        case GET_CALL_CONFIGURATION_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_CALL_CONFIGURATION_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    date: [],
                    loading: false
                }
            }
        case GET_CALL_CONFIGURATION:
            return {
                ...state,
                loading: true
            }
        case ADD_CALL_CONFIGURATION_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    addCallConfigurationStatus: true,
                    loading: false
                }
            }
        case ADD_CALL_CONFIGURATION_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    date: [],
                    addCallConfigurationStatus: true,
                    loading: false
                }
            }
        case ADD_CALL_CONFIGURATION_DONE:
            return {
                ...state,
                addCallConfigurationStatus: false
            }
        default:
            return state
    }
}