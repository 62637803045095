import { call, put, takeLatest } from 'redux-saga/effects';
import { get_deptwiseSourceDetailsSuccess, get_deptwiseSourceDetailsFailed, GET_DEPTWISE_SOURCEDETAILS_REQUESTED } from '../action/deptwiseSourceDetails-action';
import { get_deptwiseSourceDetails_API } from '../api/deptwiseSourceDetails-api';


export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getDeptwise_SourceDetails(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_deptwiseSourceDetails_API, action.payload);
    yield put(get_deptwiseSourceDetailsSuccess(getResponse));
  } catch (error) {
    yield put(get_deptwiseSourceDetailsFailed(error));
  }
}

export function* deptwiseSourceDetailsSaga() {
  yield takeLatest(GET_DEPTWISE_SOURCEDETAILS_REQUESTED, getDeptwise_SourceDetails)
}