import { SERVICE_URL } from "../../Service";
import cookie from 'react-cookies';


export const get_PastTicketInteraction_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json',
                'authorization':  'Bearer' + ' ' + cookie.load("token")
            }

        }
        let getDetails = await fetch(SERVICE_URL.GET_PASTTICKETINTERACTION + `?source_id=${payload.source_id}&info=${payload.info}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}