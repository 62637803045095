export const GET_AGENTMAPPING = 'GET_AGENTMAPPING';
export const GET_AGENTMAPPING_REQUESTED = 'GET_AGENTMAPPING_REQUESTED';
export const GET_AGENTMAPPING_SUCCESS = 'GET_AGENTMAPPING_SUCCESS';
export const GET_AGENTMAPPING_FAILED = 'GET_AGENTMAPPING_FAILED';

export const ADD_AGENTMAPPING = 'ADD_AGENTMAPPING';
export const ADD_AGENTMAPPING_REQUESTED = 'ADD_AGENTMAPPING_REQUESTED';
export const ADD_AGENTMAPPING_SUCCESS = 'ADD_AGENTMAPPING_SUCCESS';
export const ADD_AGENTMAPPING_FAILED = 'ADD_AGENTMAPPING_FAILED';
export const ADD_AGENTMAPPING_DONE = 'ADD_AGENTMAPPING_DONE';

export function get_agentMappingData(payload:any) {
  return {
    type: 'GET_AGENTMAPPING_REQUESTED',
    payload: payload,
  };
}
export function get_agentMappingDataSuccess(payload: any) {
  return {
    type: 'GET_AGENTMAPPING_SUCCESS',
    payload: payload,
  };
}
export function get_agentMappingDataFailed(payload: any) {
  return {
    type: 'GET_AGENTMAPPING_FAILED',
    payload: '',
  };
}

export function add_agentMappingData(payload: any) {
  return {
    type: 'ADD_AGENTMAPPING_REQUESTED',
    payload: payload,
  };
}
export function add_agentMappingDataSuccess(payload: any) {
  return {
    type: 'ADD_AGENTMAPPING_SUCCESS',
    payload: payload,
  };
}
export function add_agentMappingDataFailed(payload: any) {
  return {
    type: 'ADD_AGENTMAPPING_FAILED',
    payload: '',
  };
}

export function add_agentMappingDone() {
  return {
    type: 'ADD_AGENTMAPPING_DONE',
  };
}
