export const GET_PRIORITY = 'GET_PRIORITY'
export const GET_PRIORITY_REQUESTED = 'GET_PRIORITY_REQUESTED'
export const GET_PRIORITY_SUCCESS = 'GET_PRIORITY_SUCCESS'
export const GET_PRIORITY_FAILED = 'GET_PRIORITY_FAILED'

export function get_PriorityData(){
    return{
        type: 'GET_PRIORITY_REQUESTED'
    }
}
export function get_PriorityDataSuccess(payload: any){
    return{
        type: 'GET_PRIORITY_SUCCESS',
        payload: payload
    }
}
export function get_PriorityDataFailed(payload: any){
    return{
        type: 'GET_PRIORITY_FAILED',
        payload: ''
    }
}