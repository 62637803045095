import { ADD_COMPANYGROUP, ADD_COMPANYGROUP_DONE, ADD_COMPANYGROUP_FAILED, ADD_COMPANYGROUP_SUCCESS, GET_COMPANYGROUP, GET_COMPANYGROUP_FAILED, GET_COMPANYGROUP_SUCCESS } from "../action/companyGroup-action"
import cookie from 'react-cookies';


const initialState = {
  loading: false,
  data: [],
  addCompanyGroupStatus: false,
  addData: {}
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_COMPANYGROUP:
      return {
        ...state,
        loading: true
      }
    case GET_COMPANYGROUP_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = '/login_expire'
        return {}
      }
      else {
        return {
          ...state,
          loading: false,
          data: action.payload
        }
      }
    case GET_COMPANYGROUP_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = '/login_expire'
        return {}
      }
      else {
        return {
          ...state,
          loading: false,
          data: []
        }
      }
    case ADD_COMPANYGROUP:
      return {
        ...state,
      }
    case ADD_COMPANYGROUP_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = '/login_expire'
        return {}
      }
      else {
        return {
          ...state,
          addData: action.payload,
          addCompanyGroupStatus: true
        }
      }
    case ADD_COMPANYGROUP_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = '/login_expire'
        return {}
      }
      else {
        return {
          ...state,
          addData: action.payload,
          addCompanyGroupStatus: true
        }
      }
    case ADD_COMPANYGROUP_DONE:
      return {
        ...state,
        addData: {},
        addCompanyGroupStatus: false
      }
    default:
      return state
  }
}