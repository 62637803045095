import {
  ADD_PRODUCT,
  ADD_PRODUCT_DONE,
  ADD_PRODUCT_FAILED,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FIELD,
  GET_PRODUCT_FIELD_SUCCESS,
  GET_PRODUCT_FIELD_FAILED,
  ADD_PRODUCT_FIELD,
  ADD_PRODUCT_FIELD_REQUESTED,
  ADD_PRODUCT_FIELD_SUCCESS,
  ADD_PRODUCT_FIELD_FAILED,
  ADD_PRODUCT_FIELD_DONE,
} from '../action/product-action';
import cookie from 'react-cookies';
import {  SUBDOMAIN } from "../../Service"

const initialState = {
  loading: false,
  data: [],
  addData: {},
  addProductFieldData: {},
  addProductStatus: false,
  addProductFieldStatus: false,
  ProductList: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          loading: true,
          data: action.payload,
        };
      }
    case GET_PRODUCT_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          loading: false,
          data: [],
        };
      }
    case ADD_PRODUCT:
      return {
        ...state,
      };
    case ADD_PRODUCT_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addData: action.payload,
          addProductStatus: true,
        };
      }
    case ADD_PRODUCT_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addData: {},
          addProductStatus: true,
        };
      }
    case ADD_PRODUCT_DONE:
      return {
        ...state,
        addData: {},
        addProductStatus: false,
      };
    case GET_PRODUCT_FIELD:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_FIELD_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          loading: true,
          ProductList: action.payload.FieldInfo,
        };
      }
    case GET_PRODUCT_FIELD_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          loading: false,
          data: [],
          ProductList: [],
        };
      }

    case ADD_PRODUCT_FIELD:
      return {
        ...state,
      };
    case ADD_PRODUCT_FIELD_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addProductFieldData: action.payload,
          addProductFieldStatus: true,
        };
      }
    case ADD_PRODUCT_FIELD_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addProductFieldData: {},
          addProductFieldStatus: true,
        };
      }
    case ADD_PRODUCT_FIELD_DONE:
      return {
        ...state,
        addProductFieldData: {},
        addProductFieldStatus: false,
      };
    default:
      return state;
  }
};
