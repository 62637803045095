import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_COMPANYGROUP_REQUESTED, get_companyGroupFailed, get_companyGroupSuccess, add_CompanyGroupSuccess, ADD_COMPANYGROUP_REQUESTED } from '../action/companyGroup-action';
import { add_companyGroup_API, get_companyGroup_API } from '../api/companyGroup-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getCompanyGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_companyGroup_API, action.payload);
    yield put(get_companyGroupSuccess(getResponse));
  } catch (error) {
    yield put(get_companyGroupFailed(error));
  }
}

function* addCompanyGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_companyGroup_API, action.payload);
    yield put(add_CompanyGroupSuccess(getResponse));
  } catch (error) {
    yield put(get_companyGroupFailed(error));
  }

}

export function* companyGroupSaga() {
  yield takeLatest(GET_COMPANYGROUP_REQUESTED, getCompanyGroup)
  yield takeLatest(ADD_COMPANYGROUP_REQUESTED, addCompanyGroup)
}