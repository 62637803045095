import cookie from 'react-cookies';
import { ADD_CATEGORYGROUPMAPPING, ADD_CATEGORYGROUPMAPPING_DONE, ADD_CATEGORYGROUPMAPPING_FAILED, ADD_CATEGORYGROUPMAPPING_REQUESTED, ADD_CATEGORYGROUPMAPPING_SUCCESS, CHECK_CATEGORYGROUPMAPPING, CHECK_CATEGORYGROUPMAPPING_CLEAR, CHECK_CATEGORYGROUPMAPPING_FAILED, CHECK_CATEGORYGROUPMAPPING_REQUESTED, CHECK_CATEGORYGROUPMAPPING_SUCCESS, GET_CATEGORYGROUPMAPPING, GET_CATEGORYGROUPMAPPING_FAILED, GET_CATEGORYGROUPMAPPING_REQUESTED, GET_CATEGORYGROUPMAPPING_SUCCESS } from '../action/categoryGroupMapping-action';
import {  SUBDOMAIN } from "../../Service"
const initialState = {
  loading: false,
  data: [],
  checkData:[],
  addCategoryGroupMappingStatus: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CATEGORYGROUPMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORYGROUPMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      }

    case GET_CATEGORYGROUPMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          loading: false,
        };
      }
    case ADD_CATEGORYGROUPMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORYGROUPMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addData: action.payload,
          addCategoryGroupMappingStatus: true,
          loading: false,
        };
      }
    case ADD_CATEGORYGROUPMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          addCategoryGroupMappingStatus: false,
          loading: false,
        };
      }
    case ADD_CATEGORYGROUPMAPPING_DONE:
      return {
        ...state,
        addData: {},
        addCategoryGroupMappingStatus: false,
      };


    case CHECK_CATEGORYGROUPMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CHECK_CATEGORYGROUPMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          checkData: action.payload,
          loading: false,
        };
      }
    case CHECK_CATEGORYGROUPMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          checkData: [],
          loading: false,
        };
      }
      case CHECK_CATEGORYGROUPMAPPING_CLEAR:
        if (action?.payload?.StatusCode === 401) {
          localStorage.clear();
          cookie.remove('username');
          cookie.remove('token');
          window.location.href = `${SUBDOMAIN}/login_expire`;
          return {};
        } else {
          return {
            ...state,
            checkData: [],
            loading: false,
          };
        }
    default:
      return state;
  }
};
