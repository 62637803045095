import { call, put, takeLatest } from 'redux-saga/effects';
import { add_MailConfigureDataFailed, add_MailConfigureDataSuccess, ADD_MAILCONFIGURE_REQUESTED, get_MailConfigureDataFailed, get_MailConfigureDataSuccess, GET_MAILCONFIGURE_REQUESTED } from '../action/mailConfigure-action';
import { add_mailConfigure_API, get_mailConfigure_API } from '../api/mailConfigure-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getMailConfigure(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_mailConfigure_API, action.payload);
   yield put(get_MailConfigureDataSuccess(getResponse));
   } catch (error) {
    yield put(get_MailConfigureDataFailed(error));
   }
}

function* addMailConfigure(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(add_mailConfigure_API, action.payload);
   yield put(add_MailConfigureDataSuccess(getResponse));
   } catch (error) {
    yield put(add_MailConfigureDataFailed(error));
   }
}

export function* mailConfigureSaga() {
  yield takeLatest(GET_MAILCONFIGURE_REQUESTED, getMailConfigure)
  yield takeLatest(ADD_MAILCONFIGURE_REQUESTED, addMailConfigure)
}