import { ADD_USERS, ADD_USERS_DONE, ADD_USERS_FAILED, ADD_USERS_SUCCESS, GET_USERS, GET_USERS_FAILED, GET_USERS_SUCCESS } from "../action/users-action"
import cookie from 'react-cookies';


const initialState = {
    loading: false,
    getData: [],
    addUsersStatus: false,
    addData: {}
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                loading: true
            }
        case GET_USERS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_USERS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }
        case ADD_USERS:
            return {
                ...state,
                loading: true
            }
        case ADD_USERS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addUsersStatus: true,
                    addData: action.payload,
                    loading: false
                }
            }
        case ADD_USERS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: [],
                    loading: false
                }
            }
        case ADD_USERS_DONE:
            return {
                ...state,
                addData: {},
                addUsersStatus: false
            }
        default:
            return state
    }
}