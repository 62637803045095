import {
  ADD_CATEGORYGROUP,
  ADD_CATEGORYGROUP_FAILED,
  ADD_CATEGORYGROUP_SUCCESS,
  GET_CATEGORYGROUP,
  GET_CATEGORYGROUP_FAILED,
  GET_CATEGORYGROUP_SUCCESS,
  ADD_CATEGORYGROUP_DONE,
  GET_CATEGORYGROUP_REQUESTED,
  ADD_CATEGORYGROUP_REQUESTED,
} from '../action/categoryGroup-action';
import cookie from 'react-cookies';
import {  SUBDOMAIN } from "../../Service"

const initialState = {
  loading: false,
  data: [],
  addCATEGORYGROUPStatus: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CATEGORYGROUP_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORYGROUP_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      }

    case GET_CATEGORYGROUP_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          loading: false,
        };
      }
    case ADD_CATEGORYGROUP_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORYGROUP_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addData: action.payload,
          addCATEGORYGROUPStatus: true,
          loading: false,
        };
      }
    case ADD_CATEGORYGROUP_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          addCATEGORYGROUPStatus: false,
          loading: false,
        };
      }
    case ADD_CATEGORYGROUP_DONE:
      return {
        ...state,
        addData: {},
        addCATEGORYGROUPStatus: false,
      };
    default:
      return state;
  }
};
