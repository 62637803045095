
export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_REQUESTED = "GET_COMPANY_REQUESTED"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAILED = "GET_COMPANY_FAILED"

export const ADD_COMPANY = "ADD_COMPANY"
export const ADD_COMPANY_REQUESTED = "ADD_COMPANY_REQUESTED"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAILED = "ADD_COMPANY_FAILED"
export const ADD_COMPANY_DONE = "ADD_COMPANY_DONE"

export function get_CompanyData(){
    return {
        type: 'GET_COMPANY_REQUESTED'
    }
}
export function get_CompanyDataSuccess(payload: any){     
    return {
        type: 'GET_COMPANY_SUCCESS',
        payload: payload
    }
}
export function get_CompanyDataFailed(payload: any){
    return {
        type: 'GET_COMPANY_FAILED',
        payload: ''
    }
}

export function add_CompanyData(payload: any){
    return {
        type: 'ADD_COMPANY_REQUESTED',
        payload: payload
    }
}
export function add_CompanyDataSuccess(payload: any){     
    return {
        type: 'ADD_COMPANY_SUCCESS',
        payload: payload
    }
}
export function add_CompanyDataFailed(payload: any){
    return {
        type: 'ADD_COMPANY_FAILED',
        payload: ''
    }
}
export function add_CompanyDataDone(){
    return {
        type: 'ADD_COMPANY_DONE'
    }
}