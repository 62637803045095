
export const GET_MAILINBOX = "GET_MAILINBOX"
export const GET_MAILINBOX_REQUESTED = "GET_MAILINBOX_REQUESTED"
export const GET_MAILINBOX_SUCCESS = "GET_MAILINBOX_SUCCESS"
export const GET_MAILINBOX_FAILED = "GET_MAILINBOX_FAILED"

export const ADD_MAILINBOX = "ADD_MAILINBOX"
export const ADD_MAILINBOX_REQUESTED = "ADD_MAILINBOX_REQUESTED"
export const ADD_MAILINBOX_SUCCESS = "ADD_MAILINBOX_SUCCESS"
export const ADD_MAILINBOX_FAILED = "ADD_MAILINBOX_FAILED"
export const ADD_MAILINBOX_DONE = "ADD_MAILINBOX_DONE"

export function get_MailInboxData(){
    return {
        type: 'GET_MAILINBOX_REQUESTED'
    }
}
export function get_MailInboxDataSuccess(payload: any){     
    return {
        type: 'GET_MAILINBOX_SUCCESS',
        payload: payload
    }
}
export function get_MailInboxDataFailed(payload: any){
    return {
        type: 'GET_MAILINBOX_FAILED',
        payload: ''
    }
}

export function add_MailInboxData(payload: any){
    return {
        type: 'ADD_MAILINBOX_REQUESTED',
        payload: payload
    }
}
export function add_MailInboxDataSuccess(payload: any){     
    return {
        type: 'ADD_MAILINBOX_SUCCESS',
        payload: payload
    }
}
export function add_MailInboxDataFailed(payload: any){
    return {
        type: 'ADD_MAILINBOX_FAILED',
        payload: ''
    }
}
export function add_MailInboxDone() {
    return {
        type: 'ADD_MAILINBOX_DONE'
    }
}