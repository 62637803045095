import cookie from 'react-cookies';
import { SERVICE_URL } from '../../../Service';




export const get_deptwiseAgent_API = async(payload:any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + cookie.load("token")
            }
        }
        let getDetails = await fetch(SERVICE_URL.GET_DEPTWISE_AGENT+ `?company_id=${payload.company_id}&dept_id=${payload.dept_id}&source_id=${payload.source_id}&source_detail_id=${payload.source_detail_id}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch(e) {
        console.log(e);
    }
}
export const add_deptwiseAgent_API = async(payload:any) => {
    try {
        let headers = {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + cookie.load("token")
            },
            body:JSON.stringify(payload)
        }
        let getDetails = await fetch(SERVICE_URL.ADD_DEPTWISE_AGENT, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch(e) {
        console.log(e);
    }
}