import {  SUBDOMAIN } from "../../Service"
import { LOGIN, LOGIN_FAILED, LOGIN_REQUESTED, LOGIN_REQUESTED_DONE, LOGIN_SUCCESS } from "../action/login-action"

  
  const initialState = {
    loading: false,
    isVerified: false,
    userData: {},
  }
  
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          loading: true,
        }        
      case LOGIN_REQUESTED:
        return {
          ...state,
          loading: true,
        }
      case LOGIN_SUCCESS:
        if (action.payload?.userType) {
          let loginDetails = JSON.stringify(action.payload)
          let userType =  action.payload.userType
          let token = action.payload.token
          let emp_Id = action.payload.emp_Id
          let company_info = JSON.stringify(action.payload.company_info)
          document.cookie = `adminId=${emp_Id}; expires=;path=/`;
          document.cookie = `loginDetails=${loginDetails}; expires=;path=/`;
          document.cookie = `userType=${userType}; expires=;path=/`;
          document.cookie = `token=${token}; expires=;path=/`;
          document.cookie = `company_info=${company_info}; expires=;path=/`;
          window.location.href = `${SUBDOMAIN}/dashboard`
          return {
            ...state,
            userData: action.payload,
            loading: false,
            isVerified: true
          }
        }
        else{
          return {
            ...state,
            loading: false,
            userData: action.payload
          }
        }
      case LOGIN_FAILED:
        return {
          ...state,
          loading: false,
          userData: {message: 'Unspecified error !! Please Try Again!!!!'}
        }
      case LOGIN_REQUESTED_DONE:
        return {
          ...state,
          loading: false,
          isVerified: false,
        }
      default:
        return state
    }
  }