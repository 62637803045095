import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_agentMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      // body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_AGENTMAPPING +
        `?CompanyId=${payload.CompanyId}&agent_id=${payload.agent_id}`,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
export const add_agentMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(SERVICE_URL.ADD_AGENTMAPPING, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
