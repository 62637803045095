import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_categoryMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(SERVICE_URL.GET_CATEGORYMAPPING, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
export const add_categoryMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(SERVICE_URL.ADD_CATEGORYMAPPING, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};


export const check_categoryMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      }
    };
    let getDetails = await fetch(SERVICE_URL.CHECK_CATEGORYMAPPING + `?company_id=${payload.company_id}&dept_id=${payload.dept_id}&category_type_id=${payload.category_type_id}&category_group_id=${payload.category_group_id}`, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

