import { call, put, takeLatest } from 'redux-saga/effects';
import { add_MailInboxDataFailed, add_MailInboxDataSuccess, ADD_MAILINBOX_REQUESTED, get_MailInboxDataFailed, get_MailInboxDataSuccess, GET_MAILINBOX_REQUESTED } from '../action/mailInbox-action';
import { add_mailInbox_API, get_mailInbox_API } from '../api/mailInbox-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getMailInbox(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_mailInbox_API, action.payload);
    yield put(get_MailInboxDataSuccess(getResponse));
  } catch (error) {
    yield put(get_MailInboxDataFailed(error));
  }
}


function* addMailInbox(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_mailInbox_API, action.payload);
    yield put(add_MailInboxDataSuccess(getResponse));
  } catch (error) {
    yield put(add_MailInboxDataFailed(error));
  }
}

export function* mailInboxSaga() {
  yield takeLatest(GET_MAILINBOX_REQUESTED, getMailInbox)
  yield takeLatest(ADD_MAILINBOX_REQUESTED, addMailInbox)
}