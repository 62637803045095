import { call, put, takeLatest } from 'redux-saga/effects';
import { add_agentMappingDataFailed, add_agentMappingDataSuccess, ADD_AGENTMAPPING_REQUESTED, get_agentMappingDataFailed, get_agentMappingDataSuccess, GET_AGENTMAPPING_REQUESTED } from '../action/agentMapping-action';
import { add_agentMapping_API, get_agentMapping_API } from '../api/agentMapping-api';


export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getAgentMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_agentMapping_API,
      action.payload
    );
    yield put(get_agentMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(get_agentMappingDataFailed(error));
  }
}

function* addAgentMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_agentMapping_API,
      action.payload
    );
    yield put(add_agentMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(add_agentMappingDataFailed(error));
  }
}

export function* agentMappingSaga() {
  yield takeLatest(GET_AGENTMAPPING_REQUESTED, getAgentMapping);
  yield takeLatest(ADD_AGENTMAPPING_REQUESTED, addAgentMapping);
}
