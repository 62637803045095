export const GET_ADMINTICKETDASHBOARD_REQUESTED = 'GET_ADMINTICKETDASHBOARD_REQUESTED';
export const GET_ADMINTICKETDASHBOARD_SUCCESS = 'GET_ADMINTICKETDASHBOARD_SUCCESS';
export const GET_ADMINTICKETDASHBOARD_FAILED = 'GET_ADMINTICKETDASHBOARD_FAILED';
export const GET_ADMINTICKETDASHBOARD_CLEAR = 'GET_ADMINTICKETDASHBOARD_CLEAR'

export const GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED = 'GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED';
export const GET_ADMINTICKETDASHBOARDDETAILS_SUCCESS = 'GET_ADMINTICKETDASHBOARDDETAILS_SUCCESS';
export const GET_ADMINTICKETDASHBOARDDETAILS_FAILED = 'GET_ADMINTICKETDASHBOARDDETAILS_FAILED';
export const GET_ADMINTICKETDASHBOARDDETAILS_CLEAR = 'GET_ADMINTICKETDASHBOARDDETAILS_CLEAR'

export function get_AdminTcktDashboardData(payload: any) {
    return {
        type: 'GET_ADMINTICKETDASHBOARD_REQUESTED',
        payload: payload,
    };
}
export function get_AdminTcktDashboardDataSuccess(payload: any) {
    return {
        type: 'GET_ADMINTICKETDASHBOARD_SUCCESS',
        payload: payload,
    };
}
export function get_AdminTcktDashboardDataFailed(payload: any) {
    return {
        type: 'GET_ADMINTICKETDASHBOARD_FAILED',
        payload: '',
    };
}
export function get_AdminTcktDashboardDataClear() {
    return {
        type: 'GET_ADMINTICKETDASHBOARD_CLEAR'
    };
}


export function get_AdminTcktDashboardDetailsData(payload: any) {
    return {
        type: 'GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED',
        payload: payload,
    };
}
export function get_AdminTcktDashboardDetailsDataSuccess(payload: any) {
    return {
        type: 'GET_ADMINTICKETDASHBOARDDETAILS_SUCCESS',
        payload: payload,
    };
}
export function get_AdminTcktDashboardDetailsDataFailed(payload: any) {
    return {
        type: 'GET_ADMINTICKETDASHBOARDDETAILS_FAILED',
        payload: '',
    };
}
export function get_AdminTcktDashboardDetailsDataClear() {
    return {
        type: 'GET_ADMINTICKETDASHBOARDDETAILS_CLEAR'
    };
}