import { call, put, takeLatest } from 'redux-saga/effects';
import { add_subCategoryMappingDataFailed, add_subCategoryMappingDataSuccess, ADD_SUB_CATEGORYMAPPING_REQUESTED, check_subCategoryMappingDataFailed, check_subCategoryMappingDataSuccess, CHECK_SUB_CATEGORYMAPPING_REQUESTED, FIELDWISE_SUBCATEGORY_REQUESTED, getFieldwiseSubcategoryDataFailed, getFieldwiseSubcategoryDataSuccess, get_subCategoryMappingDataFailed, get_subCategoryMappingDataSuccess, GET_SUB_CATEGORYMAPPING_REQUESTED } from '../action/subCategoryMapping-action';
import { add_subCategoryMapping_API, check_subCategoryMapping_API, fieldwise_Subcategory_API, get_subCategoryMapping_API } from '../api/subCategoryMapping-api';
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getSubCategoryMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_subCategoryMapping_API,
      action.payload
    );
    yield put(get_subCategoryMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(get_subCategoryMappingDataFailed(error));
  }
}

function* addSubCategoryMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_subCategoryMapping_API,
      action.payload
    );
    yield put(add_subCategoryMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(add_subCategoryMappingDataFailed(error));
  }
}

function* checkSubCategoryMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      check_subCategoryMapping_API,
      action.payload
    );
    yield put(check_subCategoryMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(check_subCategoryMappingDataFailed(error));
  }
}

function* fieldwiseSubCategory(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      fieldwise_Subcategory_API,
      action.payload
    );
    yield put(getFieldwiseSubcategoryDataSuccess(getResponse));
  } catch (error) {
    yield put(getFieldwiseSubcategoryDataFailed(error));
  }
}
export function* subCategoryMappingSaga() {
  yield takeLatest(GET_SUB_CATEGORYMAPPING_REQUESTED, getSubCategoryMapping);
  yield takeLatest(ADD_SUB_CATEGORYMAPPING_REQUESTED, addSubCategoryMapping);
  yield takeLatest(CHECK_SUB_CATEGORYMAPPING_REQUESTED, checkSubCategoryMapping);
  yield takeLatest(FIELDWISE_SUBCATEGORY_REQUESTED, fieldwiseSubCategory);
}
