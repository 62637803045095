import { call, put, takeLatest } from 'redux-saga/effects';
import { add_TicketSourceFailed, add_TicketSourceSuccess, ADD_TICKET_SOURCE_REQUESTED, get_TicketSourceFailed, get_TicketSourceSuccess, GET_TICKET_SOURCE_REQUESTED } from '../action/ticketSource-action';
import { add_ticketSource_API, get_ticketSource_API } from '../api/ticketSource-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getTicketSource(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_ticketSource_API, action.payload);
    yield put(get_TicketSourceSuccess(getResponse));
  } catch (error) {
    yield put(get_TicketSourceFailed(error));
  }
}

function* addTicketSource(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_ticketSource_API, action.payload);
    yield put(add_TicketSourceSuccess(getResponse));
  } catch (error) {
    yield put(add_TicketSourceFailed(error));
  }
}


export function* ticketSourceSaga() {
  yield takeLatest(GET_TICKET_SOURCE_REQUESTED, getTicketSource)
  yield takeLatest(ADD_TICKET_SOURCE_REQUESTED, addTicketSource)
}