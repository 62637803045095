import cookie from 'react-cookies';
import { ADD_MAILSENT, ADD_MAILSENT_DONE, ADD_MAILSENT_FAILED, ADD_MAILSENT_SUCCESS, GET_MAILSENT, GET_MAILSENT_FAILED, GET_MAILSENT_SUCCESS } from '../action/mailSent-action';


const initialState = {
    loading: false,
    data: [],
    addData: {},
    addMailInboxStatus: false
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MAILSENT:
            return {
                ...state,
                loading: true,
            }
        case GET_MAILSENT_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_MAILSENT_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: [],
                    loading: false
                }
            }
        case ADD_MAILSENT:
            return {
                ...state,
                loading: true,
            }
        case ADD_MAILSENT_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addMailConfigureStatus: true,
                }
            }
        case ADD_MAILSENT_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: {}
                }
            }
        case ADD_MAILSENT_DONE:
            return {
                ...state,
                addData: {},
                addMailConfigureStatus: false
            }
        default:
            return state
    }
}