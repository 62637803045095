// routes
import MainRouter from './MainRouter';
// theme
import ThemeProvider from './theme';
// components
import Settings from './component/settings';
import RtlLayout from './component/RtlLayout';
import ScrollToTop from './component/ScrollToTop';
import { ProgressBarStyle } from './component/ProgressBar';
import ThemeColorPresets from './component/ThemeColorPresets';
import MotionLazyContainer from './component/animate/MotionLazyContainer';
import NotistackProvider from './component/NotistackProvider';
import '../src/assests/css/muiOver.css'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <Settings />
              <ScrollToTop />
              <MainRouter />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
