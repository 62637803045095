import { call, put, takeLatest } from 'redux-saga/effects';
import { add_TicketFieldFailed, add_TicketFieldSuccess, ADD_TICKET_FIELD_REQUESTED, get_TicketFieldFailed, get_TicketFieldSuccess, GET_TICKET_FIELD_REQUESTED } from '../action/ticketField-action';
import { add_ticketField_API, get_ticketField_API } from '../api/ticketField-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getTicketField(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_ticketField_API, action.payload);
    yield put(get_TicketFieldSuccess(getResponse));
  } catch (error) {
    yield put(get_TicketFieldFailed(error));
  }
}

function* addTicketField(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_ticketField_API, action.payload);
    yield put(add_TicketFieldSuccess(getResponse));
  } catch (error) {
    yield put(add_TicketFieldFailed(error));
  }
}


export function* ticketFieldSaga() {
  yield takeLatest(GET_TICKET_FIELD_REQUESTED, getTicketField)
  yield takeLatest(ADD_TICKET_FIELD_REQUESTED, addTicketField)
}