import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_ticketFieldGroupMapping_API = async (payload: any) => {
  let data = {
    company_id: payload.company_id,
    dept_id: payload.dept_id,
    category_group_id: payload.category_group_id,
    category_id: payload.category_id,
    subcategory_id: payload.subcategory_id,
  };
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_TICKET_FIELD_GROUP_MAPPING,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
export const add_ticketFieldGroupMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.ADD_TICKET_FIELD_GROUP_MAPPING,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const check_ticketFieldGroupMapping_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.CHECK_TICKET_FIELD_GROUP_MAPPING,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
