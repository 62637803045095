import cookie from 'react-cookies';
import { ADD_DEPT_SOURCEMAPPING, ADD_DEPT_SOURCEMAPPING_DONE, ADD_DEPT_SOURCEMAPPING_FAILED, ADD_DEPT_SOURCEMAPPING_SUCCESS, GET_DEPT_SOURCEMAPPING, GET_DEPT_SOURCEMAPPING_FAILED, GET_DEPT_SOURCEMAPPING_SUCCESS } from '../action/deptSourceMapping-action';


const initialState = {
    loading: false,
    getData: [],
    addData: [],
    addTicketSourceStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_DEPT_SOURCEMAPPING:
            return {
                ...state,
                loading: true
            }
        case GET_DEPT_SOURCEMAPPING_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_DEPT_SOURCEMAPPING_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }
        case ADD_DEPT_SOURCEMAPPING:
            return {
                ...state,
                loading: true
            }
        case ADD_DEPT_SOURCEMAPPING_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addTicketSourceStatus: true,
                    addData: action.payload,
                    loading: false
                }
            }
        case ADD_DEPT_SOURCEMAPPING_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addTicketSourceStatus: true,
                    addData: [],
                    loading: false
                }
            }
        case ADD_DEPT_SOURCEMAPPING_DONE:
            return {
                ...state,
                addTicketSourceStatus: false,
                loading: false
            }
        default:
            return state
    }
}