
import cookie from 'react-cookies';
import { ADD_CATEGORY_TYPE, ADD_CATEGORY_TYPE_DONE, ADD_CATEGORY_TYPE_FAILED, ADD_CATEGORY_TYPE_REQUESTED, ADD_CATEGORY_TYPE_SUCCESS, GET_CATEGORY_TYPE, GET_CATEGORY_TYPE_FAILED, GET_CATEGORY_TYPE_REQUESTED, GET_CATEGORY_TYPE_SUCCESS } from '../action/categoryType-action';



const initialState = {
    loading: false,
    getData: [],
    addData: {},
    addCategoryTypeStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CATEGORY_TYPE_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case GET_CATEGORY_TYPE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_CATEGORY_TYPE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }
        case ADD_CATEGORY_TYPE_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case ADD_CATEGORY_TYPE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addCategoryTypeStatus: true,
                    loading: false
                }
            }
        case ADD_CATEGORY_TYPE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: {},
                    addCategoryTypeStatus: true,
                    loading: false
                }
            }
        case ADD_CATEGORY_TYPE_DONE:
            return {
                ...state,
                addData: {},
                addCategoryTypeStatus: false,
            };
        default:
            return state
    }
}