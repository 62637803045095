export const GET_SUB_CATEGORY = 'GET_SUB_CATEGORY';
export const GET_SUB_CATEGORY_REQUESTED = 'GET_SUB_CATEGORY_REQUESTED';
export const GET_SUB_CATEGORY_SUCCESS = 'GET_SUB_CATEGORY_SUCCESS';
export const GET_SUB_CATEGORY_FAILED = 'GET_SUB_CATEGORY_FAILED';

export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const ADD_SUB_CATEGORY_REQUESTED = 'ADD_SUB_CATEGORY_REQUESTED';
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';
export const ADD_SUB_CATEGORY_FAILED = 'ADD_SUB_CATEGORY_FAILED';
export const ADD_SUBCATEGORY_DONE = 'ADD_SUBCATEGORY_DONE';

export function get_SubCategoryData(payload: any) {
  return {
    type: 'GET_SUB_CATEGORY_REQUESTED',
    payload: payload,
  };
}
export function get_SubCategoryDataSuccess(payload: any) {
  return {
    type: 'GET_SUB_CATEGORY_SUCCESS',
    payload: payload,
  };
}
export function get_SubCategoryDataFailed(payload: any) {
  return {
    type: 'GET_SUB_CATEGORY_FAILED',
    payload: '',
  };
}

export function add_SubCategoryData(payload: any) {
  return {
    type: 'ADD_SUB_CATEGORY_REQUESTED',
    payload: payload,
  };
}
export function add_SubCategoryDataSuccess(payload: any) {
  return {
    type: 'ADD_SUB_CATEGORY_SUCCESS',
    payload: payload,
  };
}
export function add_SubCategoryDataFailed(payload: any) {
  return {
    type: 'ADD_SUB_CATEGORY_FAILED',
    payload: '',
  };
}
export function add_SubCategoryDone() {
  return {
    type: 'ADD_SUBCATEGORY_DONE',
  };
}
