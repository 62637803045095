import cookie from 'react-cookies';
import { ADD_AGENTMAPPING, ADD_AGENTMAPPING_DONE, ADD_AGENTMAPPING_FAILED, ADD_AGENTMAPPING_SUCCESS, GET_AGENTMAPPING, GET_AGENTMAPPING_FAILED, GET_AGENTMAPPING_SUCCESS } from '../action/agentMapping-action';
import {  SUBDOMAIN } from "../../Service"
  const initialState = {
    loading: false,
    data: [],
    addAgentMappingStatus: false,
  };
  
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case GET_AGENTMAPPING:
        return {
          ...state,
          loading: true,
        };
      case GET_AGENTMAPPING_SUCCESS:
        if (action?.payload?.StatusCode === 401) {
          localStorage.clear();
          cookie.remove('username');
          cookie.remove('token');
          window.location.href = `${SUBDOMAIN}/login_expire`;
          return {};
        } else {
          return {
            ...state,
            data: action.payload,
            loading: false,
          };
        }
  
      case GET_AGENTMAPPING_FAILED:
        if (action?.payload?.StatusCode === 401) {
          localStorage.clear();
          cookie.remove('username');
          cookie.remove('token');
          window.location.href = `${SUBDOMAIN}/login_expire`;
          return {};
        } else {
          return {
            ...state,
            date: [],
            addData: {},
            loading: false,
          };
        }
      case ADD_AGENTMAPPING:
        return {
          ...state,
          loading: true,
        };
      case ADD_AGENTMAPPING_SUCCESS:
        if (action?.payload?.StatusCode === 401) {
          localStorage.clear();
          cookie.remove('username');
          cookie.remove('token');
          window.location.href = `${SUBDOMAIN}/login_expire`;
          return {};
        } else {
          return {
            ...state,
            addData: action.payload,
            addAgentMappingStatus: true,
            loading: false,
          };
        }
      case ADD_AGENTMAPPING_FAILED:
        if (action?.payload?.StatusCode === 401) {
          localStorage.clear();
          cookie.remove('username');
          cookie.remove('token');
          window.location.href = `${SUBDOMAIN}/login_expire`;
          return {};
        } else {
          return {
            ...state,
            date: [],
            addData: {},
            addAgentMappingStatus: false,
            loading: false,
          };
        }
      case ADD_AGENTMAPPING_DONE:
        return {
          ...state,
          addData: {},
          addAgentMappingStatus: false,
        };
      default:
        return state;
    }
  };
  