import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_product_API = async (payload: any) => {
  console.log('payload', payload, ' URL ->>>>>>>>>', SERVICE_URL.GET_PRODUCT);
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      //body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_PRODUCT +
        '?company_id=' +
        (payload.company_id == undefined ? 0 : payload.company_id) +
        '&ProductId=0',
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
export const add_product_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(SERVICE_URL.ADD_PRODUCT, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const get_product_Field_API = async (payload: any) => {
  console.log(
    'payload',
    payload,
    ' URL ->>>>>>>>>',
    SERVICE_URL.GET_PRODUCT_FIELD
  );
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      //body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_PRODUCT_FIELD +
        '?company_id=' +
        (payload.company_id == undefined ? 0 : payload.company_id) +
        '&product_id=' +
        (payload.product_id == undefined ? 0 : payload.product_id),
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const add_product_field_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(SERVICE_URL.ADD_PRODUCT_FIELD, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
