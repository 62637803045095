export const GET_CALLERINFORMATION = 'GET_CALLERINFORMATION';
export const GET_CALLERINFORMATION_REQUESTED = 'GET_CALLERINFORMATION_REQUESTED';
export const GET_CALLERINFORMATION_SUCCESS = 'GET_CALLERINFORMATION_SUCCESS';
export const GET_CALLERINFORMATION_FAILED = 'GET_CALLERINFORMATION_FAILED';
export const GET_CALLERINFORMATION_CLEAR = 'GET_CALLERINFORMATION_CLEAR'

export const INSERT_CALLERINFORMATION = 'INSERT_CALLERINFORMATION';
export const INSERT_CALLERINFORMATION_REQUESTED = 'INSERT_CALLERINFORMATION_REQUESTED';
export const INSERT_CALLERINFORMATION_SUCCESS = 'INSERT_CALLERINFORMATION_SUCCESS';
export const INSERT_CALLERINFORMATION_FAILED = 'INSERT_CALLERINFORMATION_FAILED';
export const INSERT_CALLERINFORMATION_DONE = 'INSERT_CALLERINFORMATION_DONE'

export function get_CallerInformationData(payload: any) {
    return {
        type: 'GET_CALLERINFORMATION_REQUESTED',
        payload: payload,
    };
}
export function get_CallerInformationDataSuccess(payload: any) {
    return {
        type: 'GET_CALLERINFORMATION_SUCCESS',
        payload: payload,
    };
}
export function get_CallerInformationDataFailed(payload: any) {
    return {
        type: 'GET_CALLERINFORMATION_FAILED',
        payload: '',
    };
}
export function get_CallerInformationDataClear() {
    return {
        type: 'GET_CALLERINFORMATION_CLEAR'
    };
}


export function insert_CallerInformationData(payload: any) {
    return {
        type: 'INSERT_CALLERINFORMATION_REQUESTED',
        payload: payload,
    };
}
export function insert_CallerInformationDataSuccess(payload: any) {
    return {
        type: 'INSERT_CALLERINFORMATION_SUCCESS',
        payload: payload,
    };
}
export function insert_CallerInformationDataFailed(payload: any) {
    return {
        type: 'INSERT_CALLERINFORMATION_FAILED',
        payload: '',
    };
}
export function insert_CallerInformationDataDone() {
    return {
        type: 'INSERT_CALLERINFORMATION_DONE'
    };
}