import React, { lazy, Suspense } from 'react';
import cookie from 'react-cookies';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingScreen from './component/LoadingScreen';

const Dashboard = lazy(() => import('./page/Dashboard/Dashboard'));
const LiveMonitoring = lazy(
  () => import('./page/LiveMonitoring/LiveMonitoring')
);
const CompanySetting = lazy(
  () => import('./page/CompanySetting/CompanySetting')
);
const ProductDepartment = lazy(
  () => import('./page/ProductDepartment/ProductDepartment')
);
const AddEditCompany = lazy(
  () => import('./page/CompanySetting/Company/AddEditCompany')
);
const AddEditMailConfigure = lazy(
  () => import('./page/CompanySetting/MailConfigure/AddEditMailConfigure')
);
const AddEditCallConfigure = lazy(
  () => import('./page/CompanySetting/CallConfigure/AddEditCallConfigure')
);
const SalesConfiguration = lazy(
  () => import('./page/SalesConfiguration/SalesConfiguration')
);

const SalesAddEditCategory = lazy(
  () => import('./page/SalesConfiguration/Category/AddEditCategory')
);

const SalesAddEditCategoryMapping = lazy(
  () => import('./page/SalesConfiguration/Category/AddEditCategoryMapping')
);

const SalesAddEditSubCategory = lazy(
  () => import('./page/SalesConfiguration/SubCategory/AddEditSubCategory')
);

const SalesAddEditSubCategoryMapping = lazy(
  () => import('./page/SalesConfiguration/SubCategory/AddEditSubCategoryMapping')
);
const SalesAddEditTicketFieldMapping = lazy(
  () =>
    import(
      './page/SalesConfiguration/TicketFieldGroupMapping/AddEditTicketFieldGroupMapping'
    )
);
const SalesAddEditCategoryGroup = lazy(
  () => import('./page/SalesConfiguration/categoryGroup/AddEditCategoryGroup')
);

const SalesAddEditCategoryGroupMapping = lazy(
  () =>
    import('./page/SalesConfiguration/categoryGroup/AddEditCategoryGroupMapping')
);






const CategorySetting = lazy(
  () => import('./page/CategorySetting/CategorySetting')
);

const AddEditCategory = lazy(
  () => import('./page/CategorySetting/Category/AddEditCategory')
);

const AddEditCategoryMapping = lazy(
  () => import('./page/CategorySetting/Category/AddEditCategoryMapping')
);

const AddEditSubCategory = lazy(
  () => import('./page/CategorySetting/SubCategory/AddEditSubCategory')
);

const AddEditSubCategoryMapping = lazy(
  () => import('./page/CategorySetting/SubCategory/AddEditSubCategoryMapping')
);
const AddEditTicketFieldMapping = lazy(
  () =>
    import(
      './page/CategorySetting/TicketFieldGroupMapping/AddEditTicketFieldGroupMapping'
    )
);
const AddEditCategoryGroup = lazy(
  () => import('./page/CategorySetting/categoryGroup/AddEditCategoryGroup')
);

const AddEditCategoryGroupMapping = lazy(
  () =>
    import('./page/CategorySetting/categoryGroup/AddEditCategoryGroupMapping')
);

const AddEditMailTemplate = lazy(
  () => import('./page/CompanySetting/TemplateSetting/AddEditMailTemplate')
);
const TemplateSetting = lazy(
  () => import('./page/CompanySetting/TemplateSetting/TemplateSetting')
);

const AddEditTicketSourceMapping = lazy(
  () =>
    import(
      './page/ProductDepartment/DepartmentSourceMapping/AddEditDepartmentSourceMapping'
    )
);

// const ProductFields = lazy(
//   () => import('./page/ProductDepartment/ProductFields/ProductFields')
// );

const TicketList = lazy(() => import('./page/Ticket/TicketList'));
const TicketListDetails = lazy(() => import('./page/Ticket/TicketListDetails'));
const Login = lazy(() => import('./page/login/Login'));
const LoginExpire = lazy(() => import('./page/login/LoginExpire'));
const Mail = lazy(() => import('./page/Mail/Mail'));
const NotFound = lazy(() => import('./page/NotFound'));
const Users = lazy(() => import('./page/Users/Users'));
const AddEditUser = lazy(() => import('./page/Users/AddEditUser'));
const AgentMapping = lazy(
  () => import('./page/Users/AgentMapping/AgentMapping')
);
const AddEditAgentMapping = lazy(
  () => import('./page/Users/AgentMapping/AddEditAgentMapping')
);

const MainRouter = () => {
  const isUserLoggedIn = (component: any) => {
    if (cookie.load('token')) {
      return component;
    } else {
      return <Navigate replace to='/login' />;
    }
  };

  return (
    <Suspense fallback >
      <Routes>
        <Route
          path='/'
          element={isUserLoggedIn(<Navigate replace to='/dashboard' />)}
        />
        <Route path='/login' element={<Login />} />
        <Route path='/login_expire' element={<LoginExpire />} />
        <Route
          path='/companyConfigure/companySetting'
          element={isUserLoggedIn(<CompanySetting />)}
        />
        <Route
          path='/companyConfigure/companySetting/company'
          element={isUserLoggedIn(<CompanySetting />)}
        />
        <Route
          path='/companyConfigure/companySetting/company/:companyId'
          element={isUserLoggedIn(<AddEditCompany />)}
        />
        <Route
          path='/companyConfigure/companySetting/email'
          element={isUserLoggedIn(<CompanySetting />)}
        />
        <Route
          path='/companyConfigure/companySetting/call'
          element={isUserLoggedIn(<CompanySetting />)}
        />
        <Route
          path='/companyConfigure/companySetting/call/:config_id'
          element={isUserLoggedIn(<AddEditCallConfigure />)}
        />
        <Route
          path='/companyConfigure/companySetting/email/:config_id'
          element={isUserLoggedIn(<AddEditMailConfigure />)}
        />
        <Route
          path='/companyConfigure/templateSetting'
          element={isUserLoggedIn(<TemplateSetting />)}
        />
        <Route
          path='/companyConfigure/templateSetting/mailTemplate'
          element={isUserLoggedIn(<TemplateSetting />)}
        />
        <Route
          path='/companyConfigure/templateSetting/mailTemplate/:template_id'
          element={isUserLoggedIn(<AddEditMailTemplate />)}
        />
        <Route
          path='/companyConfigure/templateSetting/smsTemplate'
          element={isUserLoggedIn(<TemplateSetting />)}
        />
        <Route
          path='/companyConfigure/productDepartment'
          element={isUserLoggedIn(<ProductDepartment />)}
        />
        <Route
          path='/companyConfigure/productDepartment/product'
          element={isUserLoggedIn(<ProductDepartment />)}
        />
        <Route
          path='/companyConfigure/productDepartment/department'
          element={isUserLoggedIn(<ProductDepartment />)}
        />
        <Route
          path='/companyConfigure/productDepartment/departmentTicketSourceMapping'
          element={isUserLoggedIn(<ProductDepartment />)}
        />
        <Route
          path='/companyConfigure/productDepartment/productfields'
          element={isUserLoggedIn(<ProductDepartment />)}
        />
        <Route
          path='/companyConfigure/productDepartment/departmentSourceMapping/:source_id'
          element={isUserLoggedIn(<AddEditTicketSourceMapping />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryType'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryGroup'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryGroupMapping'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryGroup/:category_group_id'
          element={isUserLoggedIn(<SalesAddEditCategoryGroup />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryGroupMapping/:category_group_id'
          element={isUserLoggedIn(<SalesAddEditCategoryGroupMapping />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/category'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryMapping'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/category/:category_id'
          element={isUserLoggedIn(<SalesAddEditCategory />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/categoryMapping/:category_id'
          element={isUserLoggedIn(<SalesAddEditCategoryMapping />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/subCategory'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/subCategoryMapping'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/subCategory/:subcategory_id'
          element={isUserLoggedIn(<SalesAddEditSubCategory />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/subCategoryMapping/:subcategory_id'
          element={isUserLoggedIn(<SalesAddEditSubCategoryMapping />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/ticketField'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/ticketFieldMap'
          element={isUserLoggedIn(<SalesConfiguration />)}
        />
        <Route
          path='/workFlowConfigure/salesConfiguration/AddEditTicketFieldMapping/:header_id'
          element={isUserLoggedIn(<SalesAddEditTicketFieldMapping />)}
        />







        <Route
          path='/workFlowConfigure/categorySetting'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryType'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryGroup'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryGroupMapping'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryGroup/:category_group_id'
          element={isUserLoggedIn(<AddEditCategoryGroup />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryGroupMapping/:category_group_id'
          element={isUserLoggedIn(<AddEditCategoryGroupMapping />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/category'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryMapping'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/category/:category_id'
          element={isUserLoggedIn(<AddEditCategory />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/categoryMapping/:category_id'
          element={isUserLoggedIn(<AddEditCategoryMapping />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/subCategory'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/subCategoryMapping'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/subCategory/:subcategory_id'
          element={isUserLoggedIn(<AddEditSubCategory />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/subCategoryMapping/:subcategory_id'
          element={isUserLoggedIn(<AddEditSubCategoryMapping />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/ticketField'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route path='/ticketList' element={isUserLoggedIn(<TicketList />)} />
        <Route
          path='/ticketList/detail/:ticket_id/:unique_id'
          element={isUserLoggedIn(<TicketListDetails />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/ticketFieldMap'
          element={isUserLoggedIn(<CategorySetting />)}
        />
        <Route
          path='/workFlowConfigure/categorySetting/AddEditTicketFieldMapping/:header_id'
          element={isUserLoggedIn(<AddEditTicketFieldMapping />)}
        />
        <Route path='/user' element={isUserLoggedIn(<Users />)} />
        <Route path='/user/agentMapping' element={isUserLoggedIn(<Users />)} />
        <Route
          path='/user/addEditAgentMapping/:id'
          element={isUserLoggedIn(<AddEditAgentMapping />)}
        />
        <Route
          path='/user/:user_id'
          element={isUserLoggedIn(<AddEditUser />)}
        />
        <Route path='/mail' element={isUserLoggedIn(<Mail />)} />
        <Route path='/mail/:systemLabel' element={isUserLoggedIn(<Mail />)} />
        <Route
          path='/mail/:systemLabel/:mail_id'
          element={isUserLoggedIn(<Mail />)}
        />
        <Route
          path='/mail/:systemLabel/:mail_id/:id'
          element={isUserLoggedIn(<Mail />)}
        />
        <Route path='/dashboard' element={isUserLoggedIn(<Dashboard />)} />
        <Route
          path='/liveMonitoring'
          element={isUserLoggedIn(<LiveMonitoring />)}
        />
        <Route path='*' element={isUserLoggedIn(<NotFound />)} />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Routes>
    </Suspense>
  );
};
export default MainRouter;
