import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_Disposition1_API = async (payload: any) => {
    try {
        let headers = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + cookie.load('token'),
            },
        };
        let getDetails = await fetch(SERVICE_URL.GET_DISPOSITION + `?company_id=${payload.company_id}&dispo_id=${payload.dispo_id}&source_id=${payload.source_id}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    } catch (e) {
        console.log(e);
    }
};

export const get_Disposition2_API = async (payload: any) => {
    try {
        let headers = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + cookie.load('token'),
            },
        };
        let getDetails = await fetch(SERVICE_URL.GET_DISPOSITION + `?company_id=${payload.company_id}&dispo_id=${payload.dispo_id}&source_id=${payload.source_id}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    } catch (e) {
        console.log(e);
    }
};
export const get_Disposition3_API = async (payload: any) => {
    try {
        let headers = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + cookie.load('token'),
            },
        };
        let getDetails = await fetch(SERVICE_URL.GET_DISPOSITION + `?company_id=${payload.company_id}&dispo_id=${payload.dispo_id}&source_id=${payload.source_id}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    } catch (e) {
        console.log(e);
    }
};