export const GET_SUB_CATEGORYMAPPING = 'GET_SUB_CATEGORYMAPPING';
export const GET_SUB_CATEGORYMAPPING_REQUESTED = 'GET_SUB_CATEGORYMAPPING_REQUESTED';
export const GET_SUB_CATEGORYMAPPING_SUCCESS = 'GET_SUB_CATEGORYMAPPING_SUCCESS';
export const GET_SUB_CATEGORYMAPPING_FAILED = 'GET_SUB_CATEGORYMAPPING_FAILED';

export const ADD_SUB_CATEGORYMAPPING = 'ADD_SUB_CATEGORYMAPPING';
export const ADD_SUB_CATEGORYMAPPING_REQUESTED = 'ADD_SUB_CATEGORYMAPPING_REQUESTED';
export const ADD_SUB_CATEGORYMAPPING_SUCCESS = 'ADD_SUB_CATEGORYMAPPING_SUCCESS';
export const ADD_SUB_CATEGORYMAPPING_FAILED = 'ADD_SUB_CATEGORYMAPPING_FAILED';
export const ADD_SUB_CATEGORYMAPPING_DONE = 'ADD_SUB_CATEGORYMAPPING_DONE';

export const CHECK_SUB_CATEGORYMAPPING = 'CHECK_SUB_CATEGORYMAPPING';
export const CHECK_SUB_CATEGORYMAPPING_REQUESTED = 'CHECK_SUB_CATEGORYMAPPING_REQUESTED';
export const CHECK_SUB_CATEGORYMAPPING_SUCCESS = 'CHECK_SUB_CATEGORYMAPPING_SUCCESS';
export const CHECK_SUB_CATEGORYMAPPING_FAILED = 'CHECK_SUB_CATEGORYMAPPING_FAILED';
export const CHECK_SUB_CATEGORYMAPPING_DONE = 'CHECK_SUB_CATEGORYMAPPING_DONE';

export const FIELDWISE_SUBCATEGORY = 'FIELDWISE_SUBCATEGORY';
export const FIELDWISE_SUBCATEGORY_REQUESTED = 'FIELDWISE_SUBCATEGORY_REQUESTED';
export const FIELDWISE_SUBCATEGORY_SUCCESS = 'FIELDWISE_SUBCATEGORY_SUCCESS';
export const FIELDWISE_SUBCATEGORY_FAILED = 'FIELDWISE_SUBCATEGORY_FAILED';
export const FIELDWISE_SUBCATEGORY_DONE = 'FIELDWISE_SUBCATEGORY_DONE';


export function get_subCategoryMappingData() {
  return {
    type: 'GET_SUB_CATEGORYMAPPING_REQUESTED',
  };
}
export function get_subCategoryMappingDataSuccess(payload: any) {
  return {
    type: 'GET_SUB_CATEGORYMAPPING_SUCCESS',
    payload: payload,
  };
}
export function get_subCategoryMappingDataFailed(payload: any) {
  return {
    type: 'GET_SUB_CATEGORYMAPPING_FAILED',
    payload: '',
  };
}

export function add_subCategoryMappingData(payload: any) {
  return {
    type: 'ADD_SUB_CATEGORYMAPPING_REQUESTED',
    payload: payload,
  };
}
export function add_subCategoryMappingDataSuccess(payload: any) {
  return {
    type: 'ADD_SUB_CATEGORYMAPPING_SUCCESS',
    payload: payload,
  };
}
export function add_subCategoryMappingDataFailed(payload: any) {
  return {
    type: 'ADD_SUB_CATEGORYMAPPING_FAILED',
    payload: '',
  };
}

export function add_subCategoryMappingDone() {
  return {
    type: 'ADD_SUB_CATEGORYMAPPING_DONE',
  };
}


export function check_subCategoryMappingData(payload: any) {
  return {
    type: 'CHECK_SUB_CATEGORYMAPPING_REQUESTED',
    payload: payload,
  };
}
export function check_subCategoryMappingDataSuccess(payload: any) {
  return {
    type: 'CHECK_SUB_CATEGORYMAPPING_SUCCESS',
    payload: payload,
  };
}
export function check_subCategoryMappingDataFailed(payload: any) {
  return {
    type: 'CHECK_SUB_CATEGORYMAPPING_FAILED',
    payload: '',
  };
}


export function getFieldwiseSubcategoryData(payload: any) {
  return {
    type: 'FIELDWISE_SUBCATEGORY_REQUESTED',
    payload: payload,
  };
}
export function getFieldwiseSubcategoryDataSuccess(payload: any) {
  return {
    type: 'FIELDWISE_SUBCATEGORY_SUCCESS',
    payload: payload,
  };
}
export function getFieldwiseSubcategoryDataFailed(payload: any) {
  return {
    type: 'FIELDWISE_SUBCATEGORY_FAILED',
    payload: '',
  };
}



