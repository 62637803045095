import { call, put, takeLatest } from 'redux-saga/effects';
import { get_TicketStatus_Failed, GET_TICKETSTATUS_REQUEST, get_TicketStatus_Success } from '../action/ticketStatus-action';
import { get_TicketStatus_API } from '../api/ticketStatus-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getTicketStatus(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_TicketStatus_API, action.payload);
    yield put(get_TicketStatus_Success(getResponse));
  } catch (error) {
    yield put(get_TicketStatus_Failed(error));
  }
}

export function* getTicketStatusSaga() {
  yield takeLatest(GET_TICKETSTATUS_REQUEST, getTicketStatus)
}