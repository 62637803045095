import cookie from 'react-cookies';
import { ADD_TICKET_FIELD_GROUP_MAPPING, ADD_TICKET_FIELD_GROUP_MAPPING_DONE, ADD_TICKET_FIELD_GROUP_MAPPING_FAILED, ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED, ADD_TICKET_FIELD_GROUP_MAPPING_SUCCESS, CHECK_TICKET_FIELD_GROUP_MAPPING, CHECK_TICKET_FIELD_GROUP_MAPPING_DONE, CHECK_TICKET_FIELD_GROUP_MAPPING_FAILED, CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED, CHECK_TICKET_FIELD_GROUP_MAPPING_SUCCESS, GET_TICKET_FIELD_GROUP_MAPPING, GET_TICKET_FIELD_GROUP_MAPPING_FAILED, GET_TICKET_FIELD_GROUP_MAPPING_SUCCESS } from '../action/ticketFieldGroupMapping-action';

const initialState = {
    loading: false,
    getData: [],
    addData: [],
    checkData: [],
    addTicketFieldGroupMappingStatus: false,
    checkTicketFieldGroupMappingStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TICKET_FIELD_GROUP_MAPPING:
            return {
                ...state,
                loading: true
            }
        case GET_TICKET_FIELD_GROUP_MAPPING_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_TICKET_FIELD_GROUP_MAPPING_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }
        case ADD_TICKET_FIELD_GROUP_MAPPING_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case ADD_TICKET_FIELD_GROUP_MAPPING_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addTicketFieldGroupMappingStatus: true,
                    addData: action.payload,
                    loading: false
                }
            }
        case ADD_TICKET_FIELD_GROUP_MAPPING_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: [],
                    loading: false
                }
            }
        case ADD_TICKET_FIELD_GROUP_MAPPING_DONE:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addTicketFieldGroupMappingStatus: false,
                    loading: false
                }
            }
        case CHECK_TICKET_FIELD_GROUP_MAPPING_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case CHECK_TICKET_FIELD_GROUP_MAPPING_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    checkTicketFieldGroupMappingStatus: true,
                    checkData: action.payload,
                    loading: false
                }
            }
        case CHECK_TICKET_FIELD_GROUP_MAPPING_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    checkData: [],
                    loading: false
                }
            }
        case CHECK_TICKET_FIELD_GROUP_MAPPING_DONE:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    checkData: [],
                    checkTicketFieldGroupMappingStatus: false,
                    loading: false
                }
            }
        default:
            return state
    }
}