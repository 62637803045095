import { fork } from 'redux-saga/effects';
import { loginSaga } from './loginRedux/saga/login-saga';
import { companyGroupSaga } from './companyGroupRedux/saga/companyGroup-saga';
import { productSaga } from './productRedux/saga/product-saga';
import { companySaga } from './companyRedux/saga/company-saga';
import { categorySaga } from './categoryRedux/saga/category-saga';
import { subCategorySaga } from './subCategoryRedux/saga/subCategory-saga';
import { departmentSaga } from './departmentReducer/saga/department-saga';
import { prioritySaga } from './priorityRedux/saga/priority-saga';
import { usersSaga } from './usersRedux/saga/users-saga';
import { categoryTypeSaga } from './category_TypeRedux/saga/categoryType-saga';
import { mailConfigureSaga } from './mailConfigureRedux/saga/mailConfigure-saga';
import { mailTemplateSaga } from './mailTemplateRedux/saga/mailTemplate-saga';
import { ticketSourceSaga } from './ticketSource/saga/ticketSource-saga';
import { mailInboxSaga } from './mailInboxRedux/saga/mailInbox-saga';
import { categoryGroupSaga } from './categoryGroupRedux/saga/categoryGroup-saga';
import { callConfigurationSaga } from './callConfigurationRedux/saga/callConfiguration-saga';
import { mailSentSaga } from './mailSentRedux/saga/mailSent-saga';
import { callOutboundRouteSaga } from './callOutboundRouteRedux/saga/callOutboundRoute-saga';
import { categoryGroupMappingSaga } from './categoryGroupMappingRedux/saga/categoryGroupMapping-saga';
import { categoryMappingSaga } from './categoryMappingRedux/saga/categoryMapping-saga';
import { subCategoryMappingSaga } from './subCategoryMappingRedux/saga/subCategoryMapping-saga';
import { ticketFieldSaga } from './ticketFieldRedux/saga/ticketField-saga';
import { ticketSourceDetailsSaga } from './ticketSourceDetailsRedux/saga/ticketSourceDetails-saga';
import { usersTypeSaga } from './usersTypeRedux/saga/usersType-saga';
import { usersRollSaga } from './usersRollRedux/saga/usersRoll-saga';
import { ticketFieldGroupMappingSaga } from './ticketFieldGroupMapping/saga/ticketFieldGroupMapping-saga';
import { deptwiseSourceSaga } from './deptwiseTicketSourceMappingRedux/deptwiseSourceRedux/saga/deptwiseSource-saga';
import { deptwiseSourceDetailsSaga } from './deptwiseTicketSourceMappingRedux/deptwiseSourceDetailsRedux/saga/deptwiseSourceDetails-saga';
import { deptwiseAgentSaga } from './deptwiseTicketSourceMappingRedux/deptwiseAgentRedux/saga/deptwiseAgent-saga';
import { agentMappingSaga } from './agentMappingRedux/saga/agentMapping-saga';
import { deptSourceMappingSaga } from './deptSourceMapping/saga/deptSourceMapping-saga';
import { getPastTicketInteractionSaga } from './pastTicketInteractionRedux/saga/pastTicketInteraction-saga';
import { getTicketSaga } from './TicketRedux/saga/ticket-saga';
import { insert_TicketConvertionSaga } from './ticketConvertionRedux/saga/ticketConvertion-saga';
import { get_CallerInformationSaga } from './callerInformationRedux/saga/callerInformation-saga';
import { getTicketStatusSaga } from './ticketStatus/saga/ticketStatus-saga';
import { get_DispositionSaga } from './dispositionRedux/saga/disposition-saga';
import { get_adminTcktDashboardSaga } from './adminTcktDashboard/saga/adminTcktDashboard-saga';
// Sagas

// Export the root saga
export default function* rootSaga() {
  yield fork(loginSaga);
  yield fork(productSaga);
  yield fork(companyGroupSaga);
  yield fork(companySaga);
  yield fork(categorySaga);
  yield fork(categoryMappingSaga);
  yield fork(subCategorySaga);
  yield fork(subCategoryMappingSaga);
  yield fork(ticketFieldSaga);
  yield fork(ticketFieldGroupMappingSaga);
  yield fork(categoryTypeSaga);
  yield fork(departmentSaga);
  yield fork(deptSourceMappingSaga);
  yield fork(deptwiseSourceSaga);
  yield fork(deptwiseSourceDetailsSaga);
  yield fork(deptwiseAgentSaga);
  yield fork(agentMappingSaga);
  yield fork(prioritySaga);
  yield fork(usersSaga);
  yield fork(usersTypeSaga);
  yield fork(usersRollSaga);
  yield fork(mailConfigureSaga);
  yield fork(mailTemplateSaga);
  yield fork(ticketSourceSaga);
  yield fork(ticketSourceDetailsSaga);
  yield fork(mailInboxSaga);
  yield fork(mailSentSaga);
  yield fork(categoryGroupSaga);
  yield fork(categoryGroupMappingSaga);
  yield fork(callConfigurationSaga);
  yield fork(callOutboundRouteSaga);
  yield fork(getPastTicketInteractionSaga);
  yield fork(getTicketSaga);
  yield fork(insert_TicketConvertionSaga);
  yield fork(get_CallerInformationSaga);
  yield fork(getTicketStatusSaga)
  yield fork(get_DispositionSaga);
  yield fork(get_adminTcktDashboardSaga)
}
