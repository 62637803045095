
export const GET_MAILSENT = "GET_MAILSENT"
export const GET_MAILSENT_REQUESTED = "GET_MAILSENT_REQUESTED"
export const GET_MAILSENT_SUCCESS = "GET_MAILSENT_SUCCESS"
export const GET_MAILSENT_FAILED = "GET_MAILSENT_FAILED"

export const ADD_MAILSENT = "ADD_MAILSENT"
export const ADD_MAILSENT_REQUESTED = "ADD_MAILSENT_REQUESTED"
export const ADD_MAILSENT_SUCCESS = "ADD_MAILSENT_SUCCESS"
export const ADD_MAILSENT_FAILED = "ADD_MAILSENT_FAILED"
export const ADD_MAILSENT_DONE = "ADD_MAILSENT_DONE"


export function get_MailSentData(){
    return {
        type: 'GET_MAILSENT_REQUESTED'
    }
}
export function get_MailSentDataSuccess(payload: any){     
    return {
        type: 'GET_MAILSENT_SUCCESS',
        payload: payload
    }
}
export function get_MailSentDataFailed(payload: any){
    return {
        type: 'GET_MAILSENT_FAILED',
        payload: ''
    }
}

export function add_MailSentData(payload: any){
    return {
        type: 'ADD_MAILSENT_REQUESTED',
        payload: payload
    }
}
export function add_MailSentDataSuccess(payload: any){     
    return {
        type: 'ADD_MAILSENT_SUCCESS',
        payload: payload
    }
}
export function add_MailSentDataFailed(payload: any){
    return {
        type: 'ADD_MAILSENT_FAILED',
        payload: ''
    }
}
export function add_MailSentDone() {
    return {
        type: 'ADD_MAILSENT_DONE'
    }
}