import { call, put, takeLatest } from 'redux-saga/effects';
import { add_MailTemplateDataFailed, add_MailTemplateDataSuccess, ADD_MAIL_TEMPLATE_REQUESTED, get_MailTemplateDataFailed, get_MailTemplateDataSuccess, GET_MAIL_TEMPLATE_REQUESTED } from '../action/mailTemplate-action';
import { add_mailTemplate_API, get_mailTemplate_API } from '../api/mailTemplate-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getMailTemplate(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_mailTemplate_API, action.payload);
   yield put(get_MailTemplateDataSuccess(getResponse));
   } catch (error) {
    yield put(get_MailTemplateDataFailed(error));
   }
}

function* addMailTemplate(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(add_mailTemplate_API, action.payload);
   yield put(add_MailTemplateDataSuccess(getResponse));
   } catch (error) {
    yield put(add_MailTemplateDataFailed(error));
   }
}

export function* mailTemplateSaga() {
  yield takeLatest(GET_MAIL_TEMPLATE_REQUESTED, getMailTemplate)
  yield takeLatest(ADD_MAIL_TEMPLATE_REQUESTED, addMailTemplate)
}