import { call, put, takeLatest } from 'redux-saga/effects';
import {
  add_ProductFailed,
  add_ProductSuccess,
  ADD_PRODUCT_REQUESTED,
  get_ProductFailed,
  get_ProductSuccess,
  GET_PRODUCT_REQUESTED,
  GET_PRODUCT_FIELD_REQUESTED,
  get_ProductFieldDone,
  get_ProductFieldFailed,
  ADD_PRODUCT_FIELD_REQUESTED,
  add_ProductFieldSuccess,
  add_ProductFieldFailed,
} from '../action/product-action';
import {
  add_product_API,
  get_product_API,
  get_product_Field_API,
  add_product_field_API,
} from '../api/product-api';
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getProduct(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_product_API,
      action.payload
    );
    yield put(get_ProductSuccess(getResponse));
  } catch (error) {
    yield put(get_ProductFailed(error));
  }
}

function* getProductField(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_product_Field_API,
      action.payload
    );
    yield put(get_ProductFieldDone(getResponse));
  } catch (error) {
    yield put(get_ProductFieldFailed(error));
  }
}

function* addProduct(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_product_API,
      action.payload
    );
    yield put(add_ProductSuccess(getResponse));
  } catch (error) {
    yield put(add_ProductFailed(error));
  }
}

function* addProductField(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_product_field_API,
      action.payload
    );
    yield put(add_ProductFieldSuccess(getResponse));
  } catch (error) {
    yield put(add_ProductFieldFailed(error));
  }
}

export function* productSaga() {
  yield takeLatest(GET_PRODUCT_REQUESTED, getProduct);
  yield takeLatest(GET_PRODUCT_FIELD_REQUESTED, getProductField);
  yield takeLatest(ADD_PRODUCT_REQUESTED, addProduct);
  yield takeLatest(ADD_PRODUCT_FIELD_REQUESTED, addProductField);
}
