import { call, put, takeLatest } from 'redux-saga/effects';
import { get_DeptSourceMappingSuccess, get_DeptSourceMappingFailed, add_DeptSourceMappingSuccess, add_DeptSourceMappingFailed, GET_DEPT_SOURCEMAPPING_REQUESTED, ADD_DEPT_SOURCEMAPPING_REQUESTED } from '../action/deptSourceMapping-action';
import { get_deptSourceMapping_API, add_deptSourceMapping_API } from '../api/deptSourceMapping-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getDeptSourceMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_deptSourceMapping_API, action.payload);
    yield put(get_DeptSourceMappingSuccess(getResponse));
  } catch (error) {
    yield put(get_DeptSourceMappingFailed(error));
  }
}

function* addDeptSourceMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_deptSourceMapping_API, action.payload);
    yield put(add_DeptSourceMappingSuccess(getResponse));
  } catch (error) {
    yield put(add_DeptSourceMappingFailed(error));
  }
}


export function* deptSourceMappingSaga() {
  yield takeLatest(GET_DEPT_SOURCEMAPPING_REQUESTED, getDeptSourceMapping)
  yield takeLatest(ADD_DEPT_SOURCEMAPPING_REQUESTED, addDeptSourceMapping)
}