export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_REQUESTED = 'GET_CATEGORY_REQUESTED';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_REQUESTED = 'ADD_CATEGORY_REQUESTED';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED';
export const ADD_CATEGORY_DONE = 'ADD_CATEGORY_DONE';
export function get_CategoryData(payload: any) {
  return {
    type: 'GET_CATEGORY_REQUESTED',
    payload: payload,
  };
}
export function get_CategoryDataSuccess(payload: any) {
  return {
    type: 'GET_CATEGORY_SUCCESS',
    payload: payload,
  };
}
export function get_CategoryDataFailed(payload: any) {
  return {
    type: 'GET_CATEGORY_FAILED',
    payload: '',
  };
}

export function add_CategoryData(payload: any) {
  return {
    type: 'ADD_CATEGORY_REQUESTED',
    payload: payload,
  };
}
export function add_CategoryDataSuccess(payload: any) {
  return {
    type: 'ADD_CATEGORY_SUCCESS',
    payload: payload,
  };
}
export function add_CategoryDataFailed(payload: any) {
  return {
    type: 'ADD_CATEGORY_FAILED',
    payload: '',
  };
}
export function add_CategoryDone() {
  return {
    type: 'ADD_CATEGORY_DONE',
  };
}
