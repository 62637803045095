
import cookie from 'react-cookies';
import { ADD_MAIL_TEMPLATE, ADD_MAIL_TEMPLATE_DONE, ADD_MAIL_TEMPLATE_FAILED, ADD_MAIL_TEMPLATE_SUCCESS, GET_MAIL_TEMPLATE, GET_MAIL_TEMPLATE_FAILED, GET_MAIL_TEMPLATE_SUCCESS } from '../action/mailTemplate-action';

const initialState = {
    loading: false,
    data: [],
    addData: {},
    addMailTemplateStatus: false
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MAIL_TEMPLATE:
            return {
                ...state,
                loading: true,
            }
        case GET_MAIL_TEMPLATE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_MAIL_TEMPLATE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: [],
                    loading: false
                }
            }
        case ADD_MAIL_TEMPLATE:
            return {
                ...state,
                loading: true,
            }
        case ADD_MAIL_TEMPLATE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addMailTemplateStatus: true,
                }
            }
        case ADD_MAIL_TEMPLATE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: {}
                }
            }
        case ADD_MAIL_TEMPLATE_DONE:
            return {
                ...state,
                addData: {},
                addMailTemplateStatus: false
            }
        default:
            return state
    }
}