
export const GET_PRIMARY_TICKET_SOURCE_DETAILS = "GET_PRIMARY_TICKET_SOURCE_DETAILS"
export const GET_PRIMARY_TICKET_SOURCE_DETAILS_REQUESTED = "GET_PRIMARY_TICKET_SOURCE_DETAILS_REQUESTED"
export const GET_PRIMARY_TICKET_SOURCE_DETAILS_SUCCESS = "GET_PRIMARY_TICKET_SOURCE_DETAILS_SUCCESS"
export const GET_PRIMARY_TICKET_SOURCE_DETAILS_FAILED = "GET_PRIMARY_TICKET_SOURCE_DETAILS_FAILED"

export const GET_SECONDARY_TICKET_SOURCE_DETAILS = "GET_SECONDARY_TICKET_SOURCE_DETAILS"
export const GET_SECONDARY_TICKET_SOURCE_DETAILS_REQUESTED = "GET_SECONDARY_TICKET_SOURCE_DETAILS_REQUESTED"
export const GET_SECONDARY_TICKET_SOURCE_DETAILS_SUCCESS = "GET_SECONDARY_TICKET_SOURCE_DETAILS_SUCCESS"
export const GET_SECONDARY_TICKET_SOURCE_DETAILS_FAILED = "GET_SECONDARY_TICKET_SOURCE_DETAILS_FAILED"

export const ADD_TICKET_SOURCE_DETAILS = "ADD_TICKET_SOURCE_DETAILS"
export const ADD_TICKET_SOURCE_DETAILS_REQUESTED = "ADD_TICKET_SOURCE_DETAILS_REQUESTED"
export const ADD_TICKET_SOURCE_DETAILS_SUCCESS = "ADD_TICKET_SOURCE_DETAILS_SUCCESS"
export const ADD_TICKET_SOURCE_DETAILS_FAILED = "ADD_TICKET_SOURCE_DETAILS_FAILED"
export const ADD_TICKET_SOURCE_DETAILS_DONE = "ADD_TICKET_SOURCE_DETAILS_DONE"


export function get_PrimaryTicketSourceDetailsData(payload:any) {
    return {
        type: 'GET_PRIMARY_TICKET_SOURCE_DETAILS_REQUESTED',
        payload: payload
    }
}

export function get_PrimaryTicketSourceDetailsSuccess(payload:any) {
    return {
        type: 'GET_PRIMARY_TICKET_SOURCE_DETAILS_SUCCESS',
        payload: payload
    }
}
export function get_PrimaryTicketSourceDetailsFailed(payload:any) {
    return {
        type: 'GET_PRIMARY_TICKET_SOURCE_DETAILS_FAILED',
        payload: ''
    }
}

export function get_SecondaryTicketSourceDetailsData(payload:any) {
    return {
        type: 'GET_SECONDARY_TICKET_SOURCE_DETAILS_REQUESTED',
        payload: payload
    }
}

export function get_SecondaryTicketSourceDetailsSuccess(payload:any) {
    return {
        type: 'GET_SECONDARY_TICKET_SOURCE_DETAILS_SUCCESS',
        payload: payload
    }
}
export function get_SecondaryTicketSourceDetailsFailed(payload:any) {
    return {
        type: 'GET_SECONDARY_TICKET_SOURCE_DETAILS_FAILED',
        payload: ''
    }
}

export function add_TicketSourceDetailsData(payload:any) {
    return {
        type: 'ADD_TICKET_SOURCE_DETAILS_REQUESTED',
        payload: payload
    }
}
export function add_TicketSourceDetailsSuccess(payload:any) {
    return {
        type: 'ADD_TICKET_SOURCE_DETAILS_SUCCESS',
        payload: payload
    }
}
export function add_TicketSourceDetailsFailed(payload:any) {
    return {
        type: 'ADD_TICKET_SOURCE_DETAILS_FAILED',
        payload: ''
    }
}
export function add_TicketSourceDetailsDone() {
    return {
        type: 'ADD_TICKET_SOURCE_DETAILS_DONE'
    }
}