import { call, put, takeLatest } from 'redux-saga/effects';
import { add_categoryGroupMappingDataFailed, add_categoryGroupMappingDataSuccess, ADD_CATEGORYGROUPMAPPING_REQUESTED, check_categoryGroupMappingDataFailed, check_categoryGroupMappingDataSuccess, CHECK_CATEGORYGROUPMAPPING_REQUESTED, get_categoryGroupMappingDataFailed, get_categoryGroupMappingDataSuccess, GET_CATEGORYGROUPMAPPING_REQUESTED } from '../action/categoryGroupMapping-action';
import { add_categoryGroupMapping_API, check_categoryGroupMapping_API, get_categoryGroupMapping_API } from '../api/categoryGroupMapping-api';
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getCategoryGroupMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_categoryGroupMapping_API,
      action.payload
    );
    yield put(get_categoryGroupMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(get_categoryGroupMappingDataFailed(error));
  }
}

function* addCategoryGroupMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_categoryGroupMapping_API,
      action.payload
    );
    yield put(add_categoryGroupMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(add_categoryGroupMappingDataFailed(error));
  }
}

function* checkCategoryGroupMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      check_categoryGroupMapping_API,
      action.payload
    );
    yield put(check_categoryGroupMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(check_categoryGroupMappingDataFailed(error));
  }
}
export function* categoryGroupMappingSaga() {
  yield takeLatest(GET_CATEGORYGROUPMAPPING_REQUESTED, getCategoryGroupMapping);
  yield takeLatest(ADD_CATEGORYGROUPMAPPING_REQUESTED, addCategoryGroupMapping);
  yield takeLatest(CHECK_CATEGORYGROUPMAPPING_REQUESTED, checkCategoryGroupMapping);
}
