import cookie from 'react-cookies';
import { ADD_CATEGORYMAPPING, ADD_CATEGORYMAPPING_DONE, ADD_CATEGORYMAPPING_FAILED, ADD_CATEGORYMAPPING_REQUESTED, ADD_CATEGORYMAPPING_SUCCESS, CHECK_CATEGORYMAPPING, CHECK_CATEGORYMAPPING_FAILED, CHECK_CATEGORYMAPPING_REQUESTED, CHECK_CATEGORYMAPPING_SUCCESS, GET_CATEGORYMAPPING, GET_CATEGORYMAPPING_FAILED, GET_CATEGORYMAPPING_REQUESTED, GET_CATEGORYMAPPING_SUCCESS } from '../action/categoryMapping-action';
import {  SUBDOMAIN } from "../../Service"
const initialState = {
  loading: false,
  data: [],
  checkData:[], 
  addCategoryMappingStatus: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CATEGORYMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORYMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      }

    case GET_CATEGORYMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          loading: false,
        };
      }
    case ADD_CATEGORYMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORYMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          addData: action.payload,
          addCategoryMappingStatus: true,
          loading: false,
        };
      }
    case ADD_CATEGORYMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          addData: {},
          addCategoryMappingStatus: false,
          loading: false,
        };
      }
    case ADD_CATEGORYMAPPING_DONE:
      return {
        ...state,
        addData: {},
        addCategoryMappingStatus: false,
        loading: false,
      };

    case CHECK_CATEGORYMAPPING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CHECK_CATEGORYMAPPING_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          checkData: action.payload,
          loading: false,
        };
      }
    case CHECK_CATEGORYMAPPING_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();
        cookie.remove('username');
        cookie.remove('token');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          date: [],
          checkData: {},
          loading: false,
        };
      }
    default:
      return state;
  }
};
