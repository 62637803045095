import { call, put, takeLatest } from 'redux-saga/effects';
import { add_UsersDataFailed, add_UsersDataSuccess, ADD_USERS_REQUESTED, get_UsersDataFailed, get_UsersDataSuccess, GET_USERS_REQUESTED } from '../../usersRedux/action/users-action';
import { add_users_API, get_users_API } from '../../usersRedux/api/users-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getUsers(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_users_API, action.payload);
    yield put(get_UsersDataSuccess(getResponse));
  } catch (error) {
    yield put(get_UsersDataFailed(error));
  }
}

function* addUsers(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_users_API, action.payload);
    yield put(add_UsersDataSuccess(getResponse));
  } catch (error) {
    yield put(add_UsersDataFailed(error));
  }
}


export function* usersSaga() {
  yield takeLatest(GET_USERS_REQUESTED, getUsers)
  yield takeLatest(ADD_USERS_REQUESTED, addUsers)
}