import { call, put, takeLatest } from 'redux-saga/effects';
import { insert_TicketConvertionDataFailed, insert_TicketConvertionDataSuccess, INSERT_TICKET_CONVERTION_REQUESTED } from '../action/ticketConvertion-action';
import { insert_TicketConvertion_API } from '../api/ticketConvertion-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* insertTicketConvertion(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(insert_TicketConvertion_API, action.payload);
    yield put(insert_TicketConvertionDataSuccess(getResponse));
  } catch (error) {
    yield put(insert_TicketConvertionDataFailed(error));
  }
}

export function* insert_TicketConvertionSaga() {
  yield takeLatest(INSERT_TICKET_CONVERTION_REQUESTED, insertTicketConvertion)
}