export const GET_CATEGORYGROUPMAPPING = 'GET_CATEGORYGROUPMAPPING';
export const GET_CATEGORYGROUPMAPPING_REQUESTED = 'GET_CATEGORYGROUPMAPPING_REQUESTED';
export const GET_CATEGORYGROUPMAPPING_SUCCESS = 'GET_CATEGORYGROUPMAPPING_SUCCESS';
export const GET_CATEGORYGROUPMAPPING_FAILED = 'GET_CATEGORYGROUPMAPPING_FAILED';

export const ADD_CATEGORYGROUPMAPPING = 'ADD_CATEGORYGROUPMAPPING';
export const ADD_CATEGORYGROUPMAPPING_REQUESTED = 'ADD_CATEGORYGROUPMAPPING_REQUESTED';
export const ADD_CATEGORYGROUPMAPPING_SUCCESS = 'ADD_CATEGORYGROUPMAPPING_SUCCESS';
export const ADD_CATEGORYGROUPMAPPING_FAILED = 'ADD_CATEGORYGROUPMAPPING_FAILED';
export const ADD_CATEGORYGROUPMAPPING_DONE = 'ADD_CATEGORYGROUPMAPPING_DONE';

export const CHECK_CATEGORYGROUPMAPPING = 'CHECK_CATEGORYGROUPMAPPING';
export const CHECK_CATEGORYGROUPMAPPING_REQUESTED = 'CHECK_CATEGORYGROUPMAPPING_REQUESTED';
export const CHECK_CATEGORYGROUPMAPPING_SUCCESS = 'CHECK_CATEGORYGROUPMAPPING_SUCCESS';
export const CHECK_CATEGORYGROUPMAPPING_FAILED = 'CHECK_CATEGORYGROUPMAPPING_FAILED';
export const CHECK_CATEGORYGROUPMAPPING_CLEAR = 'CHECK_CATEGORYGROUPMAPPING_CLEAR'
export const CHECK_CATEGORYGROUPMAPPING_DONE = 'CHECK_CATEGORYGROUPMAPPING_DONE';

export function get_categoryGroupMappingData() {
  return {
    type: 'GET_CATEGORYGROUPMAPPING_REQUESTED',
  };
}
export function get_categoryGroupMappingDataSuccess(payload: any) {
  return {
    type: 'GET_CATEGORYGROUPMAPPING_SUCCESS',
    payload: payload,
  };
}
export function get_categoryGroupMappingDataFailed(payload: any) {
  return {
    type: 'GET_CATEGORYGROUPMAPPING_FAILED',
    payload: '',
  };
}

export function add_categoryGroupMappingData(payload: any) {
  return {
    type: 'ADD_CATEGORYGROUPMAPPING_REQUESTED',
    payload: payload,
  };
}
export function add_categoryGroupMappingDataSuccess(payload: any) {
  return {
    type: 'ADD_CATEGORYGROUPMAPPING_SUCCESS',
    payload: payload,
  };
}
export function add_categoryGroupMappingDataFailed(payload: any) {
  return {
    type: 'ADD_CATEGORYGROUPMAPPING_FAILED',
    payload: '',
  };
}

export function add_categoryGroupMappingDone() {
  return {
    type: 'ADD_CATEGORYGROUPMAPPING_DONE',
  };
}

export function check_categoryGroupMappingData(payload: any) {
  return {
    type: 'CHECK_CATEGORYGROUPMAPPING_REQUESTED',
    payload: payload,
  };
}
export function check_categoryGroupMappingDataSuccess(payload: any) {
  return {
    type: 'CHECK_CATEGORYGROUPMAPPING_SUCCESS',
    payload: payload,
  };
}
export function check_categoryGroupMappingDataFailed(payload: any) {
  return {
    type: 'CHECK_CATEGORYGROUPMAPPING_FAILED',
    payload: '',
  };
}
export function check_categoryGroupMappingDataClear() {
  return {
    type: 'CHECK_CATEGORYGROUPMAPPING_CLEAR',
  };
}
