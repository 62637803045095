import cookie from 'react-cookies';
import { GET_FILTERWISETICKETLIST_CLEAR, GET_FILTERWISETICKETLIST_FAILED, GET_FILTERWISETICKETLIST_REQUEST, GET_FILTERWISETICKETLIST_SUCCESS, GET_TICKETDASHBOARD_FAILED, GET_TICKETDASHBOARD_REQUEST, GET_TICKETDASHBOARD_SUCCESS, GET_TICKETLIST, GET_TICKETLISTDETAILS, GET_TICKETLISTDETAILS_CLEAR, GET_TICKETLISTDETAILS_FAILED, GET_TICKETLISTDETAILS_REQUEST, GET_TICKETLISTDETAILS_SUCCESS, GET_TICKETLIST_CLEAR, GET_TICKETLIST_FAILED, GET_TICKETLIST_REQUEST, GET_TICKETLIST_SUCCESS, GET_TICKETTRAILS_CLEAR, GET_TICKETTRAILS_FAILED, GET_TICKETTRAILS_REQUEST, GET_TICKETTRAILS_SUCCESS, INSERT_TICKETRESOLVED, INSERT_TICKETRESOLVED_DONE, INSERT_TICKETRESOLVED_FAILED, INSERT_TICKETRESOLVED_REQUEST, INSERT_TICKETRESOLVED_SUCCESS } from '../action/ticket-action';

const initialState = {
    loading: false,
    getTcktDashboardData: {},
    getTcktDetailsListData: {},
    getTcktTrailsData: {},
    trailsLoading: false,
    getTcktListData: {},
    insertTcktResolvedData: {},
    inserTicketResolvedStatus: false
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TICKETDASHBOARD_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_TICKETDASHBOARD_SUCCESS:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktDashboardData: action.payload,
                    loading: false,
                }
            }
        case GET_TICKETDASHBOARD_FAILED:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktDashboardData: {},
                    loading: false,
                }
            }
        case GET_TICKETLIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_TICKETLIST_SUCCESS:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktListData: action.payload,
                    loading: false,
                }
            }
        case GET_TICKETLIST_FAILED:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktListData: {},
                    loading: false,
                }
            }
        case GET_TICKETLIST_CLEAR:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktListData: {},
                    loading: false,
                }
            }


            case GET_FILTERWISETICKETLIST_REQUEST:
                return {
                    ...state,
                    loading: true,
                }
            case GET_FILTERWISETICKETLIST_SUCCESS:
                if (action?.payload?.Code === 400) {
                    localStorage.clear();
    
                    cookie.remove('agentToken');
                    window.location.href = `/login_expire`
                    return {}
                }
                else {
                    return {
                        ...state,
                        getTcktListData: action.payload,
                        loading: false,
                    }
                }
            case GET_FILTERWISETICKETLIST_FAILED:
                if (action?.payload?.Code === 400) {
                    localStorage.clear();
    
                    cookie.remove('agentToken');
                    window.location.href = `/login_expire`
                    return {}
                }
                else {
                    return {
                        ...state,
                        getTcktListData: {},
                        loading: false,
                    }
                }
            case GET_FILTERWISETICKETLIST_CLEAR:
                if (action?.payload?.Code === 400) {
                    localStorage.clear();
    
                    cookie.remove('agentToken');
                    window.location.href = `/login_expire`
                    return {}
                }
                else {
                    return {
                        ...state,
                        getTcktListData: {},
                        loading: false,
                    }
                }



        case GET_TICKETLISTDETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_TICKETLISTDETAILS_SUCCESS:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktDetailsListData: action.payload,
                    loading: false,
                }
            }
        case GET_TICKETLISTDETAILS_FAILED:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktDetailsListData: {},
                    loading: false,
                }
            }
        case GET_TICKETLISTDETAILS_CLEAR:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktDetailsListData: {},
                    loading: false,
                }
            }


        case GET_TICKETTRAILS_REQUEST:
            return {
                ...state,
                trailsLoading: true,
            }
        case GET_TICKETTRAILS_SUCCESS:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktTrailsData: action.payload,
                    trailsLoading: false,
                }
            }
        case GET_TICKETTRAILS_FAILED:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktTrailsData: {},
                    trailsLoading: false,
                }
            }
        case GET_TICKETTRAILS_CLEAR:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    getTcktTrailsData: {},
                    trailsLoading: false,
                }
            }



        case INSERT_TICKETRESOLVED_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case INSERT_TICKETRESOLVED_SUCCESS:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    insertTcktResolvedData: action.payload,
                    inserTicketResolvedStatus: true,
                    loading: false,
                }
            }
        case INSERT_TICKETRESOLVED_FAILED:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    insertTcktResolvedData: {},
                    loading: false,
                    inserTicketResolvedStatus: false
                }
            }
        case INSERT_TICKETRESOLVED_DONE:
            if (action?.payload?.Code === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = `/login_expire`
                return {}
            }
            else {
                return {
                    ...state,
                    insertTcktResolvedData: {},
                    loading: false,
                    inserTicketResolvedStatus: false
                }
            }

        default:
            return state
    }
}