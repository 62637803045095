import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import categoryReducer from './categoryRedux/reducer/category-reducer';
import categoryTypeReducer from './category_TypeRedux/reducer/categoryType-reducer';
import companyGroupReducer from './companyGroupRedux/reducer/companyGroup-reducer';
import companyReducer from './companyRedux/reducer/company-reducer';
import departmentReducer from './departmentReducer/reducer/department-reducer';
import loginReducer from './loginRedux/reducer/login-reducer';
import mailConfigureReducer from './mailConfigureRedux/reducer/mailConfigure-reducer';
import mailInboxReducer from './mailInboxRedux/reducer/mailInbox-reducer';
import mailTemplateReducer from './mailTemplateRedux/reducer/mailTemplate-reducer';
import priorityReducer from './priorityRedux/reducer/priority-reducer';
import productReducer from './productRedux/reducer/product-reducer';
import rootSaga from './saga';
import subCategoryReducer from './subCategoryRedux/reducer/subCategory-reducer';
import ticketSourceReducer from './ticketSource/reducer/ticketSource-reducer';
import usersReducer from './usersRedux/reducer/users-reducer';
import categoryGroupReducer from './categoryGroupRedux/reducer/categoryGroup-reducer';
import callConfigurationReducer from './callConfigurationRedux/reducer/callConfiguration-reducer';
import mailSentReducer from './mailSentRedux/reducer/mailSent-reducer';
import callOutboundRouteReducer from './callOutboundRouteRedux/reducer/callOutboundRoute-reducer';
import categoryGroupMappingReducer from './categoryGroupMappingRedux/reducer/categoryGroupMapping-reducer';
import categoryMappingReducer from './categoryMappingRedux/reducer/categoryMapping-reducer';
import subCategoryMappingReducer from './subCategoryMappingRedux/reducer/subCategoryMapping-reducer';
import ticketFieldReducer from './ticketFieldRedux/reducer/ticketField-reducer';
import ticketSourceDetailsReducer from './ticketSourceDetailsRedux/reducer/ticketSourceDetails-reducer';
import usersRollReducer from './usersRollRedux/reducer/usersRoll-reducer';
import usersTypeReducer from './usersTypeRedux/reducer/usersType-reducer';
import ticketFieldGroupMappingReducer from './ticketFieldGroupMapping/reducer/ticketFieldGroupMapping-reducer';
import deptwiseSourceReducer from './deptwiseTicketSourceMappingRedux/deptwiseSourceRedux/reducer/deptwiseSource-reducer';
import deptwiseSourceDetailsReducer from './deptwiseTicketSourceMappingRedux/deptwiseSourceDetailsRedux/reducer/deptwiseSourceDetails-reducer';
import deptwiseAgentReducer from './deptwiseTicketSourceMappingRedux/deptwiseAgentRedux/reducer/deptwiseAgent-reducer';
import agentMappingReducer from './agentMappingRedux/reducer/agentMapping-reducer';
import deptSourceMappingReducer from './deptSourceMapping/reducer/deptSourceMapping-reducer';
import pastTicketInteractionReducer from './pastTicketInteractionRedux/reducer/pastTicketInteraction-reducer';
import ticketReducer from './TicketRedux/reducer/ticket-reducer';
import callerInformationReducer from './callerInformationRedux/reducer/callerInformation-reducer';
import dispositionReducer from './dispositionRedux/reducer/disposition-reducer';
import ticketConvertionReducer from './ticketConvertionRedux/reducer/ticketConvertion-reducer';
import ticketStatusReducer from './ticketStatus/reducer/ticketStatus-reducer';
import adminTcktDashboardReducer from './adminTcktDashboard/reducer/adminTcktDashboard-reducer';

const sagaMiddleware = createSagaMiddleware();

const rootReducers: any = combineReducers({
  loginReducer: loginReducer,
  productReducer: productReducer,
  companyGroupReducer: companyGroupReducer,
  companyReducer: companyReducer,
  categoryReducer: categoryReducer,
  categoryMappingReducer: categoryMappingReducer,
  subCategoryReducer: subCategoryReducer,
  subCategoryMappingReducer: subCategoryMappingReducer,
  ticketFieldReducer: ticketFieldReducer,
  ticketFieldGroupMappingReducer: ticketFieldGroupMappingReducer,
  categoryTypeReducer: categoryTypeReducer,
  departmentReducer: departmentReducer,
  deptSourceMappingReducer: deptSourceMappingReducer,
  deptwiseSourceReducer: deptwiseSourceReducer,
  deptwiseSourceDetailsReducer: deptwiseSourceDetailsReducer,
  deptwiseAgentReducer: deptwiseAgentReducer,
  agentMappingReducer: agentMappingReducer,
  priorityReducer: priorityReducer,
  usersReducer: usersReducer,
  usersTypeReducer: usersTypeReducer,
  usersRollReducer: usersRollReducer,
  mailConfigureReducer: mailConfigureReducer,
  mailTemplateReducer: mailTemplateReducer,
  ticketSourceReducer: ticketSourceReducer,
  ticketSourceDetailsReducer: ticketSourceDetailsReducer,
  mailInboxReducer: mailInboxReducer,
  mailSentReducer: mailSentReducer,
  categoryGroupReducer: categoryGroupReducer,
  categoryGroupMappingReducer: categoryGroupMappingReducer,
  callConfigurationReducer: callConfigurationReducer,
  callOutboundRouteReducer: callOutboundRouteReducer,
  pastTicketInteractionReducer: pastTicketInteractionReducer,
  ticketReducer: ticketReducer,
  ticketConvertionReducer: ticketConvertionReducer,
  callerInformationReducer: callerInformationReducer,
  ticketStatusReducer: ticketStatusReducer,
  dispositionReducer: dispositionReducer,
  adminTcktDashboardReducer: adminTcktDashboardReducer
});
const composeEnhancer = process.env.NODE_ENV !== 'production' || compose;

export const store = createStore(rootReducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
