export const GET_CATEGORY_TYPE = 'GET_CATEGORY'
export const GET_CATEGORY_TYPE_REQUESTED = 'GET_CATEGORY_TYPE_REQUESTED'
export const GET_CATEGORY_TYPE_SUCCESS = 'GET_CATEGORY_TYPE_SUCCESS'
export const GET_CATEGORY_TYPE_FAILED = 'GET_CATEGORY_TYPE_FAILED'

export const ADD_CATEGORY_TYPE = 'ADD_CATEGORY_TYPE'
export const ADD_CATEGORY_TYPE_REQUESTED = 'ADD_CATEGORY_TYPE_REQUESTED'
export const ADD_CATEGORY_TYPE_SUCCESS = 'ADD_CATEGORY_TYPE_SUCCESS'
export const ADD_CATEGORY_TYPE_FAILED = 'ADD_CATEGORY_TYPE_FAILED'
export const ADD_CATEGORY_TYPE_DONE = 'ADD_CATEGORY_TYPE_DONE'

export function get_CategoryTypeData(){
    return{
        type: 'GET_CATEGORY_TYPE_REQUESTED'
    }
}
export function get_CategoryTypeDataSuccess(payload: any){
    return{
        type: 'GET_CATEGORY_TYPE_SUCCESS',
        payload: payload
    }
}
export function get_CategoryTypeDataFailed(payload: any){
    return{
        type: 'GET_CATEGORY_TYPE_FAILED',
        payload: ''
    }
}

export function add_CategoryTypeData(payload: any){
    return{
        type: 'ADD_CATEGORY_TYPE_REQUESTED',
        payload: payload
    }
}
export function add_CategoryTypeDataSuccess(payload: any){
    return{
        type: 'ADD_CATEGORY_TYPE_SUCCESS',
        payload: payload
    }
}
export function add_CategoryTypeDataFailed(payload: any){
    return{
        type: 'ADD_CATEGORY_TYPE_FAILED',
        payload: ''
    }
}
export function add_CategoryTypeDone() {
  return {
    type: 'ADD_CATEGORY_TYPE_DONE',
  };
}
