
export const GET_DEPTWISE_SOURCE = "GET_DEPTWISE_SOURCE"
export const GET_DEPTWISE_SOURCE_REQUESTED = "GET_DEPTWISE_SOURCE_REQUESTED"
export const GET_DEPTWISE_SOURCE_SUCCESS = "GET_DEPTWISE_SOURCE_SUCCESS"
export const GET_DEPTWISE_SOURCE_FAILED = "GET_DEPTWISE_SOURCE_FAILED"


export function get_deptwiseSourceData(payload:any) {
    return {
        type: 'GET_DEPTWISE_SOURCE_REQUESTED',
        payload: payload
    }
}

export function get_deptwiseSourceSuccess(payload:any) {
    return {
        type: 'GET_DEPTWISE_SOURCE_SUCCESS',
        payload: payload
    }
}
export function get_deptwiseSourceFailed(payload:any) {
    return {
        type: 'GET_DEPTWISE_SOURCE_FAILED',
        payload: ''
    }
}