import { ADD_DEPARTMENT, ADD_DEPARTMENT_DONE, ADD_DEPARTMENT_FAILED, ADD_DEPARTMENT_SUCCESS, GET_DEPARTMENT, GET_DEPARTMENTDROPDOWN_FAILED, GET_DEPARTMENTDROPDOWN_REQUESTED, GET_DEPARTMENTDROPDOWN_SUCCESS, GET_DEPARTMENT_FAILED, GET_DEPARTMENT_REQUESTED, GET_DEPARTMENT_SUCCESS } from "../action/department-action"
import cookie from 'react-cookies';

const initialState = {
    loading: false,
    data: [],
    dataDropdown: [],
    addData: {},
    addDepartmentStatus: false
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_DEPARTMENT_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case GET_DEPARTMENT_SUCCESS:            
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_DEPARTMENT_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: [],
                    loading: false
                }
            }

            case GET_DEPARTMENTDROPDOWN_REQUESTED:
                return {
                    ...state,
                    loading: true,
                }
            case GET_DEPARTMENTDROPDOWN_SUCCESS:            
                if (action?.payload?.StatusCode === 401) {
                    localStorage.clear();
                    cookie.remove('username');
                    cookie.remove('token');
                    window.location.href = '/login_expire'
                    return {}
                }
                else {
                    return {
                        ...state,
                        dataDropdown: action.payload,
                        loading: false
                    }
                }
            case GET_DEPARTMENTDROPDOWN_FAILED:
                if (action?.payload?.StatusCode === 401) {
                    localStorage.clear();
                    cookie.remove('username');
                    cookie.remove('token');
                    window.location.href = '/login_expire'
                    return {}
                }
                else {
                    return {
                        ...state,
                        dataDropdown: [],
                        loading: false
                    }
                }

        case ADD_DEPARTMENT:
            return {
                ...state,
                loading: true,
            }
        case ADD_DEPARTMENT_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addDepartmentStatus: true,
                }
            }
        case ADD_DEPARTMENT_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: {}
                }
            }
        case ADD_DEPARTMENT_DONE:
            return {
                ...state,
                addData: {},
                addDepartmentStatus: false
            }
        default:
            return state
    }
}