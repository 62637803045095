export const GET_DISPOSITION1 = 'GET_DISPOSITION1';
export const GET_DISPOSITION1_REQUESTED = 'GET_DISPOSITION1_REQUESTED';
export const GET_DISPOSITION1_SUCCESS = 'GET_DISPOSITION1_SUCCESS';
export const GET_DISPOSITION1_FAILED = 'GET_DISPOSITION1_FAILED';

export const GET_DISPOSITION2 = 'GET_DISPOSITION2';
export const GET_DISPOSITION2_REQUESTED = 'GET_DISPOSITION2_REQUESTED';
export const GET_DISPOSITION2_SUCCESS = 'GET_DISPOSITION2_SUCCESS';
export const GET_DISPOSITION2_FAILED = 'GET_DISPOSITION2_FAILED';


export const GET_DISPOSITION3 = 'GET_DISPOSITION3';
export const GET_DISPOSITION3_REQUESTED = 'GET_DISPOSITION3_REQUESTED';
export const GET_DISPOSITION3_SUCCESS = 'GET_DISPOSITION3_SUCCESS';
export const GET_DISPOSITION3_FAILED = 'GET_DISPOSITION3_FAILED';



export function get_Disposition1Data(payload: any) {
    return {
        type: 'GET_DISPOSITION1_REQUESTED',
        payload: payload,
    };
}
export function get_Disposition1DataSuccess(payload: any) {
    return {
        type: 'GET_DISPOSITION1_SUCCESS',
        payload: payload,
    };
}
export function get_Disposition1DataFailed(payload: any) {
    return {
        type: 'GET_DISPOSITION1_FAILED',
        payload: '',
    };
}


export function get_Disposition2Data(payload: any) {
    return {
        type: 'GET_DISPOSITION2_REQUESTED',
        payload: payload,
    };
}
export function get_Disposition2DataSuccess(payload: any) {
    return {
        type: 'GET_DISPOSITION2_SUCCESS',
        payload: payload,
    };
}
export function get_Disposition2DataFailed(payload: any) {
    return {
        type: 'GET_DISPOSITION2_FAILED',
        payload: '',
    };
}

export function get_Disposition3Data(payload: any) {
    return {
        type: 'GET_DISPOSITION3_REQUESTED',
        payload: payload,
    };
}
export function get_Disposition3DataSuccess(payload: any) {
    return {
        type: 'GET_DISPOSITION3_SUCCESS',
        payload: payload,
    };
}
export function get_Disposition3DataFailed(payload: any) {
    return {
        type: 'GET_DISPOSITION3_FAILED',
        payload: '',
    };
}