export const GET_CALL_OUTBOUND_ROUTE = 'GET_CALL_OUTBOUND_ROUTE'
export const GET_CALL_OUTBOUND_ROUTE_REQUESTED = 'GET_CALL_OUTBOUND_ROUTE_REQUESTED'
export const GET_CALL_OUTBOUND_ROUTE_SUCCESS = 'GET_CALL_OUTBOUND_ROUTE_SUCCESS'
export const GET_CALL_OUTBOUND_ROUTE_FAILED = 'GET_CALL_OUTBOUND_ROUTE_FAILED'

export const ADD_CALL_OUTBOUND_ROUTE_TYPE = 'ADD_CALL_OUTBOUND_ROUTE_TYPE'
export const ADD_CALL_OUTBOUND_ROUTE_REQUESTED = 'ADD_CALL_OUTBOUND_ROUTE_REQUESTED'
export const ADD_CALL_OUTBOUND_ROUTE_SUCCESS = 'ADD_CALL_OUTBOUND_ROUTE_SUCCESS'
export const ADD_CALL_OUTBOUND_ROUTE_FAILED = 'ADD_CALL_OUTBOUND_ROUTE_FAILED'


export function get_CallOutboundRouteData(){
    return{
        type: 'GET_CALL_OUTBOUND_ROUTE_REQUESTED'
    }
}
export function get_CallOutboundRouteDataSuccess(payload: any){
    return{
        type: 'GET_CALL_OUTBOUND_ROUTE_SUCCESS',
        payload: payload
    }
}
export function get_CallOutboundRouteDataFailed(payload: any){
    return{
        type: 'GET_CALL_OUTBOUND_ROUTE_FAILED',
        payload: ''
    }
}

export function add_CallOutboundRouteData(payload: any){
    return{
        type: 'ADD_CALL_OUTBOUND_ROUTE_REQUESTED',
        payload: payload
    }
}
export function add_CallOutboundRouteDataSuccess(payload: any){
    return{
        type: 'ADD_CALL_OUTBOUND_ROUTE_SUCCESS',
        payload: payload
    }
}
export function add_CallOutboundRouteDataFailed(payload: any){
    return{
        type: 'ADD_CALL_OUTBOUND_ROUTE_FAILED',
        payload: ''
    }
}