import cookie from 'react-cookies';
import { INSERT_TICKET_CONVERTION, INSERT_TICKET_CONVERTION_DONE, INSERT_TICKET_CONVERTION_FAILED, INSERT_TICKET_CONVERTION_REQUESTED, INSERT_TICKET_CONVERTION_SUCCESS } from '../action/ticketConvertion-action';
const initialState = {
    loading: false,
    insertData: {},
    insertTicketConvertionStatus: false
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case INSERT_TICKET_CONVERTION:
            return {
                ...state,
                loading: true,
            }
        case INSERT_TICKET_CONVERTION_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case INSERT_TICKET_CONVERTION_SUCCESS:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    insertData: action.payload,
                    loading: false,
                    insertTicketConvertionStatus: true,
                }
            }
        case INSERT_TICKET_CONVERTION_FAILED:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    insertData: {},
                    loading: false,
                }
            }
        case INSERT_TICKET_CONVERTION_DONE:
            return {
                ...state,
                insertData: {},
                loading: false,
                insertTicketConvertionStatus: false
            }
        default:
            return state
    }
}