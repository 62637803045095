import { ADD_CATEGORY, ADD_CATEGORY_DONE, ADD_CATEGORY_FAILED, ADD_CATEGORY_REQUESTED, ADD_CATEGORY_SUCCESS, GET_CATEGORY, GET_CATEGORY_FAILED, GET_CATEGORY_REQUESTED, GET_CATEGORY_SUCCESS } from "../action/category-action"
import cookie from 'react-cookies';

const initialState = {
    loading: false,
    data: [],
    addData: [],
    addCategoryStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CATEGORY_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case GET_CATEGORY_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }

        case GET_CATEGORY_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    date: [],
                    loading: false
                }
            }
        case ADD_CATEGORY_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case ADD_CATEGORY_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addCategoryStatus: true,
                    loading: false
                }
            }
        case ADD_CATEGORY_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: [],
                    addCategoryStatus: false,
                    loading: false
                }
            }

        case ADD_CATEGORY_DONE:
            return {
                ...state,
                addData: [],
                addCategoryStatus: false,
                loading: false
            };
        default:
            return state
    }
}