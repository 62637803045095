export const GET_USERS_TYPE = 'GET_USERS_TYPE'
export const GET_USERS_TYPE_REQUESTED = 'GET_USERS_TYPE_REQUESTED'
export const GET_USERS_TYPE_SUCCESS = 'GET_USERS_TYPE_SUCCESS'
export const GET_USERS_TYPE_FAILED = 'GET_USERS_TYPE_FAILED'


export function get_Users_TypeData(){
    return{
        type: 'GET_USERS_TYPE_REQUESTED'
    }
}
export function get_Users_TypeDataSuccess(payload: any){
    return{
        type: 'GET_USERS_TYPE_SUCCESS',
        payload: payload
    }
}
export function get_Users_TypeDataFailed(payload: any){
    return{
        type: 'GET_USERS_TYPE_FAILED',
        payload: ''
    }
} 