import { call, put, takeLatest } from 'redux-saga/effects';
import { get_FilterWiseTicketList_Failed, GET_FILTERWISETICKETLIST_REQUEST, get_FilterWiseTicketList_Success, get_TicketDashboard_Failed, GET_TICKETDASHBOARD_REQUEST, get_TicketDashboard_Success, get_TicketListDetails_Failed, GET_TICKETLISTDETAILS_REQUEST, get_TicketListDetails_Success, get_TicketList_Failed, GET_TICKETLIST_REQUEST, get_TicketList_Success, get_TicketTrails_Failed, GET_TICKETTRAILS_REQUEST, get_TicketTrails_Success, insert_TicketResolved_Failed, INSERT_TICKETRESOLVED_REQUEST, insert_TicketResolved_Success } from '../action/ticket-action';
import { get_FilterwiseTicketList_API, get_TicketDashboard_API, get_TicketListDetails_API, get_TicketList_API, get_TicketTrails_API, insert_TicketResolved_API } from '../api/ticket-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getTicketDashboard(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_TicketDashboard_API, action.payload);
    yield put(get_TicketDashboard_Success(getResponse));
  } catch (error) {
    yield put(get_TicketDashboard_Failed(error));
  }
}

function* getTicketList(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_TicketList_API, action.payload);
    yield put(get_TicketList_Success(getResponse));
  } catch (error) {
    yield put(get_TicketList_Failed(error));
  }
}

function* getFilterwiseTicketList(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_FilterwiseTicketList_API, action.payload);
    yield put(get_FilterWiseTicketList_Success(getResponse));
  } catch (error) {
    yield put(get_FilterWiseTicketList_Failed(error));
  }
}

function* getTicketListDetails(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_TicketListDetails_API, action.payload);
    yield put(get_TicketListDetails_Success(getResponse));
  } catch (error) {
    yield put(get_TicketListDetails_Failed(error));
  }
}

function* getTicketTrails(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_TicketTrails_API, action.payload);
    yield put(get_TicketTrails_Success(getResponse));
  } catch (error) {
    yield put(get_TicketTrails_Failed(error));
  }
}


function* insertTicketResolved(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(insert_TicketResolved_API, action.payload);
    yield put(insert_TicketResolved_Success(getResponse));
  } catch (error) {
    yield put(insert_TicketResolved_Failed(error));
  }
}

export function* getTicketSaga() {
  yield takeLatest(GET_TICKETDASHBOARD_REQUEST, getTicketDashboard)
  yield takeLatest(GET_TICKETLIST_REQUEST, getTicketList)
  yield takeLatest(GET_FILTERWISETICKETLIST_REQUEST, getFilterwiseTicketList)
  yield takeLatest(INSERT_TICKETRESOLVED_REQUEST, insertTicketResolved)
  yield takeLatest(GET_TICKETLISTDETAILS_REQUEST, getTicketListDetails)
  yield takeLatest(GET_TICKETTRAILS_REQUEST, getTicketTrails)
}