export const GET_USERS_ROLL = 'GET_USERS_ROLL'
export const GET_USERS_ROLL_REQUESTED = 'GET_USERS_ROLL_REQUESTED'
export const GET_USERS_ROLL_SUCCESS = 'GET_USERS_ROLL_SUCCESS'
export const GET_USERS_ROLL_FAILED = 'GET_USERS_ROLL_FAILED'
export const GET_USERS_ROLL_CLEAR = 'GET_USERS_ROLL_CLEAR'


export function get_Users_RollData(payload: any){
    return{
        type: 'GET_USERS_ROLL_REQUESTED',
        payload: payload
    }
}
export function get_Users_RollDataSuccess(payload: any){
    return{
        type: 'GET_USERS_ROLL_SUCCESS',
        payload: payload
    }
}
export function get_Users_RollDataFailed(payload: any){
    return{
        type: 'GET_USERS_ROLL_FAILED',
        payload: ''
    }
} 
export function get_Users_RollDataClear(){
    return{
        type: 'GET_USERS_ROLL_CLEAR'
    }
}