
export const GET_MAIL_TEMPLATE = "GET_MAIL_TEMPLATE"
export const GET_MAIL_TEMPLATE_REQUESTED = "GET_MAIL_TEMPLATE_REQUESTED"
export const GET_MAIL_TEMPLATE_SUCCESS = "GET_MAIL_TEMPLATE_SUCCESS"
export const GET_MAIL_TEMPLATE_FAILED = "GET_MAIL_TEMPLATE_FAILED"

export const ADD_MAIL_TEMPLATE = "ADD_MAIL_TEMPLATE"
export const ADD_MAIL_TEMPLATE_REQUESTED = "ADD_MAIL_TEMPLATE_REQUESTED"
export const ADD_MAIL_TEMPLATE_SUCCESS = "ADD_MAIL_TEMPLATE_SUCCESS"
export const ADD_MAIL_TEMPLATE_FAILED = "ADD_MAIL_TEMPLATE_FAILED"
export const ADD_MAIL_TEMPLATE_DONE = "ADD_MAIL_TEMPLATE_DONE"

export function get_MailTemplateData() {
    return {
        type: 'GET_MAIL_TEMPLATE_REQUESTED'
    }
}
export function get_MailTemplateDataSuccess(payload: any) {
    return {
        type: 'GET_MAIL_TEMPLATE_SUCCESS',
        payload: payload
    }
}
export function get_MailTemplateDataFailed(payload: any) {
    return {
        type: 'GET_MAIL_TEMPLATE_FAILED',
        payload: ''
    }
}

export function add_MailTemplateData(payload: any) {
    return {
        type: 'ADD_MAIL_TEMPLATE_REQUESTED',
        payload: payload
    }
}
export function add_MailTemplateDataSuccess(payload: any) {
    return {
        type: 'ADD_MAIL_TEMPLATE_SUCCESS',
        payload: payload
    }
}
export function add_MailTemplateDataFailed(payload: any) {
    return {
        type: 'ADD_MAIL_TEMPLATE_FAILED',
        payload: ''
    }
}
export function add_MailTemplateDone() {
    return {
        type: 'ADD_MAIL_TEMPLATE_DONE'
    }
}