import { call, put, takeLatest } from 'redux-saga/effects';
import {
  add_CompanyDataFailed,
  add_CompanyDataSuccess,
  ADD_COMPANY_REQUESTED,
  get_CompanyDataFailed,
  get_CompanyDataSuccess,
  GET_COMPANY_REQUESTED,
} from '../action/company-action';
import { add_company_API, get_company_API } from '../api/company-api';
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getCompany(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_company_API,
      action.payload
    );
    yield put(get_CompanyDataSuccess(getResponse));
  } catch (error) {
    yield put(get_CompanyDataFailed(error));
  }
}

function* addCompany(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_company_API,
      action.payload
    );
    yield put(add_CompanyDataSuccess(getResponse));
  } catch (error) {
    yield put(add_CompanyDataFailed(error));
  }
}

export function* companySaga() {
  yield takeLatest(GET_COMPANY_REQUESTED, getCompany);
  yield takeLatest(ADD_COMPANY_REQUESTED, addCompany);
}
