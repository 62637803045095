import { SERVICE_URL } from "../../Service";
import cookie from 'react-cookies';


export const get_TicketDashboard_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json',
                'authorization': 'Bearer ' +  cookie.load('token'),
            },

        }
        let getDetails = await fetch(SERVICE_URL.GET_TICKETDASHBOARD, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}


export const get_TicketList_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + cookie.load('token'),
            },
            body: JSON.stringify(payload)

        }
        let getDetails = await fetch(SERVICE_URL.GET_TICKETLIST, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}

export const get_FilterwiseTicketList_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + cookie.load('token'),
            }
        }
        let getDetails = await fetch(SERVICE_URL.GET_FILTERWISETICKETLIST + `?filtervalue=${payload}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}

export const get_TicketListDetails_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + cookie.load('token'),
            }

        }
        let getDetails = await fetch(SERVICE_URL.GET_TICKETLISTDETAILS + `?ticket_id=${payload}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}

export const get_TicketTrails_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + cookie.load('token'),
            }

        }
        let getDetails = await fetch(SERVICE_URL.GET_TICKETTRAILS + `?ticket_id=${payload}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}

export const insert_TicketResolved_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'POST',
            'headers': {
                // 'content-type': 'application/json',
                'authorization': 'Bearer ' + cookie.load('token'),
            },
            body: payload

        }
        let getDetails = await fetch(SERVICE_URL.INSERT_TICKETLISTDETAILS, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}