import { call, put, takeLatest } from 'redux-saga/effects';
import { add_deptwiseAgentFailed, add_deptwiseAgentSuccess, ADD_DEPTWISE_AGENT_REQUESTED, get_deptwiseAgentFailed, get_deptwiseAgentSuccess, GET_DEPTWISE_AGENT_REQUESTED } from '../action/deptwiseAgent-action';
import { add_deptwiseAgent_API, get_deptwiseAgent_API } from '../api/deptwiseAgent-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getDeptwise_Agent(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_deptwiseAgent_API, action.payload);
    yield put(get_deptwiseAgentSuccess(getResponse));
  } catch (error) {
    yield put(get_deptwiseAgentFailed(error));
  }
}

function* addDeptwise_Agent(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_deptwiseAgent_API, action.payload);
    yield put(add_deptwiseAgentSuccess(getResponse));
  } catch (error) {
    yield put(add_deptwiseAgentFailed(error));
  }
}

export function* deptwiseAgentSaga() {
  yield takeLatest(GET_DEPTWISE_AGENT_REQUESTED, getDeptwise_Agent)
  yield takeLatest(ADD_DEPTWISE_AGENT_REQUESTED, addDeptwise_Agent)
}