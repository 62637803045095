import { call, put, takeLatest } from 'redux-saga/effects';
import { add_TicketSourceDetailsFailed, add_TicketSourceDetailsSuccess, ADD_TICKET_SOURCE_DETAILS_REQUESTED, get_PrimaryTicketSourceDetailsFailed, get_PrimaryTicketSourceDetailsSuccess, GET_PRIMARY_TICKET_SOURCE_DETAILS_REQUESTED, get_SecondaryTicketSourceDetailsFailed, get_SecondaryTicketSourceDetailsSuccess, GET_SECONDARY_TICKET_SOURCE_DETAILS_REQUESTED } from '../action/ticketSourceDetails-action';
import { add_ticketSourceDetails_API, get_primaryTicketSourceDetails_API, get_secondaryTicketSourceDetails_API } from '../api/ticketSourceDetails-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getPrimaryTicketSourceDetails(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_primaryTicketSourceDetails_API, action.payload);
    yield put(get_PrimaryTicketSourceDetailsSuccess(getResponse));
  } catch (error) {
    yield put(get_PrimaryTicketSourceDetailsFailed(error));
  }
}

function* getSecondaryTicketSourceDetails(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_secondaryTicketSourceDetails_API, action.payload);
    yield put(get_SecondaryTicketSourceDetailsSuccess(getResponse));
  } catch (error) {
    yield put(get_SecondaryTicketSourceDetailsFailed(error));
  }
}

function* addTicketSourceDetails(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_ticketSourceDetails_API, action.payload);
    yield put(add_TicketSourceDetailsSuccess(getResponse));
  } catch (error) {
    yield put(add_TicketSourceDetailsFailed(error));
  }
}


export function* ticketSourceDetailsSaga() {
  yield takeLatest(GET_PRIMARY_TICKET_SOURCE_DETAILS_REQUESTED, getPrimaryTicketSourceDetails)
  yield takeLatest(GET_SECONDARY_TICKET_SOURCE_DETAILS_REQUESTED, getSecondaryTicketSourceDetails)
  yield takeLatest(ADD_TICKET_SOURCE_DETAILS_REQUESTED, addTicketSourceDetails)
}