import { call, put, takeLatest } from 'redux-saga/effects';
import { get_AdminTcktDashboardDataSuccess, get_AdminTcktDashboardDataFailed, get_AdminTcktDashboardDetailsDataSuccess, get_AdminTcktDashboardDetailsDataFailed, GET_ADMINTICKETDASHBOARD_REQUESTED, GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED } from '../action/adminTcktDashboard-action';
import { get_AdminTcktDashboard_API, get_AdminTcktDashboardDetails_API } from '../api/adminTcktDashboard-api';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: any;
    statusText?: any;
    token?: any;
}


function* get_AdminTcktDashboard(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            get_AdminTcktDashboard_API,
            action.payload
        );
        yield put(get_AdminTcktDashboardDataSuccess(getResponse));
    } catch (error) {
        yield put(get_AdminTcktDashboardDataFailed(error));
    }
}

function* get_AdminTcktDashboardDetails(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            get_AdminTcktDashboardDetails_API,
            action.payload
        );
        yield put(get_AdminTcktDashboardDetailsDataSuccess(getResponse));
    } catch (error) {
        yield put(get_AdminTcktDashboardDetailsDataFailed(error));
    }
}
export function* get_adminTcktDashboardSaga() {
    yield takeLatest(GET_ADMINTICKETDASHBOARD_REQUESTED, get_AdminTcktDashboard);
    yield takeLatest(GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED, get_AdminTcktDashboardDetails);
}
