import { call, put, takeLatest } from 'redux-saga/effects';
import { get_Users_RollDataFailed, get_Users_RollDataSuccess, GET_USERS_ROLL_REQUESTED } from '../action/usersRoll-action';
import { get_users_Roll_API } from '../api/usersRoll-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

  

function* getUsers_Roll(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_users_Roll_API, action.payload);
    yield put(get_Users_RollDataSuccess(getResponse));
  } catch (error) {
    yield put(get_Users_RollDataFailed(error));
  }
}


export function* usersRollSaga() {
  yield takeLatest(GET_USERS_ROLL_REQUESTED, getUsers_Roll)
}