export const GET_CALL_CONFIGURATION = 'GET_CALL_CONFIGURATION'
export const GET_CALL_CONFIGURATION_REQUESTED = 'GET_CALL_CONFIGURATION_REQUESTED'
export const GET_CALL_CONFIGURATION_SUCCESS = 'GET_CALL_CONFIGURATION_SUCCESS'
export const GET_CALL_CONFIGURATION_FAILED = 'GET_CALL_CONFIGURATION_FAILED'

export const ADD_CALL_CONFIGURATION_TYPE = 'ADD_CALL_CONFIGURATION_TYPE'
export const ADD_CALL_CONFIGURATION_REQUESTED = 'ADD_CALL_CONFIGURATION_REQUESTED'
export const ADD_CALL_CONFIGURATION_SUCCESS = 'ADD_CALL_CONFIGURATION_SUCCESS'
export const ADD_CALL_CONFIGURATION_FAILED = 'ADD_CALL_CONFIGURATION_FAILED'
export const ADD_CALL_CONFIGURATION_DONE = 'ADD_CALL_CONFIGURATION_DONE'

export function get_CallConfigurationData(){
    return{
        type: 'GET_CALL_CONFIGURATION_REQUESTED'
    }
}
export function get_CallConfigurationDataSuccess(payload: any){
    return{
        type: 'GET_CALL_CONFIGURATION_SUCCESS',
        payload: payload
    }
}
export function get_CallConfigurationDataFailed(payload: any){
    return{
        type: 'GET_CALL_CONFIGURATION_FAILED',
        payload: ''
    }
}

export function add_CallConfigurationData(payload: any){
    return{
        type: 'ADD_CALL_CONFIGURATION_REQUESTED',
        payload: payload
    }
}
export function add_CallConfigurationDataSuccess(payload: any){
    return{
        type: 'ADD_CALL_CONFIGURATION_SUCCESS',
        payload: payload
    }
}
export function add_CallConfigurationDataFailed(payload: any){
    return{
        type: 'ADD_CALL_CONFIGURATION_FAILED',
        payload: ''
    }
}
export function add_CallConfigurationDone() {
    return {
        type: 'ADD_CALL_CONFIGURATION_DONE'
    }
}