import { ADD_SUBCATEGORY_DONE, ADD_SUB_CATEGORY, ADD_SUB_CATEGORY_FAILED, ADD_SUB_CATEGORY_REQUESTED, ADD_SUB_CATEGORY_SUCCESS, GET_SUB_CATEGORY, GET_SUB_CATEGORY_FAILED, GET_SUB_CATEGORY_REQUESTED, GET_SUB_CATEGORY_SUCCESS } from "../action/subCategory-action"
import cookie from 'react-cookies';


const initialState = {
    loading: false,
    getData: [],
    addData: {},
    addSubCategoryStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SUB_CATEGORY_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case GET_SUB_CATEGORY_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_SUB_CATEGORY_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }
        case ADD_SUB_CATEGORY_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case ADD_SUB_CATEGORY_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addSubCategoryStatus: true,
                    addData: action.payload,
                    loading: false
                }
            }
        case ADD_SUB_CATEGORY_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: {},
                    loading: false
                }
            }
        case ADD_SUBCATEGORY_DONE:
            return {
                ...state,
                addSubCategoryStatus: false,
                loading: false
            };
        default:
            return state
    }
}