import cookie from 'react-cookies';
import { GET_CALLERINFORMATION, GET_CALLERINFORMATION_CLEAR, GET_CALLERINFORMATION_FAILED, GET_CALLERINFORMATION_REQUESTED, GET_CALLERINFORMATION_SUCCESS, INSERT_CALLERINFORMATION, INSERT_CALLERINFORMATION_DONE, INSERT_CALLERINFORMATION_FAILED, INSERT_CALLERINFORMATION_REQUESTED, INSERT_CALLERINFORMATION_SUCCESS } from '../action/callerInformation-action';
import {  SUBDOMAIN } from "../../Service"
const initialState = {
  loading: false,
  getData: [],
  insertData: {},
  getCallerInformationStatus: false,
  insertCallerInformationStatus: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CALLERINFORMATION:
      return {
        ...state,
        loading: true,
      };
    case GET_CALLERINFORMATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CALLERINFORMATION_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData: action.payload,
          getCallerInformationStatus: true,
          loading: false,
        };
      }
    case GET_CALLERINFORMATION_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData: [],
          getCallerInformationStatus: false,
          loading: false,
        };
      }
    case GET_CALLERINFORMATION_CLEAR:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          getData: [],
          getCallerInformationStatus: false,
          loading: false,
        };
      }

    case INSERT_CALLERINFORMATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case INSERT_CALLERINFORMATION_SUCCESS:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          insertData: action.payload,
          insertCallerInformationStatus: true,
          loading: false,
        };
      }
    case INSERT_CALLERINFORMATION_FAILED:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          insertData: {},
          insertCallerInformationStatus: false,
          loading: false,
        };
      }
    case INSERT_CALLERINFORMATION_DONE:
      if (action?.payload?.StatusCode === 401) {
        localStorage.clear();

        cookie.remove('agentToken');
        window.location.href = `${SUBDOMAIN}/login_expire`;
        return {};
      } else {
        return {
          ...state,
          insertData: {},
          insertCallerInformationStatus: false,
          loading: false,
        };
      }
    default:
      return state;
  }
};
