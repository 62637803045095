import { call, put, takeLatest } from 'redux-saga/effects';
import { get_CallerInformationDataSuccess, get_CallerInformationDataFailed, GET_CALLERINFORMATION_REQUESTED, insert_CallerInformationDataFailed, insert_CallerInformationDataSuccess, INSERT_CALLERINFORMATION_REQUESTED } from '../action/callerInformation-action';
import { get_CallerInformation_API, insert_CallerInformation_API } from '../api/callerInformation-api';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: any;
    statusText?: any;
    token?: any;
}


function* get_CallerInformation(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            get_CallerInformation_API,
            action.payload
        );
        yield put(get_CallerInformationDataSuccess(getResponse));
    } catch (error) {
        yield put(get_CallerInformationDataFailed(error));
    }
}
function* insert_CallerInformation(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            insert_CallerInformation_API,
            action.payload
        );
        yield put(insert_CallerInformationDataSuccess(getResponse));
    } catch (error) {
        yield put(insert_CallerInformationDataFailed(error));
    }
}
export function* get_CallerInformationSaga() {
    yield takeLatest(GET_CALLERINFORMATION_REQUESTED, get_CallerInformation);
    yield takeLatest(INSERT_CALLERINFORMATION_REQUESTED, insert_CallerInformation);
}
