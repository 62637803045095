export const GET_TICKETDASHBOARD = 'GET_TICKETDASHBOARD';
export const GET_TICKETDASHBOARD_REQUEST = 'GET_TICKETDASHBOARD_REQUEST';
export const GET_TICKETDASHBOARD_SUCCESS = 'GET_TICKETDASHBOARD_SUCCESS';
export const GET_TICKETDASHBOARD_FAILED = 'GET_TICKETDASHBOARD_FAILED';

export const GET_TICKETLIST = 'GET_TICKETLIST';
export const GET_TICKETLIST_REQUEST = 'GET_TICKETLIST_REQUEST';
export const GET_TICKETLIST_SUCCESS = 'GET_TICKETLIST_SUCCESS';
export const GET_TICKETLIST_FAILED = 'GET_TICKETLIST_FAILED';
export const GET_TICKETLIST_CLEAR = 'GET_TICKETLIST_CLEAR';

export const GET_FILTERWISETICKETLIST = 'GET_FILTERWISETICKETLIST';
export const GET_FILTERWISETICKETLIST_REQUEST = 'GET_FILTERWISETICKETLIST_REQUEST';
export const GET_FILTERWISETICKETLIST_SUCCESS = 'GET_FILTERWISETICKETLIST_SUCCESS';
export const GET_FILTERWISETICKETLIST_FAILED = 'GET_FILTERWISETICKETLIST_FAILED';
export const GET_FILTERWISETICKETLIST_CLEAR = 'GET_FILTERWISETICKETLIST_CLEAR';

export const GET_TICKETLISTDETAILS = 'GET_TICKETLISTDETAILS';
export const GET_TICKETLISTDETAILS_REQUEST = 'GET_TICKETLISTDETAILS_REQUEST';
export const GET_TICKETLISTDETAILS_SUCCESS = 'GET_TICKETLISTDETAILS_SUCCESS';
export const GET_TICKETLISTDETAILS_FAILED = 'GET_TICKETLISTDETAILS_FAILED';
export const GET_TICKETLISTDETAILS_CLEAR = 'GET_TICKETLISTDETAILS_CLEAR';

export const GET_TICKETTRAILS = 'GET_TICKETTRAILS';
export const GET_TICKETTRAILS_REQUEST = 'GET_TICKETTRAILS_REQUEST';
export const GET_TICKETTRAILS_SUCCESS = 'GET_TICKETTRAILS_SUCCESS';
export const GET_TICKETTRAILS_FAILED = 'GET_TICKETTRAILS_FAILED';
export const GET_TICKETTRAILS_CLEAR = 'GET_TICKETTRAILS_CLEAR';

export const INSERT_TICKETRESOLVED = 'INSERT_TICKETRESOLVED';
export const INSERT_TICKETRESOLVED_REQUEST = 'INSERT_TICKETRESOLVED_REQUEST';
export const INSERT_TICKETRESOLVED_SUCCESS = 'INSERT_TICKETRESOLVED_SUCCESS';
export const INSERT_TICKETRESOLVED_FAILED = 'INSERT_TICKETRESOLVED_FAILED';
export const INSERT_TICKETRESOLVED_DONE = 'INSERT_TICKETRESOLVED_DONE';

export function get_TicketDashboard() {
    return {
        type: 'GET_TICKETDASHBOARD_REQUEST'
    }
}
export function get_TicketDashboard_Success(payload: any) {
    return {
        type: 'GET_TICKETDASHBOARD_SUCCESS',
        payload: payload
    }
}
export function get_TicketDashboard_Failed(payload: any) {
    return {
        type: 'GET_TICKETDASHBOARD_FAILED',
        payload: payload
    }
}


export function get_TicketList(payload: any) {
    return {
        type: 'GET_TICKETLIST_REQUEST',
        payload: payload
    }
}
export function get_TicketList_Success(payload: any) {
    return {
        type: 'GET_TICKETLIST_SUCCESS',
        payload: payload
    }
}
export function get_TicketList_Failed(payload: any) {
    return {
        type: 'GET_TICKETLIST_FAILED',
        payload: payload
    }
}
export function get_TicketList_Clear() {
    return {
        type: 'GET_TICKETLIST_CLEAR'
    }
}



export function get_FilterWiseTicketList(payload: any) {
    return {
        type: 'GET_FILTERWISETICKETLIST_REQUEST',
        payload: payload
    }
}
export function get_FilterWiseTicketList_Success(payload: any) {
    return {
        type: 'GET_FILTERWISETICKETLIST_SUCCESS',
        payload: payload
    }
}
export function get_FilterWiseTicketList_Failed(payload: any) {
    return {
        type: 'GET_FILTERWISETICKETLIST_FAILED',
        payload: payload
    }
}
export function get_FilterWiseTicketList_Clear() {
    return {
        type: 'GET_FILTERWISETICKETLIST_CLEAR'
    }
}


export function get_TicketListDetails(payload: any) {
    return {
        type: 'GET_TICKETLISTDETAILS_REQUEST',
        payload: payload
    }
}
export function get_TicketListDetails_Success(payload: any) {
    return {
        type: 'GET_TICKETLISTDETAILS_SUCCESS',
        payload: payload
    }
}
export function get_TicketListDetails_Failed(payload: any) {
    return {
        type: 'GET_TICKETLISTDETAILS_FAILED',
        payload: payload
    }
}
export function get_TicketListDetails_Clear() {
    return {
        type: 'GET_TICKETLISTDETAILS_CLEAR',
    }
}

export function get_TicketTrails(payload: any) {
    return {
        type: 'GET_TICKETTRAILS_REQUEST',
        payload: payload
    }
}
export function get_TicketTrails_Success(payload: any) {
    return {
        type: 'GET_TICKETTRAILS_SUCCESS',
        payload: payload
    }
}
export function get_TicketTrails_Failed(payload: any) {
    return {
        type: 'GET_TICKETTRAILS_FAILED',
        payload: payload
    }
}
export function get_TicketTrails_Clear() {
    return {
        type: 'GET_TICKETTRAILS_CLEAR',
    }
}

export function insert_TicketResolved(payload: any) {
    return {
        type: 'INSERT_TICKETRESOLVED_REQUEST',
        payload: payload
    }
}
export function insert_TicketResolved_Success(payload: any) {
    return {
        type: 'INSERT_TICKETRESOLVED_SUCCESS',
        payload: payload
    }
}
export function insert_TicketResolved_Failed(payload: any) {
    return {
        type: 'INSERT_TICKETRESOLVED_FAILED',
        payload: payload
    }
}
export function insert_TicketResolved_Done() {
    return {
        type: 'INSERT_TICKETRESOLVED_DONE',
    }
}