import { call, put, takeLatest } from 'redux-saga/effects';
import { get_Users_TypeDataSuccess, get_Users_TypeDataFailed, GET_USERS_TYPE_REQUESTED } from '../action/usersType-action';
import { get_users_Type_API } from '../api/usersType-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

  

function* getUsers_Type(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_users_Type_API, action.payload);
    yield put(get_Users_TypeDataSuccess(getResponse));
  } catch (error) {
    yield put(get_Users_TypeDataFailed(error));
  }
}


export function* usersTypeSaga() {
  yield takeLatest(GET_USERS_TYPE_REQUESTED, getUsers_Type)
}