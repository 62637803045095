export const INSERT_TICKET_CONVERTION = "INSERT_MTICKET_CONVERTION"
export const INSERT_TICKET_CONVERTION_REQUESTED = "INSERT_TICKET_CONVERTION_REQUESTED"
export const INSERT_TICKET_CONVERTION_SUCCESS = "INSERT_TICKET_CONVERTION_SUCCESS"
export const INSERT_TICKET_CONVERTION_FAILED = "INSERT_TICKET_CONVERTION_FAILED"
export const INSERT_TICKET_CONVERTION_DONE = "INSERT_TICKET_CONVERTION_DONE"


export function insert_TicketConvertionData(payload: any){
    return {
        type: 'INSERT_TICKET_CONVERTION_REQUESTED',
        payload: payload
    }
}
export function insert_TicketConvertionDataSuccess(payload: any){     
    return {
        type: 'INSERT_TICKET_CONVERTION_SUCCESS',
        payload: payload
    }
}
export function insert_TicketConvertionDataFailed(payload: any){
    return {
        type: 'INSERT_TICKET_CONVERTION_FAILED',
        payload: ''
    }
}
export function insert_TicketConvertionDone() {
    return {
        type: 'INSERT_TICKET_CONVERTION_DONE'
    }
}