import { SERVICE_URL } from "../../Service";

import cookie from 'react-cookies';

export const insert_TicketConvertion_API = async(payload:any) => {
    try {
        let headers = { 
            'method': 'POST',          
            'headers': {               
                // 'content-type': 'application/json',
                'authorization': 'Bearer '  + cookie.load('token'),
            },
            'body':payload
           
        }
        let getDetails = await fetch(SERVICE_URL.INSERT_TICKETCONVERTION, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch(e) {
        console.log(e);
    }
}