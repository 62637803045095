import { call, put, takeLatest } from 'redux-saga/effects';
import { add_categoryMappingDataFailed, add_categoryMappingDataSuccess, ADD_CATEGORYMAPPING_REQUESTED, check_categoryMappingDataFailed, check_categoryMappingDataSuccess, CHECK_CATEGORYMAPPING_REQUESTED, get_categoryMappingDataFailed, get_categoryMappingDataSuccess, GET_CATEGORYMAPPING_REQUESTED } from '../action/categoryMapping-action';
import { add_categoryMapping_API, check_categoryMapping_API, get_categoryMapping_API } from '../api/categoryMapping-api';
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getCategoryMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_categoryMapping_API,
      action.payload
    );
    yield put(get_categoryMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(get_categoryMappingDataFailed(error));
  }
}

function* addCategoryMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_categoryMapping_API,
      action.payload
    );
    yield put(add_categoryMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(add_categoryMappingDataFailed(error));
  }
}

function* checkCategoryMapping(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      check_categoryMapping_API,
      action.payload
    );
    yield put(check_categoryMappingDataSuccess(getResponse));
  } catch (error) {
    yield put(check_categoryMappingDataFailed(error));
  }
}
export function* categoryMappingSaga() {
  yield takeLatest(GET_CATEGORYMAPPING_REQUESTED, getCategoryMapping);
  yield takeLatest(ADD_CATEGORYMAPPING_REQUESTED, addCategoryMapping);
  yield takeLatest(CHECK_CATEGORYMAPPING_REQUESTED, checkCategoryMapping);
}
