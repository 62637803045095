import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_primaryTicketSourceDetails_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      // body:JSON.stringify(data)
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_TICKET_SOURCE_DETAILS +
        `?company_id=${payload.company_id}&Source_id=${payload.Source_id}&flag=${payload.flag}`,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const get_secondaryTicketSourceDetails_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      // body:JSON.stringify(data)
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_TICKET_SOURCE_DETAILS +`?company_id=${payload.company_id}&source_type_id=${payload.source_type_id}&Source_id=${payload.Source_id}&flag=${payload.flag}`,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const add_ticketSourceDetails_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(
      SERVICE_URL.ADD_TICKET_SOURCE_DETAILS,
      headers
    );
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
