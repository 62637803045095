import { call, put, takeLatest } from 'redux-saga/effects';
import { add_SubCategoryDataFailed, add_SubCategoryDataSuccess, ADD_SUB_CATEGORY_REQUESTED, get_SubCategoryDataFailed, get_SubCategoryDataSuccess, GET_SUB_CATEGORY_REQUESTED } from '../../subCategoryRedux/action/subCategory-action';
import { add_subCategory_API, get_subCategory_API } from '../../subCategoryRedux/api/subCategory-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getSubCategoryGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_subCategory_API, action.payload);
    yield put(get_SubCategoryDataSuccess(getResponse));
  } catch (error) {
    yield put(get_SubCategoryDataFailed(error));
  }
}

function* addSubCategoryGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_subCategory_API, action.payload);
    yield put(add_SubCategoryDataSuccess(getResponse));
  } catch (error) {
    yield put(add_SubCategoryDataFailed(error));
  }
}


export function* subCategorySaga() {
  yield takeLatest(GET_SUB_CATEGORY_REQUESTED, getSubCategoryGroup)
  yield takeLatest(ADD_SUB_CATEGORY_REQUESTED, addSubCategoryGroup)
}