import { SERVICE_URL } from "../../Service";
import cookie from 'react-cookies';


export const get_TicketStatus_API = async (payload: any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json',
                'authorization': 'Bearer ' +  cookie.load('token'),
            },

        }
        let getDetails = await fetch(SERVICE_URL.GET_TICKETSTATUS+ `?status_id=${payload}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch (e) {
        console.log(e);
    }
}