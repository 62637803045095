import { SERVICE_URL } from "../../Service";
import cookie from 'react-cookies';



export const get_callConfiguration_API = async(payload:any) => {
    try {
        let headers = {
            'method': 'GET',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + cookie.load("token")
            },
            body:JSON.stringify(payload)
        }
        let getDetails = await fetch(SERVICE_URL.GET_CALL_CONFIGURE, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch(e) {
        console.log(e);
    }
}

export const add_callConfiguration_API = async(payload:any) => {
    try {
        let headers = {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + cookie.load("token")
            },
            body:JSON.stringify(payload)
        }
        let getDetails = await fetch(SERVICE_URL.ADD_CALL_CONFIGURE, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    }
    catch(e) {
        console.log(e);
    }
}