export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_DEPARTMENT_REQUESTED = 'GET_DEPARTMENT_REQUESTED';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAILED = 'GET_DEPARTMENT_FAILED';

export const GET_DEPARTMENTDROPDOWN = 'GET_DEPARTMENTDROPDOWN';
export const GET_DEPARTMENTDROPDOWN_REQUESTED = 'GET_DEPARTMENTDROPDOWN_REQUESTED';
export const GET_DEPARTMENTDROPDOWN_SUCCESS = 'GET_DEPARTMENTDROPDOWN_SUCCESS';
export const GET_DEPARTMENTDROPDOWN_FAILED = 'GET_DEPARTMENTDROPDOWN_FAILED';

export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const ADD_DEPARTMENT_REQUESTED = 'ADD_DEPARTMENT_REQUESTED';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAILED = 'ADD_DEPARTMENT_FAILED';
export const ADD_DEPARTMENT_DONE = 'ADD_DEPARTMENT_DONE';

export function get_DepartmentData(payload: any) {
  return {
    type: 'GET_DEPARTMENT_REQUESTED',
    payload: payload,
  };
}
export function get_DepartmentDataSuccess(payload: any) {
  return {
    type: 'GET_DEPARTMENT_SUCCESS',
    payload: payload,
  };
}
export function get_DepartmentDataFailed(payload: any) {
  return {
    type: 'GET_DEPARTMENT_FAILED',
    payload: '',
  };
}

export function get_DepartmentDropDownData(payload: any) {
  return {
    type: 'GET_DEPARTMENTDROPDOWN_REQUESTED',
    payload: payload,
  };
}
export function get_DepartmentDropDownDataSuccess(payload: any) {
  return {
    type: 'GET_DEPARTMENTDROPDOWN_SUCCESS',
    payload: payload,
  };
}
export function get_DepartmentDropDownDataFailed(payload: any) {
  return {
    type: 'GET_DEPARTMENTDROPDOWN_FAILED',
    payload: '',
  };
}

export function add_DepartmentData(payload: any) {
  return {
    type: 'ADD_DEPARTMENT_REQUESTED',
    payload: payload,
  };
}
export function add_DepartmentDataSuccess(payload: any) {
  return {
    type: 'ADD_DEPARTMENT_SUCCESS',
    payload: payload,
  };
}
export function add_DepartmentDataFailed(payload: any) {
  return {
    type: 'ADD_DEPARTMENT_FAILED',
    payload: '',
  };
}
export function add_DepartmentDone() {
  return {
    type: 'ADD_DEPARTMENT_DONE',
  };
}
