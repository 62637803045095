import { call, put, takeLatest } from 'redux-saga/effects';
import { add_CallOutboundRouteDataFailed, add_CallOutboundRouteDataSuccess, ADD_CALL_OUTBOUND_ROUTE_REQUESTED, get_CallOutboundRouteDataFailed, get_CallOutboundRouteDataSuccess, GET_CALL_OUTBOUND_ROUTE_REQUESTED } from '../action/callOutboundRoute-action';
import { add_callOutboundRoute_API, get_callOutboundRoute_API } from '../api/callOutboundRoute-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getCallOutboundRoute(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_callOutboundRoute_API, action.payload);
     console.log(getResponse,'GetResp')
   yield put(get_CallOutboundRouteDataSuccess(getResponse));
   } catch (error) {
    yield put(get_CallOutboundRouteDataFailed(error));
   }
}

function* addCallOutboundRoute(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(add_callOutboundRoute_API, action.payload);
   yield put(add_CallOutboundRouteDataSuccess(getResponse));
   } catch (error) {
    yield put(add_CallOutboundRouteDataFailed(error));
   }
}

export function* callOutboundRouteSaga() {
  yield takeLatest(GET_CALL_OUTBOUND_ROUTE_REQUESTED, getCallOutboundRoute)
  yield takeLatest(ADD_CALL_OUTBOUND_ROUTE_REQUESTED, addCallOutboundRoute)
}