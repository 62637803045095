import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_department_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      //  body:JSON.stringify(payload)
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_DEPARTMENT +
        '?CompanyId=' +
        (payload.CompanyId || 0) +
        '&DepartId=' +
        (payload.DepartId || 0),
      headers
    );
    let getResponse = await getDetails.json();
    console.log(getResponse, 'getResponse');
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const get_departmentDropDown_API = async (payload: any) => {
  try {
    let headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      //  body:JSON.stringify(payload)
    };
    let getDetails = await fetch(
      SERVICE_URL.GET_DEPARTMENTDROPDOWN +
        '?CompanyId=' +
        (payload.CompanyId || 0) +
        '&DepartId=' +
        (payload.DepartId || 0),
      headers
    );
    let getResponse = await getDetails.json();
    console.log(getResponse, 'getResponse');
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};

export const add_department_API = async (payload: any) => {
  try {
    let headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + cookie.load('token'),
      },
      body: JSON.stringify(payload),
    };
    let getDetails = await fetch(SERVICE_URL.ADD_DEPARTMENT, headers);
    let getResponse = await getDetails.json();
    return getResponse;
  } catch (e) {
    console.log(e);
  }
};
