import cookie from 'react-cookies';
import { GET_DEPTWISE_AGENT, GET_DEPTWISE_AGENT_SUCCESS, GET_DEPTWISE_AGENT_FAILED, ADD_DEPTWISE_AGENT, ADD_DEPTWISE_AGENT_FAILED, ADD_DEPTWISE_AGENT_SUCCESS, ADD_DEPTWISE_AGENT_DONE } from '../action/deptwiseAgent-action';

const initialState = {
    loading: false,
    getData: [],
    addData: [],
    addDataStatus: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_DEPTWISE_AGENT:
            return {
                ...state,
                loading: true
            }
        case GET_DEPTWISE_AGENT_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false
                }
            }
        case GET_DEPTWISE_AGENT_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: [],
                    loading: false
                }
            }

        case ADD_DEPTWISE_AGENT:
            return {
                ...state,
                loading: true
            }
        case ADD_DEPTWISE_AGENT_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addDataStatus: true,
                    loading: false
                }
            }
        case ADD_DEPTWISE_AGENT_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: [],
                    loading: false
                }
            }
            case ADD_DEPTWISE_AGENT_DONE:
                if (action?.payload?.StatusCode === 401) {
                    localStorage.clear();
                    cookie.remove('username');
                    cookie.remove('token');
                    window.location.href = '/login_expire'
                    return {}
                }
                else {
                    return {
                        ...state,
                        addData: [],
                        addDataStatus: false,
                        loading: false
                    }
                }
        default:
            return state
    }
}