import { call, put, takeLatest } from 'redux-saga/effects';
import { add_CallConfigurationDataFailed, add_CallConfigurationDataSuccess, ADD_CALL_CONFIGURATION_REQUESTED, get_CallConfigurationDataFailed, get_CallConfigurationDataSuccess, GET_CALL_CONFIGURATION_REQUESTED } from '../action/callConfiguration-action';
import { add_callConfiguration_API, get_callConfiguration_API } from '../api/callConfiguration-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getCallConfiguration(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_callConfiguration_API, action.payload);
     console.log(getResponse,'GetResp')
   yield put(get_CallConfigurationDataSuccess(getResponse));
   } catch (error) {
    yield put(get_CallConfigurationDataFailed(error));
   }
}

function* addCallConfiguration(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(add_callConfiguration_API, action.payload);
   yield put(add_CallConfigurationDataSuccess(getResponse));
   } catch (error) {
    yield put(add_CallConfigurationDataFailed(error));
   }
}

export function* callConfigurationSaga() {
  yield takeLatest(GET_CALL_CONFIGURATION_REQUESTED, getCallConfiguration)
  yield takeLatest(ADD_CALL_CONFIGURATION_REQUESTED, addCallConfiguration)
}