// const MAIN_URL = 'https://c2c.armsoft-tech.com/tickettingapi/api';
const MAIN_URL = 'https://c2c.armsoft-tech.com/ticketingtoolapi/api';
//const MAIN_URL = 'https://c2c.armsoft-tech.com/ticketing/api';

// export const MAIN_URL1 = 'https://c2c.armsoft-tech.com/tickettingapi/api';
export const MAIN_URL1 = 'https://c2c.armsoft-tech.com/ticketingtoolapi/api';
//export const MAIN_URL1 = 'https://c2c.armsoft-tech.com/ticketing/api';

export const SUBDOMAIN = '';

export const SERVICE_URL = {
  LOGIN: `${MAIN_URL}/V1/Admin/Account/CheckUserLogin`,

  GET_PRODUCT: `${MAIN_URL}/V1/Admin/Account/GetProduct`,
  ADD_PRODUCT: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateProductMaster`,

  GET_PRODUCT_FIELD: `${MAIN_URL}/V1/Agent/User/GetCustomerFieldHeader`,
  ADD_PRODUCT_FIELD: `${MAIN_URL}/V1/Agent/User/InsertorUpdateCustomerMapping`,

  GET_COMPANYGROUP: `${MAIN_URL}/V1/Admin/Account/GetCompanyGroup`,
  ADD_COMPANYGROUP: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCompanyGroup`,

  GET_COMPANY: `${MAIN_URL}/V1/Admin/Account/GetCompany`,
  ADD_COMPANY: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCompany`,

  GET_CATEGORY: `${MAIN_URL}/V1/Admin/Account/GetCategory`,
  ADD_CATEGORY: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCategory`,

  GET_CATEGORYMAPPING: `${MAIN_URL}/V1/Admin/Account/GetCategoryMapping`,
  ADD_CATEGORYMAPPING: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCategoryMapping`,
  CHECK_CATEGORYMAPPING: `${MAIN_URL}/V1/Admin/Account/CheckCategoryMapping`,

  GET_CATEGORYGROUP: `${MAIN_URL}/V1/Admin/Account/GetCategoryGroup`,
  ADD_CATEGORYGROUP: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCategoryGroup`,

  GET_CATEGORYGROUPMAPPING: `${MAIN_URL}/V1/Admin/Account/GetCategoryGroupMapping`,
  ADD_CATEGORYGROUPMAPPING: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCategoryGroupMapping`,
  CHECK_CATEGORYGROUPMAPPING: `${MAIN_URL}/V1/Admin/Account/CheckCategoryGroupMapping`,

  GET_SUB_CATEGORY: `${MAIN_URL}/V1/Admin/Account/GetSubCategory`,
  ADD_SUB_CATEGORY: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateSubCategory`,

  GET_SUB_CATEGORYMAPPING: `${MAIN_URL}/V1/Admin/Account/GetSubCategoryMapping`,
  ADD_SUB_CATEGORYMAPPING: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateSubCategoryMapping`,
  CHECK_SUB_CATEGORYMAPPING: `${MAIN_URL}/V1/Admin/Account/CheckSubCategoryMapping`,
  FIELDWISE_SUB_CATEGORY: `${MAIN_URL}/V1/Admin/Account/GetFieldwiseSubcategory`,

  GET_CATEGORY_TYPE: `${MAIN_URL}/V1/Admin/Account/GetCategoryType`,
  ADD_CATEGORY_TYPE: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateCategoryType`,

  GET_DEPARTMENT: `${MAIN_URL}/V1/Admin/Account/GetDepartment`,
  GET_DEPARTMENTDROPDOWN: `${MAIN_URL}/V1/Admin/Account/GetDepartmentDropDown`,
  ADD_DEPARTMENT: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateDepartment`,

  GET_DEPT_SOURCEMAPPING: `${MAIN_URL}/V1/Admin/Account/GetSourceMapping`,
  ADD_DEPT_SOURCEMAPPING: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateSourceMapping`,

  GET_PRIORITY: `${MAIN_URL}/V1/Admin/Account/GetPriority`,

  GET_USERS: `${MAIN_URL}/V1/Admin/Account/GetUserMaster`,
  GET_USERS_TYPE: `${MAIN_URL}/V1/Admin/Account/GetUserType`,
  GET_USERS_ROLL: `${MAIN_URL}/V1/Admin/Account/GetUserRole`,
  ADD_USERS: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateUser`,

  GET_MAIL_CONFIGURE: `${MAIN_URL}/V1/Admin/EmailConfiguration/GetEmailConfiguration`,
  ADD_MAIL_CONFIGURE: `${MAIN_URL}/V1/Admin/EmailConfiguration/InsertorUpdateEmailConfiguration`,

  GET_MAIL_TEMPLATE: `${MAIN_URL}/V1/Admin/EmailConfiguration/GetEmailTemplate`,
  ADD_MAIL_TEMPLATE: `${MAIN_URL}/V1/Admin/EmailConfiguration/InsertEmailTemplate`,

  GET_TICKET_SOURCE: `${MAIN_URL}/V1/Admin/Account/GetSource`,
  ADD_TICKET_SOURCE: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateSource`,

  GET_TICKET_SOURCE_DETAILS: `${MAIN_URL}/V1/Admin/Account/GetSourceDetails`,
  ADD_TICKET_SOURCE_DETAILS: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateSourceDetails`,

  GET_TICKET_FIELD: `${MAIN_URL}/V1/Admin/Account/GetFieldMapping`,
  ADD_TICKET_FIELD: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateFieldMapping`,

  GET_TICKET_FIELD_GROUP_MAPPING: `${MAIN_URL}/V1/Admin/Account/GetFieldGroupMapping`,
  ADD_TICKET_FIELD_GROUP_MAPPING: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateFieldGroupMapping`,
  CHECK_TICKET_FIELD_GROUP_MAPPING: `${MAIN_URL}/V1/Admin/Account/CheckFieldGroup`,

  GET_DEPTWISE_SOURCE: `${MAIN_URL}/V1/Admin/Account/GetDeptwiseSource`,
  GET_DEPTWISE_SOURCEDETAILS: `${MAIN_URL}/V1/Admin/Account/GetDeptwiseSourceDetails`,
  GET_DEPTWISE_AGENT: `${MAIN_URL}/V1/Admin/Account/GetDeptwiseAgents`,
  ADD_DEPTWISE_AGENT: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateAgentMapping`,

  GET_AGENTMAPPING: `${MAIN_URL}/V1/Admin/Account/getAgentsMapping`,
  ADD_AGENTMAPPING: `${MAIN_URL}/V1/Admin/Account/InsertorUpdateAgentMapping`,

  GET_MAIL_INBOX: `${MAIN_URL}/V1/Admin/EmailConfiguration/GetInboxEmailLists`,
  GET_MAIL_SENT: `${MAIN_URL}/V1/Admin/EmailConfiguration/GetSendEmailLists`,
  ADD_MAIL_INBOX: `${MAIN_URL}/V1/Admin/EmailConfiguration/InsertSendMail`,

  GET_CALL_CONFIGURE: `${MAIN_URL}/V1/Admin/CallConfiguration/GetCallConfiguration`,
  ADD_CALL_CONFIGURE: `${MAIN_URL}/V1/Admin/CallConfiguration/InsertorUpdateCallConfiguration`,

  GET_CALL_OUTBOUND_ROUTE: `${MAIN_URL}/V1/Admin/CallConfiguration/GetOutboundRoute`,
  ADD_CALL_OUTBOUND_ROUTE: `${MAIN_URL}/V1/Admin/CallConfiguration/InsertorUpdateOutboundRoute`,

  GET_PASTTICKETINTERACTION: `${MAIN_URL}/V1/Agent/User/GetPastTicketInteraction`,
  GET_TICKETDASHBOARD: `${MAIN_URL}/V1/Agent/User/GetTicketDashboard`,
  GET_TICKETLIST: `${MAIN_URL}/V1/Agent/User/GetTicketLists`,
  //https://c2c.armsoft-tech.com/ticketing/api/V1/Agent/User/GetTicketSortingLists
  GET_FILTERWISETICKETLIST: `${MAIN_URL}/V1/Agent/User/GetFilterWiseTicketLists`,
  GET_TICKETLISTDETAILS: `${MAIN_URL}/V1/Agent/User/GetTicketDetailsList`,
  GET_TICKETTRAILS: `${MAIN_URL}/V1/Agent/User/GetTicketTrails`,
  INSERT_TICKETLISTDETAILS: `${MAIN_URL}/V1/Agent/User/InserorUpdateTicketResolved`,

  INSERT_TICKETCONVERTION: `${MAIN_URL}/V1/Agent/User/TicketConvertion`,

  GET_CALLERINFORMATION: `${MAIN_URL}/V1/Agent/User/GetCallerInformation`,
  INSERT_CALLERINFORMATION: `${MAIN_URL}/V1/Agent/User/InsertorUpdateCustomerBasicInformation`,

  GET_TICKETSTATUS: `${MAIN_URL}/V1/Agent/User/GetTicketstatus`,
  GET_DISPOSITION: `${MAIN_URL}/V1/Agent/User/GetDisposition`,

  GET_ADMINTCKTDASHBOARD: `${MAIN_URL}/V1/Agent/User/GetAdminTicketDashboard`,
  GET_ADMINTCKTDASHBOARDDETAILS: `${MAIN_URL}/V1/Agent/User/GetAdminTicketDashboardDetails`,
};
