import { call, put, takeLatest } from 'redux-saga/effects';
import { add_CategoryDataFailed, add_CategoryDataSuccess, ADD_CATEGORY_REQUESTED, get_CategoryDataFailed, get_CategoryDataSuccess, GET_CATEGORY_REQUESTED } from '../action/category-action';
import { add_category_API, get_category_API } from '../api/category-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getCategoryGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_category_API, action.payload);
    yield put(get_CategoryDataSuccess(getResponse));
  } catch (error) {
    yield put(get_CategoryDataFailed(error));
  }
}

function* addCategoryGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(add_category_API, action.payload);
    yield put(add_CategoryDataSuccess(getResponse));
  } catch (error) {
    yield put(add_CategoryDataFailed(error));
  }
}

export function* categorySaga() {
  yield takeLatest(GET_CATEGORY_REQUESTED, getCategoryGroup)
  yield takeLatest(ADD_CATEGORY_REQUESTED, addCategoryGroup)
}