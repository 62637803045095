export const GET_TICKETSTATUS = 'GET_TICKETSTATUS';
export const GET_TICKETSTATUS_REQUEST = 'GET_TICKETSTATUS_REQUEST';
export const GET_TICKETSTATUS_SUCCESS = 'GET_TICKETSTATUS_SUCCESS';
export const GET_TICKETSTATUS_FAILED = 'GET_TICKETSTATUS_FAILED';
export const GET_TICKETSTATUS_CLEAR = 'GET_TICKETSTATUS_CLEAR';


export function get_TicketStatus(payload: any){
    return{
        type: 'GET_TICKETSTATUS_REQUEST',
        payload: payload
    }
}
export function get_TicketStatus_Success(payload: any){
    return{
        type: 'GET_TICKETSTATUS_SUCCESS',
        payload: payload
    }
}
export function get_TicketStatus_Failed(payload: any){
    return{
        type: 'GET_TICKETSTATUS_FAILED',
        payload: payload
    }
}
export function get_TicketStatus_Clear(payload: any){
    return{
        type: 'GET_TICKETSTATUS_CLEAR',
        payload: payload
    }
}