import { call, put, takeLatest } from 'redux-saga/effects';
import { get_Disposition1DataSuccess, get_Disposition1DataFailed, GET_DISPOSITION1_REQUESTED, get_Disposition2DataFailed, get_Disposition2DataSuccess, GET_DISPOSITION2_REQUESTED, get_Disposition3DataFailed, get_Disposition3DataSuccess, GET_DISPOSITION3_REQUESTED } from '../action/disposition-action';
import { get_Disposition1_API, get_Disposition2_API, get_Disposition3_API } from '../api/disposition-api';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: any;
    statusText?: any;
    token?: any;
}


function* get_Disposition1(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            get_Disposition1_API,
            action.payload
        );
        yield put(get_Disposition1DataSuccess(getResponse));
    } catch (error) {
        yield put(get_Disposition1DataFailed(error));
    }
}

function* get_Disposition2(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            get_Disposition2_API,
            action.payload
        );
        yield put(get_Disposition2DataSuccess(getResponse));
    } catch (error) {
        yield put(get_Disposition2DataFailed(error));
    }
}

function* get_Disposition3(action: any) {
    try {
        let getResponse: ResponseGenerator = yield call(
            get_Disposition3_API,
            action.payload
        );
        yield put(get_Disposition3DataSuccess(getResponse));
    } catch (error) {
        yield put(get_Disposition3DataFailed(error));
    }
}
export function* get_DispositionSaga() {
    yield takeLatest(GET_DISPOSITION1_REQUESTED, get_Disposition1);
    yield takeLatest(GET_DISPOSITION2_REQUESTED, get_Disposition2);
    yield takeLatest(GET_DISPOSITION3_REQUESTED, get_Disposition3);
}
