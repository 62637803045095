export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_REQUESTED = 'GET_PRODUCT_REQUESTED';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_REQUESTED = 'ADD_PRODUCT_REQUESTED';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';
export const ADD_PRODUCT_DONE = 'ADD_PRODUCT_DONE';
export const GET_PRODUCT_FIELD = 'GET_PRODUCT_FIELD';
export const GET_PRODUCT_FIELD_REQUESTED = 'GET_PRODUCT_FIELD_REQUESTED';
export const GET_PRODUCT_FIELD_SUCCESS = 'GET_PRODUCT_FIELD_SUCCESS';
export const GET_PRODUCT_FIELD_FAILED = 'GET_PRODUCT_FIELD_FAILED';

export const ADD_PRODUCT_FIELD = 'ADD_PRODUCT_FIELD';
export const ADD_PRODUCT_FIELD_REQUESTED = 'ADD_PRODUCT_FIELD_REQUESTED';
export const ADD_PRODUCT_FIELD_SUCCESS = 'ADD_PRODUCT_FIELD_SUCCESS';
export const ADD_PRODUCT_FIELD_FAILED = 'ADD_PRODUCT_FIELD_FAILED';
export const ADD_PRODUCT_FIELD_DONE = 'ADD_PRODUCT_FIELD_DONE';

export function get_ProductData(payload: any) {
  return {
    type: 'GET_PRODUCT_REQUESTED',
    payload: payload,
  };
}

export function get_ProductSuccess(payload: any) {
  return {
    type: 'GET_PRODUCT_SUCCESS',
    payload: payload,
  };
}
export function get_ProductFailed(payload: any) {
  return {
    type: 'GET_PRODUCT_FAILED',
    payload: '',
  };
}

export function add_ProductData(payload: any) {
  return {
    type: 'ADD_PRODUCT_REQUESTED',
    payload: payload,
  };
}
export function add_ProductSuccess(payload: any) {
  return {
    type: 'ADD_PRODUCT_SUCCESS',
    payload: payload,
  };
}
export function add_ProductFailed(payload: any) {
  return {
    type: 'ADD_PRODUCT_FAILED',
    payload: '',
  };
}
export function add_ProductDone() {
  return {
    type: 'ADD_PRODUCT_DONE',
  };
}

export function get_ProductField(payload: any) {
  return {
    type: 'GET_PRODUCT_FIELD_REQUESTED',
    payload: payload,
  };
}

export function get_ProductFieldDone(payload: any) {
  return {
    type: 'GET_PRODUCT_FIELD_SUCCESS',
    payload: payload,
  };
}

export function get_ProductFieldFailed(payload: any) {
  return {
    type: 'GET_PRODUCT_FIELD_FAILED',
    payload: payload,
  };
}

export function add_ProductFieldData(payload: any) {
  return {
    type: 'ADD_PRODUCT_FIELD_REQUESTED',
    payload: payload,
  };
}

export function add_ProductFieldSuccess(payload: any) {
  return {
    type: 'ADD_PRODUCT_FIELD_SUCCESS',
    payload: payload,
  };
}

export function add_ProductFieldFailed(payload: any) {
  return {
    type: 'ADD_PRODUCT_FIELD_FAILED',
    payload: '',
  };
}

export function add_ProductFieldDone() {
  return {
    type: 'ADD_PRODUCT_FIELD_DONE',
  };
}
