
export const GET_TICKET_SOURCE = "GET_TICKET_SOURCE"
export const GET_TICKET_SOURCE_REQUESTED = "GET_TICKET_SOURCE_REQUESTED"
export const GET_TICKET_SOURCE_SUCCESS = "GET_TICKET_SOURCE_SUCCESS"
export const GET_TICKET_SOURCE_FAILED = "GET_TICKET_SOURCE_FAILED"
export const ADD_TICKET_SOURCE = "ADD_TICKET_SOURCE"
export const ADD_TICKET_SOURCE_REQUESTED = "ADD_TICKET_SOURCE_REQUESTED"
export const ADD_TICKET_SOURCE_SUCCESS = "ADD_TICKET_SOURCE_SUCCESS"
export const ADD_TICKET_SOURCE_FAILED = "ADD_TICKET_SOURCE_FAILED"
export const ADD_TICKET_SOURCE_DONE = "ADD_TICKET_SOURCE_DONE"


export function get_TicketSourceData() {
    return {
        type: 'GET_TICKET_SOURCE_REQUESTED'
    }
}

export function get_TicketSourceSuccess(payload:any) {
    return {
        type: 'GET_TICKET_SOURCE_SUCCESS',
        payload: payload
    }
}
export function get_TicketSourceFailed(payload:any) {
    return {
        type: 'GET_TICKET_SOURCE_FAILED',
        payload: ''
    }
}

export function add_TicketSourceData(payload:any) {
    return {
        type: 'ADD_TICKET_SOURCE_REQUESTED',
        payload: payload
    }
}
export function add_TicketSourceSuccess(payload:any) {
    return {
        type: 'ADD_TICKET_SOURCE_SUCCESS',
        payload: payload
    }
}
export function add_TicketSourceFailed(payload:any) {
    return {
        type: 'ADD_TICKET_SOURCE_FAILED',
        payload: ''
    }
}
export function add_TicketSourceDone() {
    return {
        type: 'ADD_TICKET_SOURCE_DONE'
    }
}