import { call, put, takeLatest } from 'redux-saga/effects';
import { get_PastTicketInteraction_Failed, GET_PASTTICKETINTERACTION_REQUEST, get_PastTicketInteraction_Success } from '../action/pastTicketInteraction-action';
import { get_PastTicketInteraction_API } from '../api/pastTicketInteraction-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getPastTicketInteraction(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_PastTicketInteraction_API, action.payload);
    yield put(get_PastTicketInteraction_Success(getResponse));
  } catch (error) {
    yield put(get_PastTicketInteraction_Failed(error));
  }
}

export function* getPastTicketInteractionSaga() {
  yield takeLatest(GET_PASTTICKETINTERACTION_REQUEST, getPastTicketInteraction);
}