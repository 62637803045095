import cookie from 'react-cookies';
import { ADD_TICKET_SOURCE, ADD_TICKET_SOURCE_FAILED, ADD_TICKET_SOURCE_SUCCESS, GET_TICKET_SOURCE, GET_TICKET_SOURCE_FAILED, GET_TICKET_SOURCE_SUCCESS } from '../action/ticketSource-action';


const initialState = {
    loading: false,
    data: [],
    addTicketSource: false
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TICKET_SOURCE:
            return {
                ...state,
                loading: true
            }
        case GET_TICKET_SOURCE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_TICKET_SOURCE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    date: [],
                    loading: false
                }
            }
        case ADD_TICKET_SOURCE:
            return {
                ...state,
                loading: true
            }
        case ADD_TICKET_SOURCE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addTicketSource: true,
                    data: action.payload,
                    loading: false
                }
            }
        case ADD_TICKET_SOURCE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    date: [],
                    loading: false
                }
            }
        default:
            return state
    }
}