
export const GET_DEPTWISE_SOURCEDETAILS = "GET_DEPTWISE_SOURCEDETAILS"
export const GET_DEPTWISE_SOURCEDETAILS_REQUESTED = "GET_DEPTWISE_SOURCEDETAILS_REQUESTED"
export const GET_DEPTWISE_SOURCEDETAILS_SUCCESS = "GET_DEPTWISE_SOURCEDETAILS_SUCCESS"
export const GET_DEPTWISE_SOURCEDETAILS_FAILED = "GET_DEPTWISE_SOURCEDETAILS_FAILED"


export function get_deptwiseSourceDetailsData(payload:any) {
    return {
        type: 'GET_DEPTWISE_SOURCEDETAILS_REQUESTED',
        payload: payload
    }
}

export function get_deptwiseSourceDetailsSuccess(payload:any) {
    return {
        type: 'GET_DEPTWISE_SOURCEDETAILS_SUCCESS',
        payload: payload
    }
}
export function get_deptwiseSourceDetailsFailed(payload:any) {
    return {
        type: 'GET_DEPTWISE_SOURCEDETAILS_FAILED',
        payload: ''
    }
}