import { call, put, takeLatest } from 'redux-saga/effects';
import { loginFailed, loginSuccess, LOGIN_FAILED, LOGIN_REQUESTED, LOGIN_SUCCESS } from '../action/login-action';
import { loginUser } from '../api/login-api';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* validateLogin(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(loginUser, action.payload);
   yield put(loginSuccess(getResponse));
   } catch (error) {
    yield put(loginFailed(error));
   }

}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUESTED, validateLogin)
}