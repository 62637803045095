import cookie from 'react-cookies';
import { GET_PASTTICKETINTERACTION, GET_PASTTICKETINTERACTION_CLEAR, GET_PASTTICKETINTERACTION_FAILED, GET_PASTTICKETINTERACTION_SUCCESS } from '../action/pastTicketInteraction-action';

const initialState = {
    loading: false,
    getData: {},
    getPastTicketInteractionStatus: false,
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PASTTICKETINTERACTION:
            return {
                ...state,
                loading: true,
            }
        case GET_PASTTICKETINTERACTION_SUCCESS:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: action.payload,
                    loading: false,
                    getPastTicketInteractionStatus: true,
                }
            }
        case GET_PASTTICKETINTERACTION_FAILED:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: {},
                    loading: false,
                }
            }
        case GET_PASTTICKETINTERACTION_CLEAR:
            if (action?.payload?.StatusCode === 400) {
                localStorage.clear();

                cookie.remove('agentToken');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    getData: {},
                    loading: false,
                }
            }
        default:
            return state
    }
}