import { call, put, takeLatest } from 'redux-saga/effects';
import { get_deptwiseSourceSuccess, get_deptwiseSourceFailed, GET_DEPTWISE_SOURCE_REQUESTED } from '../action/deptwiseSource-action';
import { get_deptwiseSource_API } from '../api/deptwiseSource-api';


export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getDeptwise_Source(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(get_deptwiseSource_API, action.payload);
    yield put(get_deptwiseSourceSuccess(getResponse));
  } catch (error) {
    yield put(get_deptwiseSourceFailed(error));
  }
}

export function* deptwiseSourceSaga() {
  yield takeLatest(GET_DEPTWISE_SOURCE_REQUESTED, getDeptwise_Source)
}