export const GET_CATEGORYGROUP = 'GET_CATEGORYGROUP';
export const GET_CATEGORYGROUP_REQUESTED = 'GET_CATEGORYGROUP_REQUESTED';
export const GET_CATEGORYGROUP_SUCCESS = 'GET_CATEGORYGROUP_SUCCESS';
export const GET_CATEGORYGROUP_FAILED = 'GET_CATEGORYGROUP_FAILED';

export const ADD_CATEGORYGROUP = 'ADD_CATEGORYGROUP';
export const ADD_CATEGORYGROUP_REQUESTED = 'ADD_CATEGORYGROUP_REQUESTED';
export const ADD_CATEGORYGROUP_SUCCESS = 'ADD_CATEGORYGROUP_SUCCESS';
export const ADD_CATEGORYGROUP_FAILED = 'ADD_CATEGORYGROUP_FAILED';
export const ADD_CATEGORYGROUP_DONE = 'ADD_CATEGORYGROUP_DONE';

export function get_categoryGroupData(payload: any) {
  return {
    type: 'GET_CATEGORYGROUP_REQUESTED',
    payload: payload,
  };
}
export function get_CATEGORYGROUPDataSuccess(payload: any) {
  return {
    type: 'GET_CATEGORYGROUP_SUCCESS',
    payload: payload,
  };
}
export function get_CATEGORYGROUPDataFailed(payload: any) {
  return {
    type: 'GET_CATEGORYGROUP_FAILED',
    payload: '',
  };
}

export function add_CATEGORYGROUPData(payload: any) {
  return {
    type: 'ADD_CATEGORYGROUP_REQUESTED',
    payload: payload,
  };
}
export function add_CATEGORYGROUPDataSuccess(payload: any) {
  return {
    type: 'ADD_CATEGORYGROUP_SUCCESS',
    payload: payload,
  };
}
export function add_CATEGORYGROUPDataFailed(payload: any) {
  return {
    type: 'ADD_CATEGORYGROUP_FAILED',
    payload: '',
  };
}

export function add_CATEGORYGROUPDone() {
  return {
    type: 'ADD_CATEGORYGROUP_DONE',
  };
}
