import cookie from 'react-cookies';
import { SUBDOMAIN } from '../../Service';
import { GET_ADMINTICKETDASHBOARD_REQUESTED, GET_ADMINTICKETDASHBOARD_SUCCESS, GET_ADMINTICKETDASHBOARD_FAILED, GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED, GET_ADMINTICKETDASHBOARDDETAILS_SUCCESS, GET_ADMINTICKETDASHBOARDDETAILS_FAILED } from '../action/adminTcktDashboard-action';

const initialState = {
    loading: false,
    getAdminTcktDashboardData: {},
    getAdminTcktDashboardDetailsData: [],
    getAdminTcktDashboardStatus: false,
    getAdminTcktDashboardDetailsStatus: false,
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ADMINTICKETDASHBOARD_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case GET_ADMINTICKETDASHBOARD_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = `${SUBDOMAIN}/login_expire`;
                return {};
            } else {
                return {
                    ...state,
                    getAdminTcktDashboardData: action.payload,
                    getAdminTcktDashboardStatus: true,
                    loading: false,
                };
            }
        case GET_ADMINTICKETDASHBOARD_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = `${SUBDOMAIN}/login_expire`;
                return {};
            } else {
                return {
                    ...state,
                    getAdminTcktDashboardData: [],
                    getAdminTcktDashboardStatus: false,
                    loading: false,
                };
            }



        case GET_ADMINTICKETDASHBOARDDETAILS_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case GET_ADMINTICKETDASHBOARDDETAILS_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = `${SUBDOMAIN}/login_expire`;
                return {};
            } else {
                return {
                    ...state,
                    getAdminTcktDashboardDetailsData: action.payload,
                    getAdminTcktDashboardDetailsStatus: true,
                    loading: false,
                };
            }
        case GET_ADMINTICKETDASHBOARDDETAILS_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = `${SUBDOMAIN}/login_expire`;
                return {};
            } else {
                return {
                    ...state,
                    getAdminTcktDashboardDetailsData: [],
                    getAdminTcktDashboardDetailsStatus: false,
                    loading: false,
                };
            }
        default:
            return state;
    }
};
