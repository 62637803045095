import { call, put, takeLatest } from 'redux-saga/effects';
import {
  add_CATEGORYGROUPDataFailed,
  add_CATEGORYGROUPDataSuccess,
  ADD_CATEGORYGROUP_REQUESTED,
  get_CATEGORYGROUPDataFailed,
  get_CATEGORYGROUPDataSuccess,
  GET_CATEGORYGROUP_REQUESTED,
} from '../action/categoryGroup-action';
import {
  add_categoryGroup_API,
  get_categoryGroup_API,
} from '../api/categoryGroup-api';
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: any;
  statusText?: any;
  token?: any;
}

function* getCategoryGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      get_categoryGroup_API,
      action.payload
    );
    yield put(get_CATEGORYGROUPDataSuccess(getResponse));
  } catch (error) {
    yield put(get_CATEGORYGROUPDataFailed(error));
  }
}

function* addCategoryGroup(action: any) {
  try {
    let getResponse: ResponseGenerator = yield call(
      add_categoryGroup_API,
      action.payload
    );
    yield put(add_CATEGORYGROUPDataSuccess(getResponse));
  } catch (error) {
    yield put(add_CATEGORYGROUPDataFailed(error));
  }
}

export function* categoryGroupSaga() {
  yield takeLatest(GET_CATEGORYGROUP_REQUESTED, getCategoryGroup);
  yield takeLatest(ADD_CATEGORYGROUP_REQUESTED, addCategoryGroup);
}
