export const GET_USERS = 'GET_USERS'
export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const ADD_USERS = 'ADD_USERS'
export const ADD_USERS_REQUESTED = 'ADD_USERS_REQUESTED'
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS'
export const ADD_USERS_FAILED = 'ADD_USERS_FAILED'
export const ADD_USERS_DONE = 'ADD_USERS_DONE'


export function get_UsersData(){
    return{
        type: 'GET_USERS_REQUESTED'
    }
}
export function get_UsersDataSuccess(payload: any){
    return{
        type: 'GET_USERS_SUCCESS',
        payload: payload
    }
}
export function get_UsersDataFailed(payload: any){
    return{
        type: 'GET_USERS_FAILED',
        payload: ''
    }
}


export function add_UsersData(payload: any){
    return{
        type: 'ADD_USERS_REQUESTED',
        payload: payload
    }
}
export function add_UsersDataSuccess(payload: any){
    return{
        type: 'ADD_USERS_SUCCESS',
        payload: payload
    }
}
export function add_UsersDataFailed(payload: any){
    return{
        type: 'ADD_USERS_FAILED',
        payload: ''
    }
}
export function add_UsersDataDone(){
    return {
        type: 'ADD_USERS_DONE'
    }
}