import { call, put, takeLatest } from 'redux-saga/effects';
import { add_DepartmentDataFailed, add_DepartmentDataSuccess, ADD_DEPARTMENT_REQUESTED, get_DepartmentDataFailed, get_DepartmentDataSuccess, get_DepartmentDropDownDataFailed, get_DepartmentDropDownDataSuccess, GET_DEPARTMENTDROPDOWN_REQUESTED, GET_DEPARTMENT_REQUESTED } from '../action/department-action';
import { add_department_API, get_departmentDropDown_API, get_department_API } from '../api/department-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getDepartment(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_department_API, action.payload);
   yield put(get_DepartmentDataSuccess(getResponse));
   } catch (error) {
    yield put(get_DepartmentDataFailed(error));
   }
}

function* getDepartmentDropDown(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_departmentDropDown_API, action.payload);
   yield put(get_DepartmentDropDownDataSuccess(getResponse));
   } catch (error) {
    yield put(get_DepartmentDropDownDataFailed(error));
   }
}

function* addDepartment(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(add_department_API, action.payload);
   yield put(add_DepartmentDataSuccess(getResponse));
   } catch (error) {
    yield put(add_DepartmentDataFailed(error));
   }
}

export function* departmentSaga() {
  yield takeLatest(GET_DEPARTMENT_REQUESTED, getDepartment)
  yield takeLatest(GET_DEPARTMENTDROPDOWN_REQUESTED, getDepartmentDropDown)
  yield takeLatest(ADD_DEPARTMENT_REQUESTED, addDepartment)
}