import cookie from 'react-cookies';
import { ADD_MAILCONFIGURE, ADD_MAILCONFIGURE_DONE, ADD_MAILCONFIGURE_FAILED, ADD_MAILCONFIGURE_SUCCESS, GET_MAILCONFIGURE, GET_MAILCONFIGURE_FAILED, GET_MAILCONFIGURE_SUCCESS } from '../action/mailConfigure-action';

const initialState = {
    loading: false,
    data: [],
    addData: {},
    addMailConfigureStatus: false
}
export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MAILCONFIGURE:
            return {
                ...state,
                loading: true,
            }
        case GET_MAILCONFIGURE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        case GET_MAILCONFIGURE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    data: [],
                    loading: false
                }
            }
        case ADD_MAILCONFIGURE:
            return {
                ...state,
                loading: true,
            }
        case ADD_MAILCONFIGURE_SUCCESS:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: action.payload,
                    addMailConfigureStatus: true,
                }
            }
        case ADD_MAILCONFIGURE_FAILED:
            if (action?.payload?.StatusCode === 401) {
                localStorage.clear();
                cookie.remove('username');
                cookie.remove('token');
                window.location.href = '/login_expire'
                return {}
            }
            else {
                return {
                    ...state,
                    addData: {}
                }
            }
        case ADD_MAILCONFIGURE_DONE:
            return {
                ...state,
                addData: {},
                addMailConfigureStatus: false
            }
        default:
            return state
    }
}