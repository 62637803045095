import { call, put, takeLatest } from 'redux-saga/effects';
import { add_CategoryTypeDataFailed, add_CategoryTypeDataSuccess, ADD_CATEGORY_TYPE_REQUESTED, get_CategoryTypeDataFailed, get_CategoryTypeDataSuccess, GET_CATEGORY_TYPE_REQUESTED } from '../action/categoryType-action';
import { add_categoryType_API, get_categoryType_API } from '../api/categoryType-api';
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: any,
  statusText?: any,
  token?: any
}

function* getCategoryTypeGroup(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(get_categoryType_API, action.payload);
   yield put(get_CategoryTypeDataSuccess(getResponse));
   } catch (error) {
    yield put(get_CategoryTypeDataFailed(error));
   }
}

function* addCategoryTypeGroup(action: any) {
  try {
     let getResponse: ResponseGenerator = yield call(add_categoryType_API, action.payload);
   yield put(add_CategoryTypeDataSuccess(getResponse));
   } catch (error) {
    yield put(add_CategoryTypeDataFailed(error));
   }
}

export function* categoryTypeSaga() {
  yield takeLatest(GET_CATEGORY_TYPE_REQUESTED, getCategoryTypeGroup)
  yield takeLatest(ADD_CATEGORY_TYPE_REQUESTED, addCategoryTypeGroup)
}