export const GET_PASTTICKETINTERACTION = 'GET_PASTTICKETINTERACTION';
export const GET_PASTTICKETINTERACTION_REQUEST = 'GET_PASTTICKETINTERACTION_REQUEST';
export const GET_PASTTICKETINTERACTION_SUCCESS = 'GET_PASTTICKETINTERACTION_SUCCESS';
export const GET_PASTTICKETINTERACTION_FAILED = 'GET_PASTTICKETINTERACTION_FAILED';
export const GET_PASTTICKETINTERACTION_CLEAR = 'GET_PASTTICKETINTERACTION_CLEAR';


export function get_PastTicketInteraction(payload: any) {
    return {
        type: 'GET_PASTTICKETINTERACTION_REQUEST',
        payload: payload
    }
}
export function get_PastTicketInteraction_Success(payload: any) {
    return {
        type: 'GET_PASTTICKETINTERACTION_SUCCESS',
        payload: payload
    }
}
export function get_PastTicketInteraction_Failed(payload: any) {
    return {
        type: 'GET_PASTTICKETINTERACTION_FAILED',
        payload: payload
    }
}
export function get_PastTicketInteraction_Clear() {
    return {
        type: 'GET_PASTTICKETINTERACTION_CLEAR'
    }
}

