import { SERVICE_URL } from '../../Service';
import cookie from 'react-cookies';

export const get_CallerInformation_API = async (payload: any) => {
    try {
        let headers = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + cookie.load('token'),
            },
        };
        let getDetails = await fetch(SERVICE_URL.GET_CALLERINFORMATION + `?info=${payload}`, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    } catch (e) {
        console.log(e);
    }
};

export const insert_CallerInformation_API = async (payload: any) => {
    try {
        let headers = {
            method: 'POST',
            headers: {
               // 'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer' + ' ' + cookie.load('token'),
            },
            body: payload
        };
        let getDetails = await fetch(SERVICE_URL.INSERT_CALLERINFORMATION, headers);
        let getResponse = await getDetails.json();
        return getResponse;
    } catch (e) {
        console.log(e);
    }
};