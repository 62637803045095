export const GET_CATEGORYMAPPING = 'GET_CATEGORYMAPPING';
export const GET_CATEGORYMAPPING_REQUESTED = 'GET_CATEGORYMAPPING_REQUESTED';
export const GET_CATEGORYMAPPING_SUCCESS = 'GET_CATEGORYMAPPING_SUCCESS';
export const GET_CATEGORYMAPPING_FAILED = 'GET_CATEGORYMAPPING_FAILED';

export const ADD_CATEGORYMAPPING = 'ADD_CATEGORYMAPPING';
export const ADD_CATEGORYMAPPING_REQUESTED = 'ADD_CATEGORYMAPPING_REQUESTED';
export const ADD_CATEGORYMAPPING_SUCCESS = 'ADD_CATEGORYMAPPING_SUCCESS';
export const ADD_CATEGORYMAPPING_FAILED = 'ADD_CATEGORYMAPPING_FAILED';
export const ADD_CATEGORYMAPPING_DONE = 'ADD_CATEGORYMAPPING_DONE';

export const CHECK_CATEGORYMAPPING = 'CHECK_CATEGORYMAPPING';
export const CHECK_CATEGORYMAPPING_REQUESTED = 'CHECK_CATEGORYMAPPING_REQUESTED';
export const CHECK_CATEGORYMAPPING_SUCCESS = 'CHECK_CATEGORYMAPPING_SUCCESS';
export const CHECK_CATEGORYMAPPING_FAILED = 'CHECK_CATEGORYMAPPING_FAILED';
export const CHECK_CATEGORYMAPPING_DONE = 'CHECK_CATEGORYMAPPING_DONE';

export function get_categoryMappingData() {
  return {
    type: 'GET_CATEGORYMAPPING_REQUESTED',
  };
}
export function get_categoryMappingDataSuccess(payload: any) {
  return {
    type: 'GET_CATEGORYMAPPING_SUCCESS',
    payload: payload,
  };
}
export function get_categoryMappingDataFailed(payload: any) {
  return {
    type: 'GET_CATEGORYMAPPING_FAILED',
    payload: '',
  };
}

export function add_categoryMappingData(payload: any) {
  return {
    type: 'ADD_CATEGORYMAPPING_REQUESTED',
    payload: payload,
  };
}
export function add_categoryMappingDataSuccess(payload: any) {
  return {
    type: 'ADD_CATEGORYMAPPING_SUCCESS',
    payload: payload,
  };
}
export function add_categoryMappingDataFailed(payload: any) {
  return {
    type: 'ADD_CATEGORYMAPPING_FAILED',
    payload: '',
  };
}

export function add_categoryMappingDone() {
  return {
    type: 'ADD_CATEGORYMAPPING_DONE',
  };
}


export function check_categoryMappingData(payload: any) {
  return {
    type: 'CHECK_CATEGORYMAPPING_REQUESTED',
    payload: payload,
  };
}
export function check_categoryMappingDataSuccess(payload: any) {
  return {
    type: 'CHECK_CATEGORYMAPPING_SUCCESS',
    payload: payload,
  };
}
export function check_categoryMappingDataFailed(payload: any) {
  return {
    type: 'CHECK_CATEGORYMAPPING_FAILED',
    payload: '',
  };
}
