
export const GET_MAILCONFIGURE = "GET_MAILCONFIGURE"
export const GET_MAILCONFIGURE_REQUESTED = "GET_MAILCONFIGURE_REQUESTED"
export const GET_MAILCONFIGURE_SUCCESS = "GET_MAILCONFIGURE_SUCCESS"
export const GET_MAILCONFIGURE_FAILED = "GET_MAILCONFIGURE_FAILED"

export const ADD_MAILCONFIGURE = "ADD_MAILCONFIGURE"
export const ADD_MAILCONFIGURE_REQUESTED = "ADD_MAILCONFIGURE_REQUESTED"
export const ADD_MAILCONFIGURE_SUCCESS = "ADD_MAILCONFIGURE_SUCCESS"
export const ADD_MAILCONFIGURE_FAILED = "ADD_MAILCONFIGURE_FAILED"
export const ADD_MAILCONFIGURE_DONE = "ADD_MAILCONFIGURE_DONE"

export function get_MailConfigureData(){
    return {
        type: 'GET_MAILCONFIGURE_REQUESTED'
    }
}
export function get_MailConfigureDataSuccess(payload: any){     
    return {
        type: 'GET_MAILCONFIGURE_SUCCESS',
        payload: payload
    }
}
export function get_MailConfigureDataFailed(payload: any){
    return {
        type: 'GET_MAILCONFIGURE_FAILED',
        payload: ''
    }
}

export function add_MailConfigureData(payload: any){
    return {
        type: 'ADD_MAILCONFIGURE_REQUESTED',
        payload: payload
    }
}
export function add_MailConfigureDataSuccess(payload: any){     
    return {
        type: 'ADD_MAILCONFIGURE_SUCCESS',
        payload: payload
    }
}
export function add_MailConfigureDataFailed(payload: any){
    return {
        type: 'ADD_MAILCONFIGURE_FAILED',
        payload: ''
    }
}
export function add_MailConfigureDone() {
    return {
        type: 'ADD_MAILCONFIGURE_DONE'
    }
}