
export const GET_DEPT_SOURCEMAPPING = "GET_DEPT_SOURCEMAPPING"
export const GET_DEPT_SOURCEMAPPING_REQUESTED = "GET_DEPT_SOURCEMAPPING_REQUESTED"
export const GET_DEPT_SOURCEMAPPING_SUCCESS = "GET_DEPT_SOURCEMAPPING_SUCCESS"
export const GET_DEPT_SOURCEMAPPING_FAILED = "GET_DEPT_SOURCEMAPPING_FAILED"
export const ADD_DEPT_SOURCEMAPPING = "ADD_DEPT_SOURCEMAPPING"
export const ADD_DEPT_SOURCEMAPPING_REQUESTED = "ADD_DEPT_SOURCEMAPPING_REQUESTED"
export const ADD_DEPT_SOURCEMAPPING_SUCCESS = "ADD_DEPT_SOURCEMAPPING_SUCCESS"
export const ADD_DEPT_SOURCEMAPPING_FAILED = "ADD_DEPT_SOURCEMAPPING_FAILED"
export const ADD_DEPT_SOURCEMAPPING_DONE = "ADD_DEPT_SOURCEMAPPING_DONE"


export function get_DeptSourceMappingData() {
    return {
        type: 'GET_DEPT_SOURCEMAPPING_REQUESTED'
    }
}

export function get_DeptSourceMappingSuccess(payload:any) {
    return {
        type: 'GET_DEPT_SOURCEMAPPING_SUCCESS',
        payload: payload
    }
}
export function get_DeptSourceMappingFailed(payload:any) {
    return {
        type: 'GET_DEPT_SOURCEMAPPING_FAILED',
        payload: ''
    }
}

export function add_DeptSourceMappingData(payload:any) {
    return {
        type: 'ADD_DEPT_SOURCEMAPPING_REQUESTED',
        payload: payload
    }
}
export function add_DeptSourceMappingSuccess(payload:any) {
    return {
        type: 'ADD_DEPT_SOURCEMAPPING_SUCCESS',
        payload: payload
    }
}
export function add_DeptSourceMappingFailed(payload:any) {
    return {
        type: 'ADD_DEPT_SOURCEMAPPING_FAILED',
        payload: ''
    }
}
export function add_DeptSourceMappingDone() {
    return {
        type: 'ADD_DEPT_SOURCEMAPPING_DONE'
    }
}